import React, { useEffect, useState } from "react";
import "../../../Common Components/Application Area/applicationArea.css";
import "./comEnrichSeries.scss";
// import { GrLocation } from "react-icons/gr";
import Footer from "../../../Common Components/Footer/Footer";
import CommercialHeader from "../../../Common Components/CommercialHeader/CommercialHeader";
import { Link } from "react-router-dom";
// import { GiKite } from "react-icons/gi";
// import { TbHeartRateMonitor } from "react-icons/tb";
// import { TbArrowsLeftRight } from "react-icons/tb";
// import { LuShieldCheck } from "react-icons/lu";
// import Products from "../../../Common Components/Products/Products";
import Aos from "aos";
import "aos/dist/aos.css";
// import Kite from "../../../Common Components/Icons/Kite";
// import Monitor from "../../../Common Components/Icons/Monitor";
// import DoubleArrow from "../../../Common Components/Icons/DoubleArrow";
// import Shield from "../../../Common Components/Icons/Shield";
import ProductCoomerCialEnrich from "../../../Common Components/Products/ProductCommercialEnrich";
import KiteSecond from "../../../Common Components/Icons/KiteSecond";
import MonitorSecond from "../../../Common Components/Icons/MonitorSecond";
import DoubleArrowSecond from "../../../Common Components/Icons/DoubleArrowSecond";
import SheildSecond from "../../../Common Components/Icons/SheildSecond";
import ApplicationArea from "../../../Common Components/Application Area/ApplicationArea";

const ComEnrichSeries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 1000, offset: 200 });
  }, []);
  const [checked, setChecked] = useState({
    product1: "true",
    product2: "",
  });
  const pdfFile = "2 ENRICH Series.pdf";

  const Product1 = {
    id: 1,
    desc1: "Hybrid Solar Inverter : 3.6KW",
    desc2: "Smart Battery : up to 10.2KWh",
    desc3: "Solar Panel : up to 4.6KWp",
    // desc: `Minimize AC/DC interconversion losses through a
    // Hybrid AC/DC Microgrid Solution.

    // 3.6 KVA and 8KVA-
    // Inverter with an efficiency of over 90%
    // Lithium, Maintenance-free Battery
    // Mono Crystaline PV Panel

    // `,
    images: {
      image: "Images/EnrichResidental/EN3.png",
      image2: "Images/EnrichResidental/ResEnPr01.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product2 = {
    id: 2,
    desc1: "Hybrid Solar Inverter : 8KW",
    desc2: "Smart Battery : up to 15.3KWh",
    desc3: "Solar Panel : up to to 10.4KWp",
    // desc: `Minimize AC/DC interconversion losses through a
    // Hybrid AC/DC Microgrid Solution.

    // 3.6 KVA and 8KVA-
    // Inverter with an efficiency of over 90%
    // Lithium, Maintenance-free Battery
    // Mono Crystaline PV Panel

    // `,
    images: {
      image: "Images/EnrichResidental/ResEnPr02.png",
      image2: "Images/EnrichResidental/EN8.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };
  const [product, setProduct] = useState(Product1);

  const ChangeProduct1 = () => {
    setProduct(Product1);
    setChecked({ product1: "true" });
  };

  const ChangeProduct2 = () => {
    setProduct(Product2);
    setChecked({ product2: "true" });
  };
  const ProductButtons = [
    {
      id: 1,
      name: "SG-EN3",
      action: ChangeProduct1,
      checked: checked.product1,
    },
    {
      id: 2,
      name: "SG-EN8",
      action: ChangeProduct2,
      checked: checked.product2,
    },
  ];

  const itemsData = [
    {
      src: "/applicationarea/Enrich01.png",
      title: "Gas Station",
      title1: "Super Market Retail",
      icon: "/applicationarea/icons/Enrich01.png",
    },
    {
      src: "/applicationarea/Enrich02.png",
      title: "Small & Medium Sized",
      title1: "Residential Areas",
      icon: "/applicationarea/icons/Enrich02.png",
    },
    {
      src: "/applicationarea/Enrich03.png",
      title: "ATM/Shops",
      title1: "Small Offices",
      icon: "/applicationarea/icons/Enrich03.png",
    },
    {
      src: "/applicationarea/Enrich04.png",
      title: "Clinics/Pharmacies",
      title1: "& Diagnostic Centers",
      icon: "/applicationarea/icons/Enrich04.png",
    },
    {
      src: "/applicationarea/Enrich05.png",
      title: "Cafeterias",
      title1: "& Restaurants",
      icon: "/applicationarea/icons/Enrich05.png",
    },
  ];

  const [itemActive, setItemActive] = useState(0);

  const nextSlide = () => {
    setItemActive((prevItemActive) => (prevItemActive + 1) % itemsData.length);
  };

  const previousSlide = () => {
    setItemActive((prevItemActive) =>
      prevItemActive === 0 ? itemsData.length - 1 : prevItemActive - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 4000);
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Function to handle thumbnail clicks
  const handleThumbnailClick = (index) => {
    setItemActive(index);
  };

  return (
    <>
      <CommercialHeader />
      <div
        id="carouselExampleIndicators"
        className="carousel slide res_third_section"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div
          className="carousel-inner"
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          <div
            className="carousel-item active"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/EnrichResidental/hero banner 1.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/EnrichResidental/EnirchMainBannerResidential02.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/EnrichResidental/EnirchMainBannerResidential03.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <section className="commercial_enrich_second_section">
        <div className="enrich_sec_sec_heading">
          <h1>Features</h1>
        </div>
        <section className="commercial_enrich_first_section">
          <div
            id="carouselExampleFade"
            className="carousel slide carousel-fade"
          >
            <div class="carousel-indicators control_buttons">
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              >
                <KiteSecond width="65" height="65" className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              >
                <MonitorSecond width="65" height="65" className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              >
                <DoubleArrowSecond width="65" height="65" className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              >
                <SheildSecond width="65" height="65" className="icon" />
              </button>
            </div>
            <div className="carousel-inner">
              <div
                className="carousel-item active"
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src="Images/commercailenrich/Enirch Features Commerical.jpeg"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Simple & Scalable </h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>Plug n Play quick installation & maintenance</li>
                    <li> Flexibility to deliver unique combinations </li>
                    <li> Across various sizes & location</li>
                    <li>
                      {" "}
                      Connect upto 6 units to grow the system with your needs
                    </li>
                  </ul>
                  {/* <p>
                    Plug n Play quick installation & maintenance
                    <br />
                    Flexibility to deliver unique combinations <br />
                    across various sizes & location
                    <br />
                    Connect upto 6 units to grow the system
                    <br />
                    with your needs
                  </p> */}
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/commercailenrich/Enirch Features Commerical 2.jpeg"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Smart</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li> 24x7 Real-Time Monitoring via Mobile App</li>
                    <li>
                      {" "}
                      Intelligent BMS optimises charging via solar & grid{" "}
                    </li>
                    <li> Doubles as a UPS for Power Backup</li>
                  </ul>
                  {/* <p>
                    24x7 Real-Time Monitoring via Mobile App
                    <br />
                    Intelligent BMS optimises charging via solar & grid
                    <br />
                    Doubles as a UPS for Power Backup
                  </p> */}
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/commercailenrich/Enirch Features Commerical 3.jpeg"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Seamless </h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>Instant switching between Grid power to Inverter</li>
                    <li>
                      {" "}
                      Energy storage to power all your appliances seamlessly
                    </li>
                    <li>
                      {" "}
                      Enjoy energy independence with non-stop reliable power
                    </li>
                  </ul>
                  {/* <p>
                    Instant switching between Grid power to Inverter
                    <br />
                    Energy storage to power all your appliances seamlessly
                    <br />
                    Enjoy energy independence with non-stop reliable power
                  </p> */}
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/commercailenrich/Enirch Features Commerical5.jpg"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents">
                  <h1>Safe & Sustainable </h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>Reduce your homes’ combined energy footprint by 90%</li>
                    <li>
                      {" "}
                      Clean hybrid energy that is sustainable & environment
                      friendly
                    </li>
                    <li>
                      {" "}
                      Long cycle LFP batteries for sustained use over a long
                      period of time
                    </li>
                  </ul>
                  {/* <p>
                    Reduce your homes’ combined energy footprint by 90%
                    <br />
                    Clean hybrid energy that is sustainable & environment
                    friendly
                    <br />
                    Long cycle LFP batteries for sustained use over a long
                    period of time
                  </p> */}
                </div>
              </div>
            </div>
            {/* <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleFade"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleFade"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button> */}
          </div>
        </section>
      </section>

      <ApplicationArea itemsData={itemsData} PageTitle="Enrich Series" />

      {/* <section className="application_area_wrapper">
        <div className="heading_container">
          <h3>Enrich Series</h3>
          <h1>Application Areas</h1>
        </div>
        <div className="Application_Area">
          <div className="slider">
            <div className="list">
              {itemsData.map((item, index) => (
                <div
                  key={index}
                  className={`item ${index === itemActive ? "active" : ""}`}
                >
                  <img src={item.src} alt={`Slider ${index + 1}`} />
                  <div className="content">
                    <h2>
                      {item.title}
                      <br />
                      {item.title1}
                    </h2>
                  </div>
                </div>
              ))}
            </div>
            <div className="thumbnail">
              {itemsData.map((item, index) => (
                <div
                  key={index}
                  className={`item ${index === itemActive ? "active" : ""}`}
                  onClick={() => handleThumbnailClick(index)} // Add click event handler
                >
                  <img src={item.src} alt={`Slider ${index + 1}`} />
                  <div className="content">
                    <img src={item.icon} alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      <ProductCoomerCialEnrich
        product={product}
        changeProduct1={ChangeProduct1}
        changeProduct2={ChangeProduct2}
        checked={checked}
        ProductButtons={ProductButtons}
        pdfurl={pdfFile}
      />

      <section className="commercial_enrich_fourth_section_wrapper">
        <div className="product_fourth_section">
          {/* <div className="featured_products">
            <h1>Product Range</h1>
            <div className="product_list">
              <div className="product">
                <div className="name">
                  <p>ENR 3.6KW</p>
                </div>
                <div className="info">
                  <img
                    src="Images/Residential/SetupSeries/enrichpr1.png"
                    alt=""
                  />
                  <button>
                    <a href="/">Know More</a>
                  </button>
                </div>
              </div>
              <div className="product">
                <div className="name">
                  <p>ENR 8KW</p>
                </div>
                <div className="info">
                  <img
                    src="Images/Residential/SetupSeries/enrichpr2.png"
                    alt=""
                  />
                  <button>
                    <a href="/">Know More</a>
                  </button>
                </div>
              </div>
            </div>
            <div className="dwnld_btn">
              <button>
                <a href="/">Download Catalog</a>
              </button>
            </div>
          </div>
          <div className="detailed_product_info_wrapper">
            <div className="detailed_product_info">
              <div className="product_image">
                <img
                  src="Images/Residential/SetupSeries/enrichpr1.png"
                  alt=""
                />
              </div>
              <div className="product_info">
                <div className="product_chng_button_wrapper">
                  <button>
                    <a href="/">ENR 3.6KW</a>
                  </button>
                  <button>
                    <a href="/">ENR 8KW</a>
                  </button>
                </div>
                <div className="all_information">
                  <h1>Product Info</h1>
                  <p>
                    Our aim is to empower residents with affordable solar
                    solutions tailored to meet their energy needs.
                    <br /> With our expertise and dedication, we're here to
                    transform the way you power your home
                  </p>
                  <div className="cta_btn_wrapper">
                    <button className="cta">
                      <a href="/">Buy Now</a>
                    </button>
                    <button className="cta">
                      <a href="/">Download</a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="Partner_with_us">
            <div className="partner_image" data-aos="zoom-in">
              <img
                src="Images/commercialHome/Partner with us.jpeg"
                alt="Area Map"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="partner_contents">
              <h1>Partner with us now! </h1>
              <p>Join Our Network Of Dealers And Distributors</p>
              <button>
                <Link to="/">Join Now</Link>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ComEnrichSeries;
