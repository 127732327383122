import React, { useEffect, useState } from "react";
import "./productsetupres.scss";
import { Link } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import $ from "jquery";
import MyModel from "./MyModel";

const ProductResSetuP = (props) => {
  const img = props.product.images.image1;
  const [mainImage, setMainImage] = useState(img);
  useEffect(() => {
    setMainImage(props.product.images.image1); // Ensure mainImage is updated when product changes
  }, [props.product]);
  const ProductButtons = props.ProductButtons;
  const PDF_FILE = props?.pdfurl;

  const [selectedButton, setSelectedButton] = useState(ProductButtons[0].id);
  console.log("PDF_FILE", PDF_FILE);
  console.log("ProductImage", mainImage);
  const handlePDF = (url) => {
    if (url) {
      console.log("PDF File URL:", url);

      const fileName = url.split("/").pop();
      console.log("File Name:", fileName);

      const aTag = document.createElement("a");
      aTag.href = url;
      aTag.setAttribute("download", fileName);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    } else {
      console.error("PDF file URL is undefined");
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <section className="products_range_setup">
        <div className="products_range_heading">
          <h1>Explore Our Products Range</h1>
        </div>
        <div className="products_range_wrapper">
          <button className="get_latest_updates_on_products">Get Latest Updates</button>
          <div className="display_image_container">
            <div className="main_image">
              <img
                src={mainImage}
                alt="Main Image"
                style={{
                  objectFit: "cover",
                  width: "100%",

                  borderRadius: "20px",
                }}
              />
            </div>
            <div className="change_main_image_buttons">
              <button
                className="img_chng_btn"
                onClick={() => {
                  setMainImage(props.product.images.image1);
                }}
              >
                <img
                  src={props.product.images.image1}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    borderRadius: "14px",
                  }}
                />
              </button>
              <button
                className="img_chng_btn"
                onClick={() => {
                  setMainImage(props.product.images.image2);
                }}
              >
                <img
                  src={props.product.images.image2}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    borderRadius: "14px",
                  }}
                />
              </button>
              <button
                className="img_chng_btn"
                onClick={() => {
                  setMainImage(props.product.images.image3);
                }}
              >
                <img
                  src={props.product.images.image3}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    borderRadius: "14px",
                  }}
                />
              </button>
              <button
                className="img_chng_btn"
                onClick={() => {
                  setMainImage(props.product.images.image4);
                }}
              >
                <img
                  src={props.product.images.image4}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    borderRadius: "14px",
                  }}
                />
              </button>
            </div>
          </div>
          <div className="product_details_wrapper">
            <div className="change_product_buttons">
              {ProductButtons.map((item, index) => (
                <div key={item.id}>
                  <input
                    type="radio"
                    name="change_product"
                    id={`product_${index}`}
                    // onClick={item.action}a
                    onClick={() => {
                      setMainImage(
                        item.id === 1
                          ? props.product.images.image1
                          : props.product.images.image1
                      );
                      item.action();
                      setSelectedButton(item.id);
                    }}
                    checked={item.checked}
                    // checked={item.id === selectedButton}
                  />
                  <label
                    className={item.id === selectedButton ? "selected" : ""}
                    style={{
                      backgroundColor:
                        item.id === selectedButton ? "#FBBA00" : " ",
                      boxShadow:
                        item.id === selectedButton
                          ? "0px 3.653px 16.529px 0px rgba(255, 195, 106, 0.63)"
                          : " ",
                    }}
                    htmlFor={`product_${index}`}
                  >
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
            <div className="description">
              {/* <h3>Description</h3> */}
              <ul className="ms-4" style={{ margin: "0px", padding: "0px" }}>
                {props.product.desc.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
            <div className="cta_buttons">
              <button
                type="button"
                className="cta"
                // data-bs-toggle="modal"
                // data-bs-target="#exampleModal"
                onClick={handleOpenModal}
              >
                Download Brochure
              </button>
              <button className="cta">
                <Link
                  style={{ color: "black" }}
                  to={props.product.cta_links.buy_now}
                >
                  Buy Now
                </Link>
              </button>
            </div>
            <MyModel
              pdf={() => handlePDF(PDF_FILE)}
              show={showModal}
              handleClose={handleCloseModal}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductResSetuP;
