import React from "react";
import "./commercialSecondSection.scss";

const CommercialSecondSection = () => {
  return (
    <>
      <section className="commercial_second_section">
        <div className="image_wrapper" data-aos="zoom-in">
          <img src="Images/commercialHome/Commercial_H_one.jpeg" alt="Image" />
        </div>
        <div className="content_container" data-aos="fade-left">
          <p>Hybrid Solar & Storage Power Solutions</p>
          <h1>
            Commercial Solar <br /> Revolution
          </h1>
          <p>
            At Skipper Green, we provide 360 degrees coverage turnkey green
            solutions, including everything from designing, installation,
            commissioning, to maintenance and after-sale services.
          </p>
          <button>
            <a href="/about_us">Explore</a>
          </button>
        </div>
      </section>

      <section className="commercial_second_section_mobile">
        <div className="heading_wrapper">
          <p>Empowering Businesses with<br/> Solar Solutions.</p>
          <h1>
            Solar Revolution
          </h1>
        </div>
        <div className="image_wrapper">
          <img src="Images/commercialHome/Commercial_H_one.jpeg" alt="Image" />
        </div>
        <div className="content_container">
          <p>
            At Skipper Green, we provide 360 degrees coverage turnkey green
            solutions, including everything from designing, installation,
            commissioning, to maintenance and after-sale services.
          </p>
          <button>
            <a href="/about_us">Explore</a>
          </button>
        </div>
      </section>
    </>
  );
};

export default CommercialSecondSection;
