import React, { useEffect } from "react";
import "./aboutUs.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { LuPhone, LuFactory } from "react-icons/lu";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";

const About2 = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="about2 ">
        <div className="about-container">
          <img src="Images/newhomeassets/Power To Empower.jpg" alt="" />
        </div>
      </div>
      <div className="about-section">
        <div className="about-section-container">
          <div className="about-first">
            <img src="Images/header logo.png" alt="" />
          </div>
          <div className="about-second">
            <h1 style={{ fontWeight: "700", color: "#19B01F" }}>
              Empowering a Sustainable <br /> Future with Innovative <br />
              Solar Solutions
            </h1>
            <p>
              ''Skipper Group, a renowned global leader in Power and
              Infrastructure, excels in serving diverse clients in power
              transmission, distribution, and generation across more than 50
              countries. Operating under the Skipper Group umbrella, Skipper
              Green is a specialised brand dedicated to providing reliable and
              cost-effective solar energy solutions. We specialise in designing
              and installing Hybrid Solar systems with storage, catering to
              Residential, Commercial, Industrial, and utility-scale
              applications, all customized to meet each customer's unique needs.
              Backed by our experienced team, we offer a comprehensive turnkey
              solution, covering design, installation, commissioning,
              maintenance, and post-sales service.''
            </p>
          </div>
        </div>
      </div>

      <div className="vision_container_wrapper">
        <div className="vision_wrapper">
            <div className="vision">
                <img src="Images/newhomeassets/vision.png" alt="" />
            </div>
            <div className="vision">
            <img src="Images/newhomeassets/mission.png" alt="" />
            </div>
        </div>
        <div className="global_wrapper">
            <img src="Images/newhomeassets/all_together.png" alt="" />
        </div>
      </div>

      <div className="about2-bottom ">
        <div className="about-container">
          <img
            src="Images/aboutus/df sc.png"
            style={{ objectFit: "cover" }}
            alt=""
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About2;
