import React from "react";

const Phone = ({ width, height }) => {
  return (
    <svg
      width="22"
      height="37"
      viewBox="0 0 22 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.99199 0H18.6678C20.1347 0 21.3348 1.10099 21.3348 2.44663V32.7017C21.3348 35.0648 19.2273 36.9982 16.6516 36.9982H5.33371C2.40011 36.9982 0 34.7963 0 32.1049V2.74507C0 1.2352 1.34636 0 2.99212 0H2.99199ZM2.99199 1.4283C2.20548 1.4283 1.55671 2.0235 1.55671 2.74507V32.1049C1.55671 34.0079 3.25925 35.5699 5.3336 35.5699H16.6514C18.368 35.5699 19.7778 34.2765 19.7778 32.7017V2.44663C19.7778 1.8893 19.2753 1.4283 18.6677 1.4283H2.99187H2.99199Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.9918 2.14209C2.6348 2.14209 2.33447 2.41773 2.33447 2.74515V13.4264C2.33447 14.9355 3.68858 16.1778 5.33353 16.1778H16.6514C17.9385 16.1778 18.9998 15.2041 18.9998 14.0232V2.44681C18.9998 2.28342 18.8458 2.14209 18.6677 2.14209H2.9918Z"
        // fill="#18AF1E"
      />
      <path
        d="M18.2127 3.8374H3.12158V9.51832H18.2127V3.8374Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5479 12.9614C14.9598 12.9614 15.2936 13.2678 15.2936 13.6457C15.2936 14.0236 14.9598 14.33 14.5479 14.33C14.1361 14.33 13.8022 14.0236 13.8022 13.6457C13.8022 13.2678 14.1361 12.9614 14.5479 12.9614ZM14.5479 13.0952C14.8791 13.0952 15.1478 13.3418 15.1478 13.6457C15.1478 13.9496 14.8791 14.1962 14.5479 14.1962C14.2167 14.1962 13.9481 13.9496 13.9481 13.6457C13.9481 13.3418 14.2167 13.0952 14.5479 13.0952Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2867 12.9614C17.6985 12.9614 18.0324 13.2678 18.0324 13.6457C18.0324 14.0236 17.6985 14.33 17.2867 14.33C16.8749 14.33 16.541 14.0236 16.541 13.6457C16.541 13.2678 16.8749 12.9614 17.2867 12.9614ZM17.2867 13.0952C17.6179 13.0952 17.8866 13.3418 17.8866 13.6457C17.8866 13.9496 17.6179 14.1962 17.2867 14.1962C16.9555 14.1962 16.6868 13.9496 16.6868 13.6457C16.6868 13.3418 16.9555 13.0952 17.2867 13.0952Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.81667 16.5151H17.9517C18.3127 16.5151 18.6082 16.7707 18.6082 17.083V19.4803C18.6082 19.7927 18.3127 20.0482 17.9517 20.0482H3.81667C3.45563 20.0482 3.16016 19.7927 3.16016 19.4803V17.083C3.16016 16.7707 3.45563 16.5151 3.81667 16.5151ZM3.81667 16.7185H17.9517C18.1834 16.7185 18.3732 16.8826 18.3732 17.083V19.4803C18.3732 19.6808 18.1835 19.8449 17.9517 19.8449H3.81667C3.58492 19.8449 3.39515 19.6808 3.39515 19.4803V17.083C3.39515 16.8826 3.5848 16.7185 3.81667 16.7185Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.36675 20.3447H6.21072C6.84377 20.3447 7.36163 20.8199 7.36163 21.4009C7.36163 21.9818 6.84377 22.457 6.21072 22.457H4.36675C3.7337 22.457 3.21582 21.9818 3.21582 21.4009C3.21582 20.8199 3.7337 20.3447 4.36675 20.3447ZM4.36675 20.6086H6.21061C6.68545 20.6086 7.07391 20.9651 7.07391 21.4009C7.07391 21.8366 6.68545 22.1931 6.21061 22.1931H4.36675C3.89191 22.1931 3.50345 21.8366 3.50345 21.4009C3.50345 20.9651 3.89191 20.6086 4.36675 20.6086Z"
        // fill="#18AF1E"
      />
      <path
        d="M5.40198 22.5962H5.16548V21.7049C5.07908 21.7857 4.97724 21.8455 4.85997 21.8842V21.6696C4.92169 21.6494 4.98874 21.6112 5.06112 21.5551C5.1335 21.4984 5.18316 21.4325 5.21009 21.3573H5.40198V22.5962Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1055 20.3447H11.9495C12.5825 20.3447 13.1004 20.8199 13.1004 21.4009C13.1004 21.9818 12.5825 22.457 11.9495 22.457H10.1055C9.47245 22.457 8.95459 21.9818 8.95459 21.4009C8.95459 20.8199 9.47245 20.3447 10.1055 20.3447ZM10.1055 20.6086H11.9494C12.4242 20.6086 12.8127 20.9651 12.8127 21.4009C12.8127 21.8366 12.4242 22.1931 11.9494 22.1931H10.1055C9.63065 22.1931 9.24219 21.8366 9.24219 21.4009C9.24219 20.9651 9.63065 20.6086 10.1055 20.6086Z"
        // fill="#18AF1E"
      />
      <path
        d="M11.3338 22.3765V22.5962H10.5048C10.5138 22.5132 10.5407 22.4346 10.5856 22.3605C10.6305 22.2859 10.7192 22.1872 10.8516 22.0643C10.9582 21.965 11.0236 21.8976 11.0477 21.8623C11.0802 21.8135 11.0965 21.7652 11.0965 21.7175C11.0965 21.6648 11.0822 21.6244 11.0536 21.5963C11.0255 21.5677 10.9865 21.5534 10.9366 21.5534C10.8872 21.5534 10.8479 21.5683 10.8188 21.598C10.7896 21.6278 10.7728 21.6771 10.7683 21.7462L10.5326 21.7226C10.5466 21.5924 10.5907 21.499 10.6647 21.4423C10.7388 21.3857 10.8314 21.3573 10.9425 21.3573C11.0642 21.3573 11.1599 21.3902 11.2295 21.4558C11.299 21.5214 11.3338 21.6031 11.3338 21.7007C11.3338 21.7563 11.3237 21.8093 11.3035 21.8598C11.2839 21.9097 11.2525 21.9622 11.2093 22.0172C11.1807 22.0536 11.129 22.1061 11.0544 22.1745C10.9798 22.243 10.9324 22.2884 10.9122 22.3109C10.8925 22.3333 10.8766 22.3552 10.8642 22.3765H11.3338Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3574 20.3447H17.2014C17.8345 20.3447 18.3523 20.8199 18.3523 21.4009C18.3523 21.9818 17.8345 22.457 17.2014 22.457H15.3574C14.7244 22.457 14.2065 21.9818 14.2065 21.4009C14.2065 20.8199 14.7244 20.3447 15.3574 20.3447ZM15.3574 20.6086H17.2013C17.6762 20.6086 18.0646 20.9651 18.0646 21.4009C18.0646 21.8366 17.6762 22.1931 17.2013 22.1931H15.3574C14.8826 22.1931 14.4941 21.8366 14.4941 21.4009C14.4941 20.9651 14.8826 20.6086 15.3574 20.6086Z"
        // fill="#18AF1E"
      />
      <path
        d="M15.7787 22.2683L16.0076 22.2405C16.0149 22.2989 16.0345 22.3435 16.0665 22.3744C16.0985 22.4052 16.1372 22.4206 16.1826 22.4206C16.2315 22.4206 16.2724 22.4021 16.3055 22.3651C16.3392 22.3281 16.356 22.2781 16.356 22.2153C16.356 22.1558 16.34 22.1087 16.308 22.0739C16.2761 22.0391 16.2371 22.0217 16.1911 22.0217C16.1608 22.0217 16.1246 22.0276 16.0825 22.0394L16.1086 21.8467C16.1725 21.8483 16.2214 21.8346 16.255 21.8054C16.2887 21.7757 16.3055 21.7364 16.3055 21.6876C16.3055 21.6461 16.2932 21.613 16.2685 21.5883C16.2438 21.5636 16.211 21.5513 16.17 21.5513C16.1296 21.5513 16.0951 21.5653 16.0665 21.5933C16.0379 21.6214 16.0205 21.6623 16.0143 21.7162L15.7963 21.6792C15.8115 21.6046 15.8342 21.5451 15.8645 21.5008C15.8954 21.4559 15.938 21.4208 15.9924 21.3956C16.0474 21.3697 16.1089 21.3568 16.1768 21.3568C16.2929 21.3568 16.386 21.3939 16.4562 21.4679C16.514 21.5285 16.5429 21.597 16.5429 21.6733C16.5429 21.7816 16.4837 21.868 16.3653 21.9325C16.436 21.9477 16.4924 21.9816 16.5344 22.0343C16.5771 22.0871 16.5984 22.1508 16.5984 22.2254C16.5984 22.3337 16.5589 22.426 16.4797 22.5023C16.4006 22.5786 16.3022 22.6167 16.1843 22.6167C16.0727 22.6167 15.9801 22.5848 15.9066 22.5208C15.8331 22.4563 15.7905 22.3721 15.7787 22.2683Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.36675 23.1699H6.21072C6.84377 23.1699 7.36163 23.6451 7.36163 24.2261C7.36163 24.807 6.84377 25.2822 6.21072 25.2822H4.36675C3.7337 25.2822 3.21582 24.807 3.21582 24.2261C3.21582 23.6451 3.7337 23.1699 4.36675 23.1699ZM4.36675 23.4339H6.21061C6.68545 23.4339 7.07391 23.7903 7.07391 24.2261C7.07391 24.6618 6.68545 25.0183 6.21061 25.0183H4.36675C3.89191 25.0183 3.50345 24.6618 3.50345 24.2261C3.50345 23.7903 3.89191 23.4339 4.36675 23.4339Z"
        // fill="#18AF1E"
      />
      <path
        d="M5.26058 25.4214V25.1731H4.75561V24.9661L5.29088 24.1825H5.4895V24.9652H5.64268V25.1731H5.4895V25.4214H5.26058ZM5.26058 24.9652V24.5436L4.97696 24.9652H5.26058Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1055 23.1699H11.9495C12.5825 23.1699 13.1004 23.6451 13.1004 24.2261C13.1004 24.807 12.5825 25.2822 11.9495 25.2822H10.1055C9.47245 25.2822 8.95459 24.807 8.95459 24.2261C8.95459 23.6451 9.47245 23.1699 10.1055 23.1699ZM10.1055 23.4339H11.9494C12.4242 23.4339 12.8127 23.7903 12.8127 24.2261C12.8127 24.6618 12.4242 25.0183 11.9494 25.0183H10.1055C9.63065 25.0183 9.24219 24.6618 9.24219 24.2261C9.24219 23.7903 9.63065 23.4339 10.1055 23.4339Z"
        // fill="#18AF1E"
      />
      <path
        d="M10.5385 25.1041L10.7742 25.0797C10.7809 25.133 10.8008 25.1754 10.8339 25.2068C10.867 25.2376 10.9052 25.2531 10.9484 25.2531C10.9977 25.2531 11.0395 25.2331 11.0738 25.1933C11.108 25.1529 11.1251 25.0923 11.1251 25.0115C11.1251 24.9358 11.108 24.8791 11.0738 24.8415C11.0401 24.8034 10.9961 24.7843 10.9416 24.7843C10.8737 24.7843 10.8129 24.8143 10.759 24.8743L10.5671 24.8466L10.6883 24.2044H11.3136V24.4258H10.8676L10.8305 24.6353C10.8833 24.6089 10.9371 24.5958 10.9921 24.5958C11.0971 24.5958 11.186 24.6339 11.2589 24.7102C11.3319 24.7865 11.3683 24.8856 11.3683 25.0073C11.3683 25.1089 11.3389 25.1995 11.28 25.2792C11.1997 25.388 11.0884 25.4424 10.9458 25.4424C10.8319 25.4424 10.7391 25.4118 10.6673 25.3507C10.5955 25.2895 10.5525 25.2073 10.5385 25.1041Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3574 23.1699H17.2014C17.8345 23.1699 18.3523 23.6451 18.3523 24.2261C18.3523 24.807 17.8345 25.2822 17.2014 25.2822H15.3574C14.7244 25.2822 14.2065 24.807 14.2065 24.2261C14.2065 23.6451 14.7244 23.1699 15.3574 23.1699ZM15.3574 23.4339H17.2013C17.6762 23.4339 18.0646 23.7903 18.0646 24.2261C18.0646 24.6618 17.6762 25.0183 17.2013 25.0183H15.3574C14.8826 25.0183 14.4941 24.6618 14.4941 24.2261C14.4941 23.7903 14.8826 23.4339 15.3574 23.4339Z"
        // fill="#18AF1E"
      />
      <path
        d="M16.5883 24.4897L16.3594 24.515C16.3538 24.4678 16.3392 24.433 16.3156 24.4106C16.2921 24.3882 16.2615 24.3769 16.2239 24.3769C16.174 24.3769 16.1316 24.3994 16.0968 24.4443C16.0626 24.4892 16.041 24.5826 16.032 24.7245C16.0909 24.655 16.1641 24.6202 16.2517 24.6202C16.3504 24.6202 16.4349 24.6578 16.505 24.7329C16.5757 24.8081 16.611 24.9052 16.611 25.0241C16.611 25.1504 16.574 25.2517 16.4999 25.328C16.4259 25.4043 16.3308 25.4424 16.2146 25.4424C16.0901 25.4424 15.9877 25.3942 15.9074 25.2977C15.8272 25.2006 15.7871 25.0418 15.7871 24.8213C15.7871 24.5952 15.8289 24.4322 15.9125 24.3323C15.9961 24.2325 16.1047 24.1825 16.2382 24.1825C16.3319 24.1825 16.4093 24.2089 16.4705 24.2616C16.5322 24.3138 16.5715 24.3898 16.5883 24.4897ZM16.0522 25.0056C16.0522 25.0825 16.0699 25.142 16.1052 25.1841C16.1406 25.2256 16.181 25.2463 16.2264 25.2463C16.2702 25.2463 16.3066 25.2292 16.3358 25.195C16.365 25.1608 16.3796 25.1047 16.3796 25.0267C16.3796 24.9464 16.3639 24.8878 16.3325 24.8508C16.301 24.8132 16.2618 24.7944 16.2146 24.7944C16.1692 24.7944 16.1307 24.8123 16.0993 24.8482C16.0679 24.8836 16.0522 24.9361 16.0522 25.0056Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.36675 25.9951H6.21072C6.84377 25.9951 7.36163 26.4703 7.36163 27.0513C7.36163 27.6322 6.84377 28.1074 6.21072 28.1074H4.36675C3.7337 28.1074 3.21582 27.6322 3.21582 27.0513C3.21582 26.4703 3.7337 25.9951 4.36675 25.9951ZM4.36675 26.259H6.21061C6.68545 26.259 7.07391 26.6155 7.07391 27.0513C7.07391 27.487 6.68545 27.8435 6.21061 27.8435H4.36675C3.89191 27.8435 3.50345 27.487 3.50345 27.0513C3.50345 26.6155 3.89191 26.259 4.36675 26.259Z"
        // fill="#18AF1E"
      />
      <path
        d="M4.79685 27.2493V27.0296H5.60565V27.2013C5.53888 27.2669 5.47099 27.3612 5.40198 27.4841C5.33296 27.607 5.28022 27.7377 5.24375 27.8763C5.20784 28.0143 5.19017 28.1377 5.19073 28.2466H4.96265C4.96658 28.076 5.00165 27.9021 5.06785 27.7248C5.13462 27.5475 5.22355 27.389 5.33465 27.2493H4.79685Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1055 25.9951H11.9495C12.5825 25.9951 13.1004 26.4703 13.1004 27.0513C13.1004 27.6322 12.5825 28.1074 11.9495 28.1074H10.1055C9.47245 28.1074 8.95459 27.6322 8.95459 27.0513C8.95459 26.4703 9.47245 25.9951 10.1055 25.9951ZM10.1055 26.259H11.9494C12.4242 26.259 12.8127 26.6155 12.8127 27.0513C12.8127 27.487 12.4242 27.8435 11.9494 27.8435H10.1055C9.63065 27.8435 9.24219 27.487 9.24219 27.0513C9.24219 26.6155 9.63065 26.259 10.1055 26.259Z"
        // fill="#18AF1E"
      />
      <path
        d="M10.738 27.5809C10.6768 27.5551 10.6322 27.5197 10.6041 27.4748C10.5767 27.4294 10.5629 27.3797 10.5629 27.3259C10.5629 27.2338 10.5949 27.1578 10.6589 27.0978C10.7234 27.0377 10.8148 27.0077 10.9332 27.0077C11.0505 27.0077 11.1414 27.0377 11.2059 27.0978C11.271 27.1578 11.3035 27.2338 11.3035 27.3259C11.3035 27.3831 11.2887 27.4341 11.2589 27.479C11.2292 27.5234 11.1874 27.5573 11.1335 27.5809C11.202 27.6084 11.2539 27.6485 11.2892 27.7012C11.3251 27.754 11.3431 27.8148 11.3431 27.8838C11.3431 27.9977 11.3066 28.0903 11.2337 28.1616C11.1613 28.2328 11.0648 28.2685 10.9442 28.2685C10.8319 28.2685 10.7385 28.239 10.6639 28.1801C10.5758 28.1105 10.5318 28.0151 10.5318 27.8939C10.5318 27.8272 10.5483 27.766 10.5814 27.7105C10.6145 27.6544 10.6667 27.6112 10.738 27.5809ZM10.7868 27.3427C10.7868 27.3898 10.8 27.4266 10.8263 27.4529C10.8533 27.4793 10.8889 27.4925 10.9332 27.4925C10.9781 27.4925 11.014 27.4793 11.0409 27.4529C11.0679 27.426 11.0813 27.389 11.0813 27.3418C11.0813 27.2975 11.0679 27.2622 11.0409 27.2358C11.0146 27.2089 10.9795 27.1954 10.9357 27.1954C10.8903 27.1954 10.8541 27.2089 10.8272 27.2358C10.8002 27.2627 10.7868 27.2984 10.7868 27.3427ZM10.7649 27.8712C10.7649 27.9363 10.7814 27.9871 10.8146 28.0236C10.8482 28.06 10.89 28.0783 10.94 28.0783C10.9888 28.0783 11.0292 28.0609 11.0611 28.0261C11.0931 27.9907 11.1091 27.94 11.1091 27.8737C11.1091 27.816 11.0928 27.7697 11.0603 27.7349C11.0278 27.6995 10.9865 27.6819 10.9366 27.6819C10.8788 27.6819 10.8356 27.7018 10.807 27.7416C10.7789 27.7814 10.7649 27.8247 10.7649 27.8712Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3574 25.9951H17.2014C17.8345 25.9951 18.3523 26.4703 18.3523 27.0513C18.3523 27.6322 17.8345 28.1074 17.2014 28.1074H15.3574C14.7244 28.1074 14.2065 27.6322 14.2065 27.0513C14.2065 26.4703 14.7244 25.9951 15.3574 25.9951ZM15.3574 26.259H17.2013C17.6762 26.259 18.0646 26.6155 18.0646 27.0513C18.0646 27.487 17.6762 27.8435 17.2013 27.8435H15.3574C14.8826 27.8435 14.4941 27.487 14.4941 27.0513C14.4941 26.6155 14.8826 26.259 15.3574 26.259Z"
        // fill="#18AF1E"
      />
      <path
        d="M15.7921 27.9613L16.0211 27.936C16.0267 27.9826 16.0413 28.0171 16.0648 28.0395C16.0884 28.062 16.1195 28.0732 16.1582 28.0732C16.2071 28.0732 16.2486 28.0508 16.2828 28.0059C16.317 27.961 16.3389 27.8679 16.3484 27.7265C16.289 27.7955 16.2146 27.83 16.1254 27.83C16.0284 27.83 15.9445 27.7927 15.8738 27.718C15.8036 27.6429 15.7686 27.5452 15.7686 27.4252C15.7686 27.3 15.8056 27.1993 15.8797 27.123C15.9543 27.0462 16.0491 27.0077 16.1641 27.0077C16.2893 27.0077 16.3919 27.0563 16.4722 27.1533C16.5524 27.2498 16.5925 27.4089 16.5925 27.6305C16.5925 27.8561 16.5507 28.0188 16.4671 28.1187C16.3835 28.2185 16.2747 28.2685 16.1406 28.2685C16.0441 28.2685 15.9661 28.2429 15.9066 28.1919C15.8471 28.1403 15.809 28.0634 15.7921 27.9613ZM16.3274 27.4445C16.3274 27.3682 16.3097 27.309 16.2744 27.2669C16.2396 27.2249 16.1992 27.2038 16.1532 27.2038C16.1094 27.2038 16.073 27.2212 16.0438 27.256C16.0152 27.2902 16.0009 27.3466 16.0009 27.4252C16.0009 27.5048 16.0166 27.5635 16.048 27.6011C16.0794 27.6381 16.1187 27.6566 16.1658 27.6566C16.2113 27.6566 16.2494 27.6387 16.2803 27.6027C16.3117 27.5668 16.3274 27.5141 16.3274 27.4445Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.36675 28.8208H6.21072C6.84377 28.8208 7.36163 29.296 7.36163 29.8769C7.36163 30.4579 6.84377 30.9331 6.21072 30.9331H4.36675C3.7337 30.9331 3.21582 30.4579 3.21582 29.8769C3.21582 29.296 3.7337 28.8208 4.36675 28.8208ZM4.36675 29.0847H6.21061C6.68545 29.0847 7.07391 29.4412 7.07391 29.8769C7.07391 30.3127 6.68545 30.6691 6.21061 30.6691H4.36675C3.89191 30.6691 3.50345 30.3127 3.50345 29.8769C3.50345 29.4412 3.89191 29.0847 4.36675 29.0847Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1055 28.8208H11.9495C12.5825 28.8208 13.1004 29.296 13.1004 29.8769C13.1004 30.4579 12.5825 30.9331 11.9495 30.9331H10.1055C9.47245 30.9331 8.95459 30.4579 8.95459 29.8769C8.95459 29.296 9.47245 28.8208 10.1055 28.8208ZM10.1055 29.0847H11.9494C12.4242 29.0847 12.8127 29.4412 12.8127 29.8769C12.8127 30.3127 12.4242 30.6691 11.9494 30.6691H10.1055C9.63065 30.6691 9.24219 30.3127 9.24219 29.8769C9.24219 29.4412 9.63065 29.0847 10.1055 29.0847Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3574 28.8208H17.2014C17.8345 28.8208 18.3523 29.296 18.3523 29.8769C18.3523 30.4579 17.8345 30.9331 17.2014 30.9331H15.3574C14.7244 30.9331 14.2065 30.4579 14.2065 29.8769C14.2065 29.296 14.7244 28.8208 15.3574 28.8208ZM15.3574 29.0847H17.2013C17.6762 29.0847 18.0646 29.4412 18.0646 29.8769C18.0646 30.3127 17.6762 30.6691 17.2013 30.6691H15.3574C14.8826 30.6691 14.4941 30.3127 14.4941 29.8769C14.4941 29.4412 14.8826 29.0847 15.3574 29.0847Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.21543 30.3174V30.5013L3.98877 30.0681L5.21543 29.6348V29.8187H6.39062V30.3173H5.21543V30.3174ZM6.27104 30.2076H5.09586V30.3414L4.3217 30.0681L5.09586 29.7947V29.9285H6.27104V30.2075V30.2076Z"
        // fill="#18AF1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1734 30.4234V30.6073L14.9468 30.1742L16.1734 29.741V29.9249L16.8055 29.9248V29.4878H17.3488V29.9248V30.4228V30.4233L16.1736 30.4231L16.1734 30.4234ZM17.2255 29.9249H17.2291V29.5977H16.925V29.8087H16.9213V30.0347H16.663V30.0348L16.0539 30.0347V29.9009L15.2797 30.1743L16.0539 30.4477V30.3139H16.663H16.8055H16.925H17.0987H17.2291V30.0349H17.2255V29.9251V29.9249Z"
        // fill="#18AF1E"
      />
      <path
        d="M10.9276 29.9594C11.0471 29.9594 11.1405 30.002 11.2078 30.0873C11.2881 30.1883 11.3282 30.3558 11.3282 30.5898C11.3282 30.8232 11.2878 30.9909 11.207 31.093C11.1402 31.1772 11.0471 31.2193 10.9276 31.2193C10.8075 31.2193 10.7107 31.1733 10.6372 31.0813C10.5637 30.9887 10.527 30.824 10.527 30.5872C10.527 30.3549 10.5674 30.1877 10.6482 30.0856C10.7149 30.0015 10.8081 29.9594 10.9276 29.9594ZM10.9276 30.1555C10.899 30.1555 10.8734 30.1647 10.851 30.1833C10.8285 30.2012 10.8112 30.2337 10.7988 30.2809C10.7825 30.342 10.7744 30.445 10.7744 30.5898C10.7744 30.7345 10.7817 30.8341 10.7963 30.8885C10.8109 30.9424 10.8291 30.9783 10.851 30.9963C10.8734 31.0142 10.899 31.0232 10.9276 31.0232C10.9562 31.0232 10.9817 31.0142 11.0042 30.9963C11.0266 30.9777 11.044 30.9449 11.0563 30.8978C11.0726 30.8372 11.0808 30.7345 11.0808 30.5898C11.0808 30.445 11.0735 30.3457 11.0589 30.2918C11.0443 30.2374 11.0258 30.2012 11.0033 30.1833C10.9814 30.1647 10.9562 30.1555 10.9276 30.1555Z"
        // fill="#18AF1E"
      />
    </svg>
  );
};

export default Phone;
