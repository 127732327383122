import React, { useState } from "react";
import "./footer.scss";
import {
  FaInstagram,
  FaFacebook,
  // FaLinkedin,
  FaXTwitter,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const Footer = () => {
  const [subscribeFormvalues, setSubscribeFromvalues] = useState({
    email: "",
  });
  const handleSubscribeChange = (e) => {
    setSubscribeFromvalues({
      ...subscribeFormvalues,
      [e.target.name]: e.target.value,
    });
  };

  const subscribeNewLetter = (e) => {
    e.preventDefault();
    const payload = {
      email: subscribeFormvalues?.email,
    };

    axios
      .post("https://dashboard.skippergreen.com/newsletter/", payload)
      .then((response) => {
        // Handle successful response
        Swal.fire({
          title: "Success!",
          html: `Thank you for subscribing! Stay tuned for exclusive updates, tips, and more.`,
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#18AF1E",
        });
        setSubscribeFromvalues({ email: "" });
        console.log("Response data:", response?.data);
      })
      .catch((error) => {
        // Check if error is a response error
        if (error.response && error.response.status === 500) {
          // Server-side error occurred
          if (error.response.data && error.response.data.email) {
            // Email validation error
            Swal.fire({
              title: "Error!",
              text: error.response.data.email[0], // Display the first error message
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#FF0000",
            });
          } else {
            // Other server-side error
            Swal.fire({
              title: "Error!",
              text: "An unexpected error occurred. Please try again later.",
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#FF0000",
            });
          }
        } else {
          // Handle other errors
          console.error("Error:", error);
        }
      });
  };
  return (
    <>
      <section className="footer_wrapper">
        <div className="subscribe">
          <div className="subscribe-container">
            <h3>Subscribe to our Newsletter</h3>
            <p>
              Be the first to know about our latest updates, exclusive offers,
              and more.
            </p>
            <form className="subsribe-input" onSubmit={subscribeNewLetter}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={subscribeFormvalues?.email}
                  id=""
                  onChange={handleSubscribeChange}
                />
                <input type="submit" value="Subscribe" className="btn"/>
            </form>
          </div>
        </div>
        <div className="footer">
          <div className="footer-container">
            <div className="logos_social_icons_wrapper">
              <div className="company_logo_wrapper">
                <Link to="/">
                  <img src="/Images/header logo.png" alt="Logo Skipper Green" />
                </Link>
                {/* <Link to="/"><img src="Images/common/FooterLogo.png" alt="Logo Skipper Green" /></Link> */}
              </div>
              <ul className="social_icons">
                <li>
                  <Link target="_blank"  to={'https://www.facebook.com/people/Skipper-Green/61555956117815/'}>
                  <FaFacebook />

                  </Link>
                  {/* <a
                    target="_blank"
                    href="https://www.facebook.com/people/Skipper-Green/61555956117815/"
                  >
                    <FaFacebook />
                  </a> */}
                </li>
                <li>
                <Link target="_blank"  to={'https://www.instagram.com/skipper_green/?hl=en'}>
                <FaInstagram />

                  </Link>
                  {/* <a
                    target="_blank"
                    href="https://www.instagram.com/skipper_green/?hl=en"
                  >
                    <FaInstagram />
                  </a> */}
                </li>
                {/* <li>
                <a target="_blank" href="/linkedin">
                  <FaLinkedin />
                </a>
              </li> */}
                <li>
                <Link target="_blank"  to={'https://twitter.com/SkipperGreen_ng'}>
                <FaXTwitter />

                  </Link>
                  {/* <a target="_blank" href="https://twitter.com/SkipperGreen_ng">
                    <FaXTwitter />
                  </a> */}
                </li>
              </ul>
            </div>

            <div className="our_pages_wrapper">
              <h1>Quick Search</h1>
              <ul>
                {/* <li>
                <Link to="/">Home</Link>
              </li> */}
                <li>
                  <Link to="/about_us">About Us</Link>
                </li>
                <li>
                  <Link to="/">Residential</Link>
                </li>
                <li>
                  <Link to="/commercial">Commercial</Link>
                </li>
                <li>
                  <Link to="/blogs">Blog</Link>
                </li>
                
                {/* <li>
                  <Link to="/news_&_blogs">News & Events</Link>
                </li> */}
              </ul>
            </div>
            <div className="factory_address_wrapper">
              <h1>Experience Center</h1>
              <p>
                Skipper House,1E Ligali Ayorinde Street, Victoria Island, Lagos,
                Nigeria
              </p>
              <h1>Factory Address</h1>
              <p>
                Plots 113/114 Ikorodu Industrial Scheme Estate,Odogunyan,
                Ikorodu, Lagos, Nigeria
              </p>
            </div>
            <div className="get_in_touch_wrapper">
              <h1>Get In Touch</h1>
              <p>sales@skippergreen.com</p>
              <p className="toll_free">TOLL FREE NUMBER</p>
              <p>08-0000-838383</p>
            </div>
          </div>
          <div className="footer-container-mobile">
            <div className="our_pages_wrapper">
              <h1>Quick Search</h1>
              <ul>
                {/* <li>
                <Link to="/">Home</Link>
              </li> */}
                <li>
                  <Link to="/about_us">About Us</Link>
                </li>
                <li>
                  <Link to="/">Residential</Link>
                </li>
                <li>
                  <Link to="/commercial">Commercial</Link>
                </li>
                {/* <li>
                  <Link to="/news_&_blogs">News & Events</Link>
                </li> */}
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                
              </ul>
            </div>
            <div className="get_in_touch_wrapper">
              <p className="toll_free">TOLL FREE NUMBER</p>
              <p>08-0000-838383</p>
              <h1>Get In Touch</h1>
              <p>sales@skippergreen.com</p>
            </div>
            <div className="factory_address_wrapper">
              <h1>Experience Center</h1>
              <p>
                Skipper House,1E Ligali Ayorinde Street, Victoria Island, Lagos,
                Nigeria
              </p>
              <h1>Factory Address</h1>
              <p>
                Plots 113/114 Ikorodu Industrial Scheme Estate,Odogunyan,
                Ikorodu, Lagos, Nigeria
              </p>
            </div>
            <div className="logos_social_icons_wrapper">
              <div className="company_logo_wrapper">
                <Link to="/">
                  <img src="Images/header logo.png" alt="Logo Skipper Green" />
                </Link>
                {/* <Link to="/"><img src="Images/common/FooterLogo.png" alt="Logo Skipper Green" /></Link> */}
              </div>
              <ul className="social_icons">
                <li>
                  {/* <a
                    target="_blank"
                    href="https://www.facebook.com/people/Skipper-Green/61555956117815/"
                  >
                    <FaFacebook />
                  </a> */}
                    <Link target="_blank"  to={'https://www.facebook.com/people/Skipper-Green/61555956117815/'}>
                    <FaFacebook />

                  </Link>
                </li>
                <li>
                  {/* <a
                    target="_blank"
                    href="https://www.instagram.com/skipper_green/?hl=en"
                  >
                    <FaInstagram />
                  </a> */}
                    <Link target="_blank"  to={'https://www.instagram.com/skipper_green/?hl=en'}>
                <FaInstagram />

                  </Link>
                </li>
                {/* <li>
                <a target="_blank" href="/linkedin">
                  <FaLinkedin />
                </a>
              </li> */}
                <li>
                <Link target="_blank"  to={'https://twitter.com/SkipperGreen_ng'}>
                <FaXTwitter />

                  </Link>
                  {/* <a target="_blank" href="https://twitter.com/SkipperGreen_ng">
                    <FaXTwitter />
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p
            style={{
              marginBottom: "0",
              textAlign: "center",
              paddingBottom: "1rem",
            }}
          >
            Copyright © 2024 T&D West Africa. All rights reserved.
          </p>
        </div>
      </section>
    </>
  );
};

export default Footer;
