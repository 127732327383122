import React from "react";

const FullBattery = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3234 29.7647H28.7435L28.8873 28.4327L25.7102 28.5126C25.6214 28.5162 25.5646 28.3297 25.6108 28.1823L26.4472 25.4581H23.3252C23.2683 25.4581 23.2222 25.5131 23.2222 25.5771V29.6457C23.2222 29.7114 23.2683 29.7629 23.3252 29.7647"
        fill="#19B01F"
        
      />
      <path
        d="M25.8025 15.4331H31.9684C32.0821 15.4331 32.1762 15.3265 32.1762 15.1969V13.5666C32.1762 13.4352 32.0839 13.3286 31.9684 13.3286H25.8025C25.6888 13.3286 25.5965 13.4352 25.5965 13.5666V15.1969C25.5965 15.3283 25.6888 15.4331 25.8025 15.4331Z"
        fill="#19B01F"
         

      />
      <path
        d="M34.4497 31.0593H29.7577L28.4933 34.1174C28.4187 34.2985 28.2678 34.1618 28.2926 33.9345L28.6034 31.0593H23.3236C23.2668 31.0593 23.2206 31.1126 23.2206 31.1783V34.8846C23.2206 34.9485 23.2668 35.0036 23.3236 35.0036H34.4497C34.5065 35.0036 34.5545 34.9503 34.5545 34.8846V31.1783C34.5545 31.1126 34.5065 31.0593 34.4497 31.0593Z"
        fill="#19B01F"
        

      />
      <path
        d="M34.4498 25.4581H32.0719L30.2924 29.7647H34.4498C34.5066 29.7647 34.5528 29.7096 34.5546 29.6457V25.5771C34.5546 25.5131 34.5066 25.4581 34.4498 25.4581Z"
        fill="#19B01F"
        

      />
      <path
        d="M36.3886 15.941H21.384C20.5315 15.941 19.8371 16.7401 19.8371 17.7187V37.1791C19.8371 38.1576 20.5315 38.955 21.384 38.955H36.3886C37.241 38.955 37.9354 38.1576 37.9354 37.1791V17.7187C37.9354 16.7401 37.241 15.941 36.3886 15.941ZM35.1206 36.4225H22.5507C21.867 36.4225 21.9309 36.4989 21.9309 35.7122V19.2726C21.9309 18.4876 21.867 18.5622 22.5507 18.5622H35.1206C35.8043 18.5622 35.7386 18.4876 35.7386 19.2726V35.7122C35.7386 36.4971 35.8043 36.4225 35.1206 36.4225Z"
        fill="#19B01F"
        

      />
      <path
        d="M34.4497 19.9829H23.3236C23.2668 19.9829 23.2206 20.0362 23.2206 20.1019V24.0427C23.2206 24.1084 23.2668 24.1634 23.3236 24.1634H26.8435L27.7083 21.3451C27.7297 21.2793 27.767 21.2367 27.806 21.2367H31.0808C31.1714 21.2367 31.2264 21.4338 31.1749 21.5795L30.2639 24.1652H34.4479C34.5047 24.1652 34.5509 24.1101 34.5527 24.0444V20.1037C34.5527 20.038 34.5047 19.9847 34.4479 19.9847"
        fill="#19B01F"
        

      />
    </svg>
  );
};

export default FullBattery;
