import React, { useEffect, useState } from "react";
import "./nearByDealers.scss";
import Header from "../../../Common Components/Header/Header";
import Footer from "../../../Common Components/Footer/Footer";
import axios from "axios";
import { GoPersonFill } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { RiSendPlaneFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { GrLocation } from "react-icons/gr";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const NearByDealers = () => {
  const [dealers, setDealers] = useState([]);
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });



  const HandleSelect = async (value) => {
    const results = await geocodeByAddress(value);

    const addressComponents = results[0].address_components;

    let country = "";
    let state = "";
    let city = "";

    // Extract country, state, and city from address components
    addressComponents.forEach((component) => {
      if (component.types.includes("country")) {
        country = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      }
    });

    const ll = await getLatLng(results[0]);
    // console.log(ll);
    setAddress(value);
    setCoordinates(ll);
    localStorage.setItem("lat", ll.lat);
    localStorage.setItem("lng", ll.lng);
    localStorage.setItem("country", country);
    localStorage.setItem("state", state);
    localStorage.setItem("city", city);
  };

  const FetchDealers = () => {
    const city = localStorage.getItem("city");
    const state = localStorage.getItem("state");
    const country = localStorage.getItem("country");
    axios
      .get(
        `https://dashboard.skippergreen.com/dealers/by-location/?city=${city}&state=${state}&country=${country}`
      )
      .then((res) => {
        if (res.status === 200) {
          setDealers(res.data);
          console.log(res.data);
        } else if (res.error) {
          alert("No Dealers Found in your area");
        }
      })
      .catch((error) => {
        console.error("Error fetching dealers:", error);
      });
  };

  useEffect(() => {
    FetchDealers();
  }, []);

  return (
    <>
      <Header />
      <section className="all_nearby_dealers_wrapper">
        <div className="all_nearby_dealers">
          <div className="dealers_heading">
            <h1>Contact Dealer In Your Area</h1>
            <div className="search_input_wrapper">
              <div className="search_input">
                <GrLocation className="loc_icon" />
                {/* <input type="text" placeholder="Search service in your area" /> */}
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={HandleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: "Start typing to search your location",
                          className: "location-search-input",
                        })}
                        style={{
                          width: "155%",
                          height: "100%",
                          // padding: "0.6rem 0.5rem",
                          // borderRadius: "10px",
                          // border: "1px solid #fff",
                          outline: "none",
                          // marginBottom: "1rem",
                          // backgroundColor: "rgba(0, 0, 0, 0.082)",
                        }}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#000",
                                color: "#fff",
                                cursor: "pointer",
                                padding: "0.1rem 0.3rem",
                                borderRadius: "5px",
                                marginBottom: "0.1rem",
                              }
                            : {
                                backgroundColor: "#000",
                                cursor: "pointer",
                                color: "#fff",
                                padding: "0.1rem 0.3rem",
                                borderRadius: "5px",
                                marginBottom: "0.1rem",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <button
                onClick={() => {
                  if (localStorage.getItem("lat")) {
                    navigate("/near_by_dealers");
                  } else {
                    alert("Select a location first.");
                  }
                }}
                className="search_button"
              >
                Search
              </button>
            </div>
          </div>
          <div className="dealers_container">
            <div className="dealer">
              <div className="profile_image_container">
                <img src="Images/profile.png" alt="" />
              </div>
              <div className="profile_content_container">
                <div className="name">
                  <span className="icon">
                    <GoPersonFill />
                  </span>
                  <p>Ali Abdal</p>
                </div>
                <div className="email">
                  <span className="icon">
                    <MdEmail />
                  </span>
                  <p>thelomashraj@gmail.com</p>
                </div>
                <div className="phone">
                  <span className="icon">
                    <FaPhoneAlt />
                  </span>
                  <p>+91 7633828121</p>
                </div>
                <div className="address">
                  <span className="icon">
                    <FaLocationDot />
                  </span>
                  <p>
                    Mr Daniel Izuchukwu Nwoye. 8, My Street, Ilassan Lekki,
                    Lagos 105102.
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate("/contact_dealer");
                }}
                className="send_msg_btn"
              >
                <RiSendPlaneFill className="send_msg_icon" />
                Send Message
              </button>
            </div>
            <div className="dealer">
              <div className="profile_image_container">
                <img src="Images/profile.png" alt="" />
              </div>
              <div className="profile_content_container">
                <div className="name">
                  <span className="icon">
                    <GoPersonFill />
                  </span>
                  <p>Ali Abdal</p>
                </div>
                <div className="email">
                  <span className="icon">
                    <MdEmail />
                  </span>
                  <p>thelomashraj@gmail.com</p>
                </div>
                <div className="phone">
                  <span className="icon">
                    <FaPhoneAlt />
                  </span>
                  <p>+91 7633828121</p>
                </div>
                <div className="address">
                  <span className="icon">
                    <FaLocationDot />
                  </span>
                  <p>
                    Mr Daniel Izuchukwu Nwoye. 8, My Street, Ilassan Lekki,
                    Lagos 105102.
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate("/contact_dealer");
                }}
                className="send_msg_btn"
              >
                <RiSendPlaneFill className="send_msg_icon" />
                Send Message
              </button>
            </div>
            <div className="dealer">
              <div className="profile_image_container">
                <img src="Images/profile.png" alt="" />
              </div>
              <div className="profile_content_container">
                <div className="name">
                  <span className="icon">
                    <GoPersonFill />
                  </span>
                  <p>Ali Abdal</p>
                </div>
                <div className="email">
                  <span className="icon">
                    <MdEmail />
                  </span>
                  <p>thelomashraj@gmail.com</p>
                </div>
                <div className="phone">
                  <span className="icon">
                    <FaPhoneAlt />
                  </span>
                  <p>+91 7633828121</p>
                </div>
                <div className="address">
                  <span className="icon">
                    <FaLocationDot />
                  </span>
                  <p>
                    Mr Daniel Izuchukwu Nwoye. 8, My Street, Ilassan Lekki,
                    Lagos 105102.
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate("/contact_dealer");
                }}
                className="send_msg_btn"
              >
                <RiSendPlaneFill className="send_msg_icon" />
                Send Message
              </button>
            </div>
            <div className="dealer">
              <div className="profile_image_container">
                <img src="Images/profile.png" alt="" />
              </div>
              <div className="profile_content_container">
                <div className="name">
                  <span className="icon">
                    <GoPersonFill />
                  </span>
                  <p>Ali Abdal</p>
                </div>
                <div className="email">
                  <span className="icon">
                    <MdEmail />
                  </span>
                  <p>thelomashraj@gmail.com</p>
                </div>
                <div className="phone">
                  <span className="icon">
                    <FaPhoneAlt />
                  </span>
                  <p>+91 7633828121</p>
                </div>
                <div className="address">
                  <span className="icon">
                    <FaLocationDot />
                  </span>
                  <p>
                    Mr Daniel Izuchukwu Nwoye. 8, My Street, Ilassan Lekki,
                    Lagos 105102.
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate("/contact_dealer");
                }}
                className="send_msg_btn"
              >
                <RiSendPlaneFill className="send_msg_icon" />
                Send Message
              </button>
            </div>
            <div className="dealer">
              <div className="profile_image_container">
                <img src="Images/profile.png" alt="" />
              </div>
              <div className="profile_content_container">
                <div className="name">
                  <span className="icon">
                    <GoPersonFill />
                  </span>
                  <p>Ali Abdal</p>
                </div>
                <div className="email">
                  <span className="icon">
                    <MdEmail />
                  </span>
                  <p>thelomashraj@gmail.com</p>
                </div>
                <div className="phone">
                  <span className="icon">
                    <FaPhoneAlt />
                  </span>
                  <p>+91 7633828121</p>
                </div>
                <div className="address">
                  <span className="icon">
                    <FaLocationDot />
                  </span>
                  <p>
                    Mr Daniel Izuchukwu Nwoye. 8, My Street, Ilassan Lekki,
                    Lagos 105102.
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate("/contact_dealer");
                }}
                className="send_msg_btn"
              >
                <RiSendPlaneFill className="send_msg_icon" />
                Send Message
              </button>
            </div>
            <div className="dealer">
              <div className="profile_image_container">
                <img src="Images/profile.png" alt="" />
              </div>
              <div className="profile_content_container">
                <div className="name">
                  <span className="icon">
                    <GoPersonFill />
                  </span>
                  <p>Ali Abdal</p>
                </div>
                <div className="email">
                  <span className="icon">
                    <MdEmail />
                  </span>
                  <p>thelomashraj@gmail.com</p>
                </div>
                <div className="phone">
                  <span className="icon">
                    <FaPhoneAlt />
                  </span>
                  <p>+91 7633828121</p>
                </div>
                <div className="address">
                  <span className="icon">
                    <FaLocationDot />
                  </span>
                  <p>
                    Mr Daniel Izuchukwu Nwoye. 8, My Street, Ilassan Lekki,
                    Lagos 105102.
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate("/contact_dealer");
                }}
                className="send_msg_btn"
              >
                <RiSendPlaneFill className="send_msg_icon" />
                Send Message
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <ContactDealer/> */}
      <Footer />
    </>
  );
};

export default NearByDealers;
