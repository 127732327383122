import React, { useEffect, useState } from "react";
import "./contactUs.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { LuPhone, LuFactory } from "react-icons/lu";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const ContactUs = () => {
  const [formValues, setFormValues] = useState({
    full_name: "",
    phone_number: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChnage = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formValues.full_name ||
      !formValues.phone_number ||
      !formValues.email ||
      !formValues.message
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all required fields!",
      });
      return; // Stop further execution if any field is empty
    }
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const payload = {
      full_name: formValues?.full_name,
      phone_number: formValues?.phone_number,
      email: formValues?.email,
      message: formValues?.message,
    };

    axios
      .post("https://dashboard.skippergreen.com/contactus/", payload)
      .then((response) => {
        console.log("success", response.data);
        Swal.fire({
          title: "success!",
          html: `Thank you for your interest in Skipper Green! <br> A member of our team will get back to you shortly`,
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: '#18AF1E',
        });
        setFormValues({
          full_name: "",
          phone_number: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("error", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      })
      .finally(() => {
        setIsSubmitting(false); // Reset submitting status to false
      });

    // alert("hello world")
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number" &&
      document.activeElement.classList.contains("noscroll")) {
      document.activeElement.blur();
    }
  });
  return (
    <>
      <Header />
      <section id="top" className="contact_us">
        <div className="contact_banner">
          <img src="Images/contact_us/contact_us.jpg" alt="" />

          <div className="about_text_container">
            <h1>We're here to help you</h1>
          </div>
        </div>
        <div className="get_in_touch_wrapper">
          <h1>Get In Touch</h1>
          <div className="get_in_touch">
            <div className="touch_comp_wrapper">
              <LuPhone className="touch_icon" />
              <h3>Phone No</h3>
              <p>07089501618</p>
              <p>07031269442</p>
            </div>
            <div className="touch_comp_wrapper">
              <HiOutlineMail className="touch_icon" />
              <h3>Email</h3>
              <p>sales@skippergreen.com</p>
            </div>
            <div className="touch_comp_wrapper">
              <LuFactory className="touch_icon" />
              <h3>Factory Address</h3>
              <p>
                Plots 113/114 Ikorodu Industrial Scheme Estate, Odogunyan,
                Ikorodu, Lagos, Nigeria
              </p>
            </div>
            <div className="touch_comp_wrapper">
              <HiOutlineBuildingOffice className="touch_icon" />
              <h3>Experience Center</h3>
              <p>
                Skipper House, 1E Ligali Ayorinde Street, Victoria Island,
                Lagos, Nigeria
              </p>
            </div>
          </div>
        </div>
        <div className="contact_form_wrapper">
          <div className="contact_image">
            {/* <img src="Images/contact.gif" alt="" /> */}
            <img src="Images/5114855.gif" alt="" />
          </div>
          <form onSubmit={handleSubmit} className="contact_form">
            <label htmlFor="name">
              Full Name <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Name"
              onChange={handleChnage}
              name="full_name"
              value={formValues?.full_name}
              required
            />

            <label htmlFor="name">
              Phone Number <span>*</span>
            </label>
            <input
              type="number"
              inputMode="numeric"
              placeholder="Phone Number"
              onChange={handleChnage}
              style={{ scrollbarGutter: "none", scrollBehavior: "none", WheelEvent: "none" }}
              name="phone_number"
              class="noscroll"

              pattern="[0-9]*"
              maxLength={10}
              minLength={10}
              value={formValues?.phone_number}
              required
            />

            <label htmlFor="name">
              Email <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Email Address"
              onChange={handleChnage}
              name="email"
              required
              value={formValues?.email}
            />

            <label htmlFor="name">
              Your Message
            </label>
            <textarea
              type="text"
              placeholder="Your Message"
              onChange={handleChnage}
              name="message"
              rows="5"
              value={formValues?.message}
              required
            />
            <button type="submit" className="btn btn submit_btn">
              Submit
            </button>
            {/* <input type="button"  value="Submit" /> */}
          </form>
        </div>
        <div className="address_map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1153.5515654724954!2d3.440919276531533!3d6.432410912230607!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf518b99d249b%3A0x769cd76e5c78a629!2s1e%20Ligali%20Ayorinde%20St%2C%20Eti-Osa%2C%20Lagos%20106104%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sus!4v1705568049590!5m2!1sen!2sus"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
