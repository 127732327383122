import React, { useEffect, useState } from "react";
import "./resSetUpSeries.scss";
import Header from "../../../Common Components/Header/Header";
import Footer from "../../../Common Components/Footer/Footer";
import FirstSection from "../ProductPageComponents/FirstSection";
import SecondSection from "../ProductPageComponents/SecondSection";
import ThirdSection from "../ProductPageComponents/ThirdSection";
import Products from "../../../Common Components/Products/Products";
import Aos from "aos";
import "aos/dist/aos.css";
import Kite from "../../../Common Components/Icons/Kite";
import { Mobile } from "../../../Common Components/Icons/Mobile";
import { Hand } from "../../../Common Components/Icons/Hand";
import Round from "../../../Common Components/Icons/Round";
import { Punch } from "../../../Common Components/Icons/Punch";
import Bulb from "../../../Common Components/Icons/Bulb";
import FullBattery from "../../../Common Components/Icons/FullBattery";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProductResSetuP from "../../../Common Components/Products/ProductResSetup";
import WallMount from "../../../Common Components/Icons/WallMount";
import Comment from "../../../Common Components/Icons/Comment";

const ResSetUpSeries = () => {
  const styleColor = {
    backgroundColor: "green",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1000, offset: 200 });
  }, []);

  const pdfFile = "1 STEP-UP SERIES.pdf";
  const [checked, setChecked] = useState({
    product1: "true",
    product2: "",
  });

  const Product1 = {
    id: 1,
    desc: [
      "20W Polycrystalline Solar Panel ",
      "6000mAh battery ",
      "3 * 3W LED Bulbs",
      "Multi Phone charging cable",
    ],

    images: {
      image1: "Images/SetupResidental/Step-Up.png",
      image2: "Images/SetupResidental/SHS 02.png",
      image3: "Images/SetupResidental/SHS03.jpeg",

      // image3: "Images/SetupResidental/SHS03.jpg",
      image4: "Images/SetupResidental/Box Image.jpg",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };
  const Product2 = {
    id: 2,
    desc: [
      "50W Polycrystalline Solar Panel ",
      "18000mAh battery ",
      "Standing Fan",
      "3 * 3W LED Bulbs",
      "Multi Phone charging cable",
    ],

    images: {
      image1: "Images/SetupResidental/Step-Up 02.png",
      image2: "Images/SetupResidental/SHS.png",
      image3: "Images/SetupResidental/SHS03.jpg",

      // image3: "Images/SetupResidental/SHS03.jpeg",
      image4: "Images/SetupResidental/Box Image.jpg",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const [product, setProduct] = useState(Product1);

  const ChangeProduct1 = () => {
    setProduct(Product1);
    setChecked({ product1: "true" });
  };

  const ChangeProduct2 = () => {
    setProduct(Product2);
    setChecked({ product2: "true" });
  };

  const ProductButtons = [
    {
      id: 1,
      name: "SG-S20",
      action: ChangeProduct1,
      checked: checked.product1,
    },
    {
      id: 2,
      name: "SG-S50",
      action: ChangeProduct2,
      checked: checked.product2,
    },
  ];

  const [selectedIcon, setSelectedIcon] = useState({
    id: 1,
    label: "Icon 1",
    data: "Data 1",
    icon: <WallMount />,
    title: "Wall Mount",
    image: "Images/SetupResidental/Wall Mount.jpg",
    content: [
      "Emphasis on home’s aesthetics because of subtle visibility",
      "Best for multi-directional walls, allowing to capture more sunlight",
      "Also can be installed at varying heights, reducing shading and improving system’s performance",
    ],
  });

  const [selectedIconId, setSelectedIconId] = useState(1);

  const iconData = [
    {
      id: 1,
      label: "Icon 1",
      data: "Data 1",
      icon: <WallMount />,
      title: "Wall Mount",
      image: "Images/SetupResidental/Wall Mount.jpg",
      content: [
        "Emphasis on home’s aesthetics because of subtle visibility",
        "Best for multi-directional walls, allowing to capture more sunlight",
        "Also can be installed at varying heights, reducing shading and improving system’s performance",
      ],
    },
    {
      id: 2,
      icon: <FullBattery />,
      title: "Long Battery life",
      // image: "Images/enrich/Rectangle 236 (1).png",
      image: "Images/SetupResidental/long battery life.jpg",
      // content: "Converting into long-term energy cost & battery replacement savings",
      content: [
        "Converting into long-term energy cost & battery replacement savings",
      ],
    },
    {
      id: 3,
      label: "Icon 3",
      data: "Data 3",
      icon: <Hand />,
      title: "Pay-As-You-Go (PAYG)",
      image: "Images/SetupResidental/pay as you go.jpg",
      content: [
        "Pay in pocket-friendly amounts over time and save yourselves from the harsh upfront price",
        "Involves an integrated locking/unlocking functionality in the hardware",
        "Flexible repayment conditions. Users only pay when the system is working, which is why, trustable after-sales",
      ],
    },
    {
      id: 4,
      label: "Icon 4",
      data: "Data 4",
      icon: <Punch />,
      title: "Portable Power",
      image: "Images/SetupResidental/Portable power.jpg",
      content: [
        "Green energy, anywhere, anytime",
        " A reliable backup for emergency situations-power outages, floods or any natural calamity",
      ],
    },
    {
      id: 5,
      label: "Icon 5",
      data: "Data 5",
      icon: <Bulb />,
      title: "Lights Up ",
      title2: "Multiple Rooms",
      image: "Images/SetupResidental/light up multiple rooms.jpg",

      content: [
        "Modern lighting and energy to homes",
        "At practically zero cost!",
        "Grid-like power ",
      ],
    },
    {
      id: 6,
      label: "Icon 6",
      data: "Data 6",
      icon: <Mobile />,
      title: "USB Charging For Mobile",
      image: "Images/SetupResidental/USB charging.jpg",
      content: ["Portable", "Durable", "Pocket-friendly", "Reliable"],
    },
  ];

  const handleIconClick = (icon) => {
    setSelectedIconId(icon.id);
    setSelectedIcon({
      title: icon.title,
      title2: icon.title2,
      content: icon.content,
      content1: icon.content1,
      content2: icon.content2,
      image: icon.image,
      icon: icon.icon,
    });
  };

  const handleImageArrowClick = (direction) => {
    const currentIndex = iconData.findIndex(
      (icon) => icon.id === selectedIcon.id
    );
    let newIndex;

    if (direction === "left") {
      newIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : iconData.length - 1;
    } else {
      newIndex = currentIndex + 1 < iconData.length ? currentIndex + 1 : 0;
    }

    setSelectedIcon({
      title: iconData[newIndex].title,
      title2: iconData[newIndex].title2,
      content: iconData[newIndex].content,
      content1: iconData[newIndex].content1,
      content2: iconData[newIndex].content2,
      image: iconData[newIndex].image,
      icon: iconData[newIndex].icon,
      id: iconData[newIndex].id,
    });
    setSelectedIconId(iconData[newIndex].id);
  };
  return (
    <>
      <Header />
      <div
        id="carouselExampleIndicators"
        className="carousel slide res_third_section"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div
          className="carousel-inner"
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          <div
            className="carousel-item active"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              backgroundSize: "cover",
            }}
          >
            <img
              src="Images/SetupResidental/Step-up Main Banner Residential 01.jpg"
              className="d-block w-100"
              alt="..."
              style={{ backgroundSize: "cover", objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/SetupResidental/hero banner 2.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ backgroundSize: "cover", objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/SetupResidental/hero banner 3jpeg.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ backgroundSize: "cover", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
      {/* <FirstSection /> */}
      {/* <SecondSection /> */}
      <div className="residental-features-heaidng">
        <div className="residental-features-heaidng-con">
          <h1>Features</h1>
        </div>
      </div>

      <section className="residental-features-step-up">
        <div className="resitental-features-container">
          <div className="residetal-sidebar-first">
            <div className="resitentl-colmn1">
              <div className="resitentl-colmn1-icon">{selectedIcon.icon}</div>
            </div>
            <div className="resitentl-colmn2">
              <h1>{selectedIcon.title}</h1>
              <h1>{selectedIcon.title2}</h1>
            </div>
            <div className="resitentl-colmn3">
              <ul>
                {selectedIcon.content.map((contentItem, index) => (
                  <li key={index}>{contentItem}</li>
                ))}
              </ul>
              {/* <p>{selectedIcon?.content}</p> */}
            </div>
            <div className="resitentl-colmn4">
              {iconData.map((icon) => (
                <div
                  key={icon.id}
                  // className="icon"

                  // className={`icon ${icon.id === selectedIcon.id ? "selected" : ""}`}
                  className={`filter-delhi ${
                    icon.id === selectedIconId ? "selected" : ""
                  }`}
                  onClick={() => handleIconClick(icon)}
                >
                  {icon.icon}
                </div>
              ))}
            </div>
          </div>
          <div
            className="residetal-sidebar-second"
            style={{ boxShadow: "15px 15px 0px #18AF1E", borderRadius: "28px" }}
          >
            <div className="arrow-left">
              {" "}
              {/* <ArrowBackIosIcon
                onClick={() => handleImageArrowClick("left")}
                className="large-icon"
              /> */}
            </div>
            <div className="small-arrow-left">
              <ArrowBackIosIcon
                onClick={() => handleImageArrowClick("left")}
                className="large-icon-small"
              />
            </div>

            <img src={selectedIcon.image} alt="" />
            <div className="arrow-right">
              {/* <ArrowForwardIosIcon
                onClick={() => handleImageArrowClick("right")}
                className="large-icon"
              /> */}
            </div>
            <div className="small-arrow-right">
              <ArrowForwardIosIcon
                onClick={() => handleImageArrowClick("right")}
                className="large-icon-small"
              />
            </div>
          </div>
        </div>
      </section>

      <ProductResSetuP
        product={product}
        changeProduct1={ChangeProduct1}
        changeProduct2={ChangeProduct2}
        checked={checked}
        ProductButtons={ProductButtons}
        pdfurl={pdfFile}
      />
      {/* <ThirdSection /> */}
      <Footer />
    </>
  );
};

export default ResSetUpSeries;
