import React, { useEffect } from "react";
import "./secondSection.scss";
import Aos from "aos";
import "aos/dist/aos.css";

const SecondSection = () => {
  useEffect(() => {
    Aos.init({ duration: 1000, offset: 200 });
  }, []);
  return (
    <section className="res_home_sec_section">
      <div className="resident-solar-revolution">
        <div className="resident-solar-evo-container">
          <video autoPlay loop muted playsInline>
            <source src="video/upwardlink.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="image-residental">
          <div className="image_wrapper" data-aos="zoom-in-right">
            <img
              src="Images/newhomeassets/homepagesection2.png"
              alt="Solar Revolution"
            />
          </div>

          <div className="content_container">
            <p>Who we are</p>
            <h1>
              Solar and Storage
              <br /> Power Solutions
            </h1>
            <p>
              Skipper Green, a specialized brand under the Skipper Group
              umbrella, provides reliable and cost-effective Solar Energy
              solutions globally. We specialize in designing and installing
              Hybrid Solar systems tailored to residential, commercial,
              industrial, and utility-scale applications, supported by a
              comprehensive turnkey solution from design to post-sales service.
            </p>
            <button>
              <a href="/about_us">Explore</a>
            </button>
          </div>
        </div>
      </div>

      <section className="res_home_second_section">
        <div className="power_to_empower">
          <div className="power_heading" data-aos="zoom-in">
            <img
              src="Images/newhomeassets/Power to Empower 1.png"
              alt="Power to Empower"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="power_video_wrapper">
            <video
              className="power_video_wrapper-video"
              autoPlay
              loop
              muted
              playsInline
            >
              <source
                src="Images/newhomeassets/Power_video.webm"
                type="video/webm"
              />
            </video>
          </div>
        </div>
        <div className="redident-solar-downward">
          <video autoPlay loop muted playsInline>
            <source src="video/down.mp4" type="video/mp4" />
          </video>
        </div>
      </section>
    </section>
  );
};

export default SecondSection;
