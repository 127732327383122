import React, { useEffect, useState } from "react";
import "./newsAndEvents.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { IoIosArrowDropright } from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const NewsAndEvents = () => {
  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState([]);

  const ReadMore = (blogId) => {
    localStorage.setItem("BlogId", blogId);
    history("/blog");
  };

  const ReadByCat = (category) => {
    localStorage.setItem("category", category);
    history("/blogs-by-tag");
  };
  const handleNavigateButton =(idBlog)=>{
    history(`/blog/name/${idBlog}`)
  }
  const handleCategory =(category)=>{
    history(`/blogs-by-tag/${category}`)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://dashboard.skippergreen.com/blogs/"); // Replace with your API endpoint
        setBlogs(response.data);
        console.log(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Pad single digit day and month with leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };


  return (
    <>
      <Header />
      <section className="News_and_events">
        <div className="hero_section">
          <div className="header_text">
            <h1>Blog</h1>
          </div>
          <img src="/Images/BannerCS.png" alt="Banner Case Studies" />
        </div>
        <div className="content_section_wrapper">
          <div className="content_section">
            {/* <div className="info_wrapper">
              <p>
                Home &gt; Product Range &gt; <span>You are here</span>
              </p>
            </div> */}
            {/* <div className="years_wrapper">
              <p>Get year wise updates</p>
              <div className="year">
                <div className="radio_btn">
                  <input type="radio" name="year" id="2024" />
                  <label htmlFor="2024">2024</label>
                </div>
                <div className="radio_btn">
                  <input type="radio" name="year" id="2023" />
                  <label htmlFor="2023">2023</label>
                </div>
                <div className="radio_btn">
                  <input type="radio" name="year" id="2022" />
                  <label htmlFor="2022">2022</label>
                </div>
              </div>
            </div> */}
            <div className="articles_wrapper">
              {blogs.map((item) => {
                return (
                  <div key={item.id} className="article">
                    <div className="image_container">
                      <div
                        id={`carouselExampleIndicators${item.id}`}
                        className="carousel slide"
                      >
                        <div className="carousel-indicators">
                          {item.blog_img && (
                            <button
                              type="button"
                              data-bs-target={`#carouselExampleIndicators${item.id}`}
                              data-bs-slide-to="0"
                              className="active"
                              aria-current="true"
                              aria-label="Slide 1"
                            ></button>
                          )}
                          {item.blog_img1 && (
                            <button
                              type="button"
                              data-bs-target={`#carouselExampleIndicators${item.id}`}
                              data-bs-slide-to="1"
                              aria-label="Slide 2"
                            ></button>
                          )}
                          {item.blog_img2 && (
                            <button
                              type="button"
                              data-bs-target={`#carouselExampleIndicators${item.id}`}
                              data-bs-slide-to="2"
                              aria-label="Slide 3"
                            ></button>
                          )}
                          {item.blog_img3 && (
                            <button
                              type="button"
                              data-bs-target={`#carouselExampleIndicators${item.id}`}
                              data-bs-slide-to="3"
                              aria-label="Slide 4"
                            ></button>
                          )}
                        </div>
                        <div className="carousel-inner">
                          {item.blog_img && (
                            <div className="carousel-item active">
                              <img
                                src={`https://dashboard.skippergreen.com${item.blog_img}`}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                          )}
                          {item.blog_img1 && (
                            <div className="carousel-item">
                              <img
                                src={`https://dashboard.skippergreen.com${item.blog_img1}`}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                          )}
                          {item.blog_img2 && (
                            <div className="carousel-item">
                              <img
                                src={`https://dashboard.skippergreen.com${item.blog_img2}`}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                          )}
                          {item.blog_img3 && (
                            <div className="carousel-item">
                              <img
                                src={`https://dashboard.skippergreen.com${item.blog_img3}`}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <h1>{item?.title}</h1>
                      <div className="tags_wrapper">
                        <p>Published {formatDate(item.created_at)}</p>
                        <span>Tags</span>
                        <p
                        // onClick={() => {
                        //   ReadByCat(item.category);
                        // }}
                        onClick={()=>handleCategory(item?.category)}
                        className="read_by_cat_btn"
                        >{item?.category}</p>
                      </div>
                      <p className="desc">
                        {item?.content.split(" ").slice(0, 50).join(" ") +
                          "..."}
                      </p>
                      <button
                        // onClick={() => {
                        //   ReadMore(item.id);
                        // }}
                        onClick={()=>handleNavigateButton(item?.id)}
                        className="read_more_btn"
                      >
                        <IoIosArrowDropright className="icon" />
                        Read More
                      </button>
                  </div>
                );
              })}

            </div>
          </div>
          <div className="latest_posts_wrapper">
            <h1>Latest Posts</h1>
            {blogs.map((item) => (
              <>
                <ul className="single_post_link">
                  <li
                    // onClick={() => {
                    //   ReadMore(item.id);
                    // }}
                    onClick={()=>handleNavigateButton(item?.id)}

                  >
                    {item?.title}
                  </li>
                  <li>
                    {item?.content.split(" ").slice(0, 20).join(" ") + "..."}
                  </li>
                </ul>
              </>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default NewsAndEvents;
