import React from "react";

const KiteSecond = ({width, height}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.1947 13.0284C22.7654 13.4272 22.842 14.7105 22.8427 15.6987L22.8426 25.671C22.8421 26.3713 22.6889 27.132 23.3355 27.2342C24.3471 27.3941 24.0522 25.5168 24.0522 24.0653L24.0522 16.1213C24.052 13.864 23.9132 14.1762 26.177 14.1762H38.1027C40.7038 14.1762 40.5585 13.9106 40.5572 15.7832L40.5604 29.7317C40.555 30.6406 40.344 30.6729 39.4576 30.6693C36.8077 30.6584 34.1557 30.6677 31.5055 30.6677C29.8457 30.6677 27.064 30.2427 27.5212 31.5077C27.7376 32.1065 29.1766 31.8752 30.2368 31.8752C32.8869 31.8752 35.5371 31.8752 38.1873 31.8752C39.6469 31.8752 40.6695 32.1061 41.4286 31.1064C41.9997 30.3541 41.7677 28.4462 41.7677 27.3612L41.768 15.4452C41.7698 14.7933 41.7674 14.1743 41.4347 13.7205C41.1673 13.3558 40.7072 13.0385 40.1352 12.9791C39.3336 12.8958 33.519 12.966 32.1821 12.966C30.854 12.966 25.0012 12.8035 24.1947 13.0284L24.1947 13.0284Z"
        // fill="#19B01F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.8205 16.9931C36.5894 17.4624 21.864 32.042 19.5909 34.3018C19.3446 34.5467 18.4449 35.1836 18.9873 35.728C19.5247 36.2675 20.1357 35.4036 20.3717 35.1669L33.0597 22.4908C33.6872 21.8644 37.2848 18.1269 37.7364 17.9085C37.7347 18.9464 37.7374 19.9848 37.7363 21.0229C37.7359 21.4636 37.6064 22.1645 37.9293 22.4664C38.2551 22.7708 38.8127 22.6268 38.9222 22.1834C39.0295 21.7493 38.9509 19.601 38.9509 18.9947C38.9509 15.2082 39.5392 15.7806 35.8191 15.7806C35.0563 15.7806 33.2577 15.6856 32.6145 15.7951C32.1488 15.8745 31.9451 16.3738 32.222 16.7548C32.494 17.1289 33.18 16.9958 33.62 16.9952C34.6868 16.9935 35.754 16.9969 36.8205 16.9931L36.8205 16.9931Z"
        // fill="#19B01F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2379 27.9405C15.9988 28.3192 15.9991 29.2207 15.9993 30.6571C15.9995 31.9811 15.9993 33.3051 15.9993 34.6291C15.9993 35.7617 15.7648 37.366 16.414 38.1294C17.1067 38.944 18.5464 38.7139 19.8335 38.7139C21.1303 38.7139 22.4272 38.7139 23.7241 38.7139C25.128 38.7139 26.21 38.8329 26.7198 37.7094C26.9913 37.111 26.8732 34.7866 26.8753 33.953C26.8768 33.3326 26.9752 32.5787 26.2582 32.5793C25.5291 32.58 25.657 33.4353 25.6581 34.0375C25.6593 34.6854 25.6578 35.3333 25.6583 35.9813C25.6587 36.5757 25.8257 37.3628 25.2357 37.4878C24.8792 37.5635 17.6759 37.5497 17.4548 37.4258C17.0514 37.1997 17.2098 36.2279 17.2098 35.7277L17.2051 29.8056C17.2228 28.9448 17.633 29.0569 18.395 29.0613C19.0715 29.0653 19.7489 29.0617 20.4255 29.0619C20.9544 29.0621 21.8549 29.1958 22.0734 28.7472C22.4344 28.0061 21.7071 27.8423 21.104 27.8446C20.2464 27.8479 17.8834 27.7432 17.2379 27.9405Z"
        // fill="#19B01F"
      />
    </svg>
  );
};


export default KiteSecond