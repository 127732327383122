import React, { useEffect } from "react";
import Header from "../../../Common Components/Header/Header";
import Footer from "../../../Common Components/Footer/Footer";
import CommercialFirstSection from "./Sections/CommercialFirstSection.jsx/CommercialFirstSection";
import CommercialSecondSection from "./Sections/CommercialSecondSection/CommercialSecondSection";
import CommercialThirdSection from "./Sections/CommercialThirdSectio/CommercialThirdSection";
import CommercialFourthSection from "./Sections/CommercialFourthSection/CommercialFourthSection";
import CommercialHeader from "../../../Common Components/CommercialHeader/CommercialHeader";
import Aos from "aos";
import 'aos/dist/aos.css'

const CommercialHome = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({duration: 1000, offset: 200,});
  }, [])
  return (
    <>
      <CommercialHeader />
      <CommercialThirdSection />
      <CommercialSecondSection />
      <CommercialFirstSection />
      <CommercialFourthSection />
      <Footer />
    </>
  );
};

export default CommercialHome;
