import React from "react";

const Umbrella = ({ width, height }) => {
  return (
    <svg
      width="36"
      height="28"
      viewBox="0 0 36 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.3397 13.9865V12.74C34.9806 12.0037 34.7663 8.50021 31.7228 6.11003C28.8514 3.85502 27.7835 3.70148 24.1274 3.15283C24.0877 2.49293 24.3047 2.25647 23.7559 1.91553C23.2321 2.1133 23.4365 1.60642 23.3046 3.19425C19.6828 3.33117 16.5559 5.03806 15.0711 6.71066C13.5356 8.44006 12.6368 9.99165 12.2419 12.3876C12.1017 13.2393 11.7166 14.1045 12.7741 14.1068C12.9068 13.7014 13.044 11.3687 15.4953 11.6343C17.9663 11.9019 16.9343 13.9626 18.1165 14.0852C18.8014 13.5275 18.1946 12.4521 19.7178 11.8172C20.93 11.3118 22.1242 11.8854 22.6351 12.4927C23.3711 13.3673 23.262 17.613 23.1108 19.1974H23.9148C23.8573 16.9424 23.7736 13.3632 24.1897 12.6975C24.6965 11.8867 25.9532 11.2601 27.1824 11.8113C29.0428 12.6456 28.1733 14.2461 29.0905 14.1073C29.7437 14.0083 29.2105 11.6868 31.8396 11.618C34.2145 11.5558 34.3145 13.6531 34.6073 14.0751C35.2363 14.1633 34.9112 14.2008 35.3397 13.9865V13.9865Z"
        // fill="#19B01F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.34271 19.1968C7.62458 19.1194 6.85583 19.1496 6.16895 19.1968H8.34271Z"
        // fill="#19B01F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.58029 3.90186C9.06038 4.04309 8.89734 4.36362 8.58093 4.81088C8.3759 5.10055 7.99499 5.66459 7.82973 6.09232C6.72599 8.9484 11.9811 9.22471 11.269 6.31296C11.0782 5.53259 10.0867 4.17016 9.58029 3.90194V3.90186Z"
        // fill="#19B01F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.04541 11.3942V12.1765C1.48173 12.7768 1.71935 13.3968 2.72456 13.4587C6.24141 13.6755 3.95847 8.94552 2.87446 8.98808C2.01121 9.31855 1.61966 10.6285 1.0455 11.3942H1.04541Z"
        // fill="#19B01F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.04492 2.43413V3.28256C1.47329 3.80483 1.70189 4.41618 2.72915 4.48855C6.03215 4.72142 4.39249 0.241461 2.69646 0.0166016C2.02022 0.680628 1.65531 1.61313 1.04492 2.43413Z"
        // fill="#19B01F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.22 0C14.6117 0.264696 13.6804 1.57085 13.4538 2.30409C13.0754 3.52811 13.9952 4.51504 15.2158 4.49552C18.3806 4.44487 16.44 0.569052 15.22 0Z"
        // fill="#19B01F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.965332 27.4281H2.64918V19.168H0.965332V27.4281ZM4.20784 27.4281H5.88029V24.7468H7.72345C9.4187 24.7468 10.6702 23.5926 10.6702 21.9574C10.6702 20.3222 9.4187 19.168 7.72345 19.168H4.20784V27.4281ZM5.88029 23.1236V20.7911H7.54132C8.37189 20.7911 8.94078 21.248 8.94078 21.9573C8.94078 22.6667 8.37189 23.1235 7.54132 23.1235H5.88029V23.1236ZM14.3791 27.5844C16.245 27.5844 17.4851 26.4662 17.4851 24.7108C17.4851 23.0275 16.2905 22.0897 14.9025 22.0897C14.2653 22.0897 13.7648 22.3062 13.3779 22.5947L13.4234 20.7671H17.0528V19.1681H11.8762L11.7624 23.6288L12.8888 24.1097C13.3097 23.7731 13.8104 23.5687 14.4019 23.5687C15.2552 23.5687 15.8127 24.0496 15.8127 24.7711C15.8127 25.4805 15.2666 26.0335 14.3905 26.0335C13.6397 26.0335 12.9229 25.6488 12.3427 25.0596L11.3529 26.31C12.081 27.0796 13.0709 27.5845 14.3792 27.5845L14.3791 27.5844ZM22.2295 27.4281H23.9133V25.7448H24.9486V24.2539H23.9133V19.18H21.8767L18.2588 24.3741L18.4181 25.7448H22.2295V27.4281ZM20.0223 24.2539L22.2295 21.0557V24.2539H20.0223Z"
        // fill="#19B01F"
      />
    </svg>
  );
};

export default Umbrella;
