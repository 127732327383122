import React, { useEffect } from "react";
import Header from "../../../Common Components/Header/Header";
import Footer from "../../../Common Components/Footer/Footer";
import FirstSection from "./Sections/FirstSection/FirstSection";
import SecondSection from "./Sections/SecondSection/SecondSection";
import ThirdSection from "./Sections/ThirdSection/ThirdSection";
import FourthSection from "./Sections/FourthSection/FourthSection";
import Subscribe from "./Sections/Subscribe/Subscribe";

const ResidentialHome = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <ThirdSection />
      <SecondSection />
      <FirstSection />
      <FourthSection />
      {/* <Subscribe/> */}
      <Footer />
    </>
  );
};

export default ResidentialHome;
