import React, { useEffect, useState } from "react";
import "../../../Common Components/Application Area/applicationArea.css";
import "./comFreedomSeries.scss";
import CommercialHeader from "../../../Common Components/CommercialHeader/CommercialHeader";
import Footer from "../../../Common Components/Footer/Footer";
import { Link } from "react-router-dom";
// import { GiKite } from "react-icons/gi";
// import { TbHeartRateMonitor } from "react-icons/tb";
// import { TbArrowsLeftRight } from "react-icons/tb";
// import { LuShieldCheck } from "react-icons/lu";
// import Products from "../../../Common Components/Products/Products";
import Aos from "aos";
import "aos/dist/aos.css";
// import Safe from "../../../Common Components/Icons/Safe";
import Shield from "../../../Common Components/Icons/Shield";
// import Wifi from "../../../Common Components/Icons/Wifi";
// import Battery from "../../../Common Components/Icons/Battery";
import ProductCoomerCialFreedom from "../../../Common Components/Products/ProductCommercialFreedom";
import WifiSecond from "../../../Common Components/Icons/WifiSecond";
import BatterySecond from "../../../Common Components/Icons/BatterySecond";
import ApplicationArea from "../../../Common Components/Application Area/ApplicationArea";

const ComFreedomSeries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1000, offset: 200 });
  }, []);
  const [checked, setChecked] = useState({
    product1: "true",
    product2: "",
  });
  const pdfFile = "2.2 FREEDOM Series.pdf";

  const Product1 = {
    id: 1,
    desc1: "Hybrid Solar Inverter : 15KW",
    desc2: "Smart Battery : up to 48KWh",
    desc3: "Solar Panel : up to 22KWp",

    desc: `
    Earn electricity independency with this Minigrid 
Hybrid Solution
15 KVA and 60 KVA-
Inverter with an efficiency of over 90%
Tubler Gel VRLA, Maintenance-free Battery
Mono Crystaline PV Panel

    `,
    images: {
      image: "Images/FreedeomResidental/FreedomPr.png",
      image2: "Images/FreedeomResidental/Freedom 01.jpg",
      image3: "Images/FreedeomResidental/Freedom 02.jpg",
      image4: "Images/FreedeomResidental/Freedom 03.jpg",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product2 = {
    id: 2,
    desc1: "Hybrid Solar Inverter : 60KW",
    desc2: "Smart Battery : up to 192KWh",
    desc3: "Solar Panel : up to 88KWp",
    desc: `
    Earn electricity independency with this Minigrid 
Hybrid Solution
15 KVA and 60 KVA-
Inverter with an efficiency of over 90%
Tubler Gel VRLA, Maintenance-free Battery
Mono Crystaline PV Panel
    `,
    images: {
      image: "Images/FreedeomResidental/FreedomPr02.png",
      image2: "Images/FreedeomResidental/Freedom 01.jpg",
      image3: "Images/FreedeomResidental/Freedom 02.jpg",
      image4: "Images/FreedeomResidental/Freedom 03.jpg",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };
  const [product, setProduct] = useState(Product1);

  const ChangeProduct1 = () => {
    setProduct(Product1);
    setChecked({ product1: "true" });
  };

  const ChangeProduct2 = () => {
    setProduct(Product2);
    setChecked({ product2: "true" });
  };
  const ProductButtons = [
    {
      id: 1,
      name: "SG-FH15",
      action: ChangeProduct1,
      checked: checked.product1,
    },
    {
      id: 2,
      name: "SG-FH60",
      action: ChangeProduct2,
      checked: checked.product2,
    },
  ];

  const itemsData = [
    {
      src: "/applicationareacommercialfreedom/Freedom01.png",
      title: "Small Residential",
      title1: "Buildings & Complexes",
      icon: "/applicationareacommercialfreedom/icons/Freedom01.png",
    },
    {
      src: "/applicationareacommercialfreedom/Freedom02.png",
      title: "Educational",
      title1: "Institutions",
      icon: "/applicationareacommercialfreedom/icons/Freedom02.png",
    },
    {
      src: "/applicationareacommercialfreedom/Freedom03.png",
      title: "Banks/ offices",
      title1: "commercial complexes",
      icon: "/applicationareacommercialfreedom/icons/Freedom03.png",
    },
    {
      src: "/applicationareacommercialfreedom/Freedom04.png",
      title: "Public Health ",
      title1: "centers/ hospitals",
      icon: "/applicationareacommercialfreedom/icons/Freedom04.png",
    },
    {
      src: "/applicationareacommercialfreedom/Freedom05.png",
      title: "manufacturing",
      title1: "& Small industries",
      icon: "/applicationareacommercialfreedom/icons/Freedom05.png",
    },
  ];

  const [itemActive, setItemActive] = useState(0);

  const nextSlide = () => {
    setItemActive((prevItemActive) => (prevItemActive + 1) % itemsData.length);
  };

  const previousSlide = () => {
    setItemActive((prevItemActive) =>
      prevItemActive === 0 ? itemsData.length - 1 : prevItemActive - 1
    );
  };

  useEffect(() => {
        const interval = setInterval(nextSlide, 4000);
        return () => clearInterval(interval); // Cleanup on unmount
      }, []);

  // Function to handle thumbnail clicks
  const handleThumbnailClick = (index) => {
    setItemActive(index);
  };

  return (
    <>
      <CommercialHeader />

      <div
        id="carouselExampleIndicators"
        className="carousel slide res_third_section"
        style={{ width: "100%", overflow: "hidden" }}
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div
          className="carousel-inner"
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          <div
            className="carousel-item active"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/FreedeomResidental/homefreedombanner1.png"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              objectFit: "cover",
            }}
          >
            <img
              src="Images/FreedeomResidental/hero banner 2.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              objectFit: "cover",
            }}
          >
            <img
              src="Images/FreedeomResidental/hero banner 3.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <section className="commercial_freedom_second_section">
        <div className="enrich_sec_sec_heading">
          <h1>Features</h1>
        </div>
        <section className="commercial_enrich_first_section">
          <div
            id="carouselExampleFade"
            className="carousel slide carousel-fade"
          >
            <div class="carousel-indicators control_buttons">
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              >
                <Shield width="65" height="65" className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              >
                <WifiSecond width="65" height="65" className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              >
                <BatterySecond width="65" height="65" className="icon" />
              </button>
            </div>
            <div className="carousel-inner">
              <div
                className="carousel-item active"
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src="Images/commercailFreedom/smart and safe.jpg"
                  className="d-block w-100"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Smart & Safe</h1>

                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>Web Based Monitoring for Remote Support</li>
                    <li>
                      Intelligent Energy Management System (EMS) ensures battery
                      optimization{" "}
                    </li>
                    <li>Inbuilt protection devices for safe operation</li>
                    <li> Maintenance free VRLA Battery.</li>
                    <li>Safe for environment.</li>
                  </ul>
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/commercailFreedom/compact nd modular.jpg"
                  className="d-block w-100"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Compact & Modular</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>
                      {" "}
                      Connect up to 90kW in parallel for easy scalability{" "}
                    </li>
                    <li> Small Foot print. Plug n play operation </li>
                    <li>Pre assembled, self contained System</li>
                  </ul>
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/commercailFreedom/absolute powerpack.jpg"
                  className="d-block w-100"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Absolute Powerpack</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li> Seamless switching between Grid power to Inverter </li>
                    <li>
                      {" "}
                      Support charging via Solar, Grid & other sources like
                      Diesel Generator{" "}
                    </li>
                    <li>Uninterrupted 24X7 reliable power</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <ApplicationArea itemsData={itemsData} PageTitle="Freedom Series" />


      {/* <section className="application_area_wrapper">
      <div className="heading_container">
        <h3>Freedom Series</h3>
        <h1>Application Areas</h1>
      </div>
      <div className="Application_Area">
        <div className="slider">
          <div className="list">
            {itemsData.map((item, index) => (
              <div
                key={index}
                className={`item ${index === itemActive ? "active" : ""}`}
              >
                <img src={item.src} alt={`Slider ${index + 1}`} />
                <div className="content">
                  <h2>
                    {item.title}
                    <br />
                    {item.title1}
                  </h2>
                </div>
              </div>
            ))}
          </div>
          <div className="thumbnail">
            {itemsData.map((item, index) => (
              <div
                key={index}
                className={`item ${index === itemActive ? "active" : ""}`}
                onClick={() => handleThumbnailClick(index)} // Add click event handler
              >
                <img src={item.src} alt={`Slider ${index + 1}`} />
                <div className="content">
                  <img src={item.icon} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section> */}




      <ProductCoomerCialFreedom
        product={product}
        changeProduct1={ChangeProduct1}
        changeProduct2={ChangeProduct2}
        checked={checked}
        ProductButtons={ProductButtons}
        pdfurl={pdfFile}
      />

      <section className="commercial_freedom_fourth_section_wrapper">
        <div className="product_fourth_section">
          {/* <div className="featured_products">
            <h1>Product Range</h1>
            <div className="product_list">
              <div className="product">
                <div className="name">
                  <p>FH 15</p>
                </div>
                <div className="info">
                  <img
                    src="Images/Residential/SetupSeries/freedompr1.png"
                    alt=""
                  />
                  <button>
                    <a href="/">Know More</a>
                  </button>
                </div>
              </div>
              <div className="product">
                <div className="name">
                  <p>FH 60</p>
                </div>
                <div className="info">
                  <img
                    src="Images/Residential/SetupSeries/freedompr2.png"
                    alt=""
                  />
                  <button>
                    <a href="/">Know More</a>
                  </button>
                </div>
              </div>
            </div>
            <div className="dwnld_btn">
              <button>
                <a href="/">Download Catalog</a>
              </button>
            </div>
          </div>
          <div className="detailed_product_info_wrapper">
            <div className="detailed_product_info">
              <div className="product_image">
                <img
                  src="Images/Residential/SetupSeries/freedompr1.png"
                  alt=""
                />
              </div>
              <div className="product_info">
                <div className="product_chng_button_wrapper">
                  <button>
                    <a href="/">FH 15</a>
                  </button>
                  <button>
                    <a href="/">FH 60</a>
                  </button>
                </div>
                <div className="all_information">
                  <h1>Product Info</h1>
                  <p>
                    Our aim is to empower residents with affordable solar
                    solutions tailored to meet their energy needs.
                    <br /> With our expertise and dedication, we're here to
                    transform the way you power your home
                  </p>
                  <div className="cta_btn_wrapper">
                    <button className="cta">
                      <a href="/">Buy Now</a>
                    </button>
                    <button className="cta">
                      <a href="/">Download</a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="Partner_with_us">
            <div className="partner_image" data-aos="zoom-in">
              <img
                src="Images/commercialHome/Partner with us.jpeg"
                alt="Area Map"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="partner_contents">
              <h1>Partner with us now! </h1>
              <p>Join Our Network Of Dealers And Distributors</p>
              <button>
                <Link to="/">Join Now</Link>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ComFreedomSeries;
