import React, { useEffect } from "react";
import "./thirdSection.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min.js";

const ThirdSection = () => {
  useEffect(() => {
    const carousel = new window.bootstrap.Carousel(
      document.getElementById("carouselExampleIndicators"),
      {
        interval: 5000,
        wrap: true,
      }
    );

    return () => {
      carousel.dispose(); // Cleanup the carousel on component unmount
    };
  }, []);
  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide res_third_section"
        style={{ width: "100%", overflow: "hidden" }}
        data-bs-ride="carousel"
        // data-bs-interval="5000"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div
          className="carousel-inner"
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          <div
            className="carousel-item active"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/newhomeassets/01.png"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/newhomeassets/02.png"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/newhomeassets/03.png"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdSection;
