import React from "react";
import "./commercialThirdSection.scss";

const CommercialThirdSection = () => {
  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide commercial_third_section"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner" 
          style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          <div
            className="carousel-item active"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img src="Images/newhomeassets/01.png" className="" alt="..." />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img src="Images/newhomeassets/02.png" className="" alt="..." />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              style={{ height: "100%" }}
              src="Images/newhomeassets/03.png"
              className="d-block w-100"
              alt="..."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommercialThirdSection;
