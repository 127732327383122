import React from "react";

const Wifi = ({width,height}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.1528 34.8909C31.1528 33.6518 30.1253 32.6242 28.8862 32.6242C27.6471 32.6242 26.6196 33.6518 26.6196 34.8909C26.6196 36.1299 27.6471 37.1575 28.8862 37.1575C30.1253 37.1575 31.1528 36.1299 31.1528 34.8909Z"
        fill="#19B01F"
      />
      <path
        d="M34.7492 28.9976C31.5155 25.7639 26.2267 25.7639 22.993 28.9976L25.1387 31.1434C27.1938 29.0883 30.5484 29.0883 32.6337 31.1434L34.7492 28.9976Z"
        fill="#19B01F"
      />
      <path
        d="M38.4967 25.2502C35.9278 22.6813 32.5128 21.2911 28.8862 21.2911C25.2596 21.2911 21.8446 22.7116 19.2757 25.2804L21.4215 27.4261C25.5316 23.316 32.271 23.316 36.3812 27.4261L38.4967 25.2502Z"
        fill="#19B01F"
      />
      <path
        d="M40.6424 23.1347L42.7881 20.9889C35.1118 13.3126 22.6605 13.3126 14.9842 20.9889L17.13 23.1347C23.6276 16.637 34.1447 16.637 40.6424 23.1347Z"
        fill="#19B01F"
      />
    </svg>
  );
};

export default Wifi;
