import React from "react";

export const Punch = ({ width, height ,selectedIcon}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2228 24.5365H31.7658C31.6535 25.0679 31.1858 25.4532 30.6382 25.4505L27.5491 25.4369L26.6189 25.4194C26.331 25.414 26.1174 25.1544 26.1647 24.8705L26.2215 24.5365H26.2228ZM18.7777 29.1575V28.3355C18.7777 27.9989 19.0494 27.7271 19.3847 27.7271H21.6695V24.5365H25.5117L25.4752 24.7529C25.3549 25.4572 25.8902 26.1048 26.6054 26.1183L27.5382 26.1359L30.6342 26.1494C31.5386 26.1535 32.3106 25.5005 32.4634 24.6096V24.6014L32.4742 24.5338H36.2853V27.7244H38.3835C38.7188 27.7244 38.9919 27.9962 38.9919 28.3328V29.1548H18.7777V29.1575ZM18.7777 30.1741V29.486H38.9932V30.1741H18.7777ZM18.7777 31.1908V30.5026H38.9932V31.1908H18.7777ZM18.7777 32.2074V31.5193H38.9932V32.2074H18.7777ZM18.7777 33.2241V32.536H38.9932V33.2241H18.7777ZM18.7777 34.2218V33.5526H38.9932V34.2218H18.7777ZM18.7777 35.2047V34.5693H38.9932V35.2047H18.7777ZM18.7777 35.2574V35.2344H38.9932V35.2574H18.7777ZM18.7777 36.1875V35.5859H38.9932V36.1875H18.7777ZM18.7777 36.2741V36.2173H38.9932V36.2741H18.7777ZM38.3848 38.7089H19.3861C19.0494 38.7089 18.779 38.4372 18.779 38.0992V36.6026H38.9946V38.0992C38.9946 38.4372 38.7228 38.7089 38.3862 38.7089H38.3848Z"
        fill="#19B01F"
        // fill={selectedIcon === 3 ? "#19B01F" : "white"}

      />
      <path
        d="M32.4616 21.4081H32.44C32.4414 21.4947 32.4346 21.5825 32.4197 21.6704L32.029 24.5365C31.9128 25.2152 31.326 25.7127 30.6365 25.7087L27.5474 25.6951L26.6145 25.6776C26.1697 25.6681 25.8358 25.2666 25.9115 24.8272L26.1684 23.3184L25.4748 23.8119C24.9611 24.1729 24.25 24.0512 23.8863 23.5402C23.7552 23.3563 23.7038 23.1278 23.7417 22.9061C23.7795 22.6844 23.9052 22.4856 24.0891 22.3532L26.9795 20.305C27.1715 20.1684 27.3932 20.0846 27.6231 20.0562L28.0124 12.607L30.3391 12.5989L32.7645 13.1546L32.463 21.4068L32.4616 21.4081ZM32.6158 21.5853C32.6117 21.6231 32.6077 21.661 32.6009 21.6988L32.2102 24.5649V24.5676C32.0791 25.3342 31.4139 25.8966 30.6365 25.8925L27.5447 25.879L26.6118 25.8614C26.0548 25.8506 25.6371 25.3463 25.7317 24.7975L25.9142 23.7267L25.5816 23.9633C24.9868 24.3865 24.1594 24.2418 23.7376 23.6483C23.5781 23.4252 23.5145 23.1467 23.5618 22.8764C23.6078 22.606 23.7592 22.364 23.9837 22.2058L26.8741 20.1576C27.0471 20.0346 27.2432 19.9494 27.4487 19.9034L27.838 12.4245L30.3594 12.4164L32.9524 13.0099L32.6388 21.5839H32.6158V21.5853Z"
        fill="#19B01F"
        // fill={selectedIcon === 3 ? "#19B01F" : "white"}

      />
    </svg>
  );
};

 
// export const PunchWhite = ({ width, height ,selectedIcon}) => {
//   return (
//     <svg
//       width={width}
//       height={height}
//       viewBox="0 0 57 51"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M26.2228 24.5365H31.7658C31.6535 25.0679 31.1858 25.4532 30.6382 25.4505L27.5491 25.4369L26.6189 25.4194C26.331 25.414 26.1174 25.1544 26.1647 24.8705L26.2215 24.5365H26.2228ZM18.7777 29.1575V28.3355C18.7777 27.9989 19.0494 27.7271 19.3847 27.7271H21.6695V24.5365H25.5117L25.4752 24.7529C25.3549 25.4572 25.8902 26.1048 26.6054 26.1183L27.5382 26.1359L30.6342 26.1494C31.5386 26.1535 32.3106 25.5005 32.4634 24.6096V24.6014L32.4742 24.5338H36.2853V27.7244H38.3835C38.7188 27.7244 38.9919 27.9962 38.9919 28.3328V29.1548H18.7777V29.1575ZM18.7777 30.1741V29.486H38.9932V30.1741H18.7777ZM18.7777 31.1908V30.5026H38.9932V31.1908H18.7777ZM18.7777 32.2074V31.5193H38.9932V32.2074H18.7777ZM18.7777 33.2241V32.536H38.9932V33.2241H18.7777ZM18.7777 34.2218V33.5526H38.9932V34.2218H18.7777ZM18.7777 35.2047V34.5693H38.9932V35.2047H18.7777ZM18.7777 35.2574V35.2344H38.9932V35.2574H18.7777ZM18.7777 36.1875V35.5859H38.9932V36.1875H18.7777ZM18.7777 36.2741V36.2173H38.9932V36.2741H18.7777ZM38.3848 38.7089H19.3861C19.0494 38.7089 18.779 38.4372 18.779 38.0992V36.6026H38.9946V38.0992C38.9946 38.4372 38.7228 38.7089 38.3862 38.7089H38.3848Z"
//         fill="#19B01F"


//       />
//       <path
//         d="M32.4616 21.4081H32.44C32.4414 21.4947 32.4346 21.5825 32.4197 21.6704L32.029 24.5365C31.9128 25.2152 31.326 25.7127 30.6365 25.7087L27.5474 25.6951L26.6145 25.6776C26.1697 25.6681 25.8358 25.2666 25.9115 24.8272L26.1684 23.3184L25.4748 23.8119C24.9611 24.1729 24.25 24.0512 23.8863 23.5402C23.7552 23.3563 23.7038 23.1278 23.7417 22.9061C23.7795 22.6844 23.9052 22.4856 24.0891 22.3532L26.9795 20.305C27.1715 20.1684 27.3932 20.0846 27.6231 20.0562L28.0124 12.607L30.3391 12.5989L32.7645 13.1546L32.463 21.4068L32.4616 21.4081ZM32.6158 21.5853C32.6117 21.6231 32.6077 21.661 32.6009 21.6988L32.2102 24.5649V24.5676C32.0791 25.3342 31.4139 25.8966 30.6365 25.8925L27.5447 25.879L26.6118 25.8614C26.0548 25.8506 25.6371 25.3463 25.7317 24.7975L25.9142 23.7267L25.5816 23.9633C24.9868 24.3865 24.1594 24.2418 23.7376 23.6483C23.5781 23.4252 23.5145 23.1467 23.5618 22.8764C23.6078 22.606 23.7592 22.364 23.9837 22.2058L26.8741 20.1576C27.0471 20.0346 27.2432 19.9494 27.4487 19.9034L27.838 12.4245L30.3594 12.4164L32.9524 13.0099L32.6388 21.5839H32.6158V21.5853Z"
//         fill="#19B01F"


//       />
//     </svg>
//   );
// };

 
