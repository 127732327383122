import React, { useEffect, useState } from "react";
import "../../../Common Components/Application Area/applicationArea.css";
import "./comSmartMeter.scss";
import CommercialHeader from "../../../Common Components/CommercialHeader/CommercialHeader";
import Footer from "../../../Common Components/Footer/Footer";
import { Link } from "react-router-dom";
// import { GiKite } from "react-icons/gi";
// import { TbHeartRateMonitor } from "react-icons/tb";
// import { TbArrowsLeftRight } from "react-icons/tb";
// import { LuShieldCheck } from "react-icons/lu";
// import Products from "../../../Common Components/Products/Products";
import Aos from "aos";
import "aos/dist/aos.css";
// import Kite from "../../../Common Components/Icons/Kite";
// import Battery from "../../../Common Components/Icons/Battery";
import Umbrella from "../../../Common Components/Icons/Umbrella";
import IEC from "../../../Common Components/Icons/IEC";
import Comment from "../../../Common Components/Icons/Comment";
import Timer from "../../../Common Components/Icons/Timer";
import Chip from "../../../Common Components/Icons/Chip";
// import Mobile from "../../../Common Components/Icons/Mobile";
import Phone from "../../../Common Components/Icons/Phone";
import ProductCoomerCialMeter from "../../../Common Components/Products/ProductCommercialMeter";
import ApplicationArea from "../../../Common Components/Application Area/ApplicationArea";

const ComSmartMeter = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1000, offset: 200 });
  }, []);
  const [checked, setChecked] = useState({
    product1: "true",
    product2: "",
    product3: "",
  });
  const pdfFile = "5  SMART ENERGY METER.pdf";
  const Product1 = {
    id: 1,
    desc: `Skipper Green Single-Phase Smart Meter - your modern solution for precise electricity measurement. This advanced meter ensures accurate monitoring of single-phase power consumption, equipped with cutting-edge communication modules for seamless data transmission to utility providers. With a sleek design and robust functionality, the Skipper Green Smart Meter guarantees reliability and efficiency in tracking both active and reactive power usage. Upgrade to the Skipper Green Single-Phase Smart Meter for a smarter approach to managing your energy consumption.`,
    images: {
      image: "Images/coomercailSmartmetr/Smart Meter 01.png",
      // image2: "Images/Common/meterProduct.png",
      // image3: "Images/Common/meterProduct.png",
      // image4: "Images/Common/meterProduct.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product2 = {
    id: 2,
    desc: `Skipper Green Three-Phase Smart Meter is your ultimate solution for comprehensive electricity monitoring. This advanced meter is designed to accurately measure and track three-phase power consumption with precision. Equipped with state-of-the-art communication modules, the Skipper Green Smart Meter ensures seamless data transmission to utility providers, eliminating the need for manual readings. With a sleek and modern design, this meter offers reliability and efficiency in monitoring both active and reactive power usage across all three phases. Upgrade to the Skipper Green Three-Phase Smart Meter for a smarter and more efficient approach to managing your energy consumption.`,
    images: {
      image: "Images/coomercailSmartmetr/Smart Meter 02.png",
      // image2: "Images/Common/meterProduct.png",
      // image3: "Images/Common/meterProduct.png",
      // image4: "Images/Common/meterProduct.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product3 = {
    id: 3,
    desc: `Introducing the Skipper Green MD Smart Meter - your all-in-one solution for accurate electricity measurement. This advanced meter is designed to accurately measure and track both current and voltage consumption, providing comprehensive data for energy management. Equipped with state-of-the-art communication modules, the Skipper Green Smart Meter ensures seamless data transmission to utility providers, eliminating the need for manual readings. With a sleek and modern design, this meter offers reliability and efficiency in monitoring both active and reactive power usage across all current and voltage levels. Upgrade to the Skipper Green CD/MD Smart Meter for a smarter and more efficient approach to managing your energy consumption.`,
    images: {
      image: "Images/coomercailSmartmetr/Smart Meter 03.png",
      // image3: "Images/Common/meterProduct.png",
      // image4: "Images/Common/meterProduct.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };
  const [product, setProduct] = useState(Product1);

  const ChangeProduct1 = () => {
    setProduct(Product1);
    setChecked({ product1: "true" });
  };

  const ChangeProduct2 = () => {
    setProduct(Product2);
    setChecked({ product2: "true" });
  };

  const ChangeProduct3 = () => {
    setProduct(Product3);
    setChecked({ product3: "true" });
  };
  const ProductButtons = [
    {
      id: 1,
      name: "SINGLE PHASE",
      action: ChangeProduct1,
      checked: checked.product1,
    },
    {
      id: 2,
      name: "THREE PHASE",
      action: ChangeProduct2,
      checked: checked.product2,
    },
    {
      id: 3,
      name: "MD METER",
      action: ChangeProduct3,
      checked: checked.product3,
    },
  ];

  const itemsData = [
    {
      src: "/applicationsmartmeter/Smart Meter01.png",
      title: "Residential Areas",
      title1: "",
      icon: "/applicationsmartmeter/icons/Smart01.png",
    },
    {
      src: "/applicationsmartmeter/Smart Meter02.png",
      title: "Small & Medium Sized",
      title1: "Residential Areas",
      icon: "/applicationsmartmeter/icons/Smart02.png",
    },
    {
      src: "/applicationsmartmeter/Smart Meter03.png",
      title: "Industries",
      title1: "",
      icon: "/applicationsmartmeter/icons/Smart03.png",
    },
    {
      src: "/applicationsmartmeter/Smart Meter04.png",
      title: "Commercial Areas",
      title1: "",
      icon: "/applicationsmartmeter/icons/Smart04.png",
    },
    // {
    //   src: "Images/Slider/Smart/Smart05.png",
    //   title: "Power Distribution",
    //   title1: "",
    //   icon: "Images/Slider/Smart/icons/Smart05.png",
    // },
  ];


  const [itemActive, setItemActive] = useState(0);

  const nextSlide = () => {
    setItemActive((prevItemActive) => (prevItemActive + 1) % itemsData.length);
  };

  const previousSlide = () => {
    setItemActive((prevItemActive) =>
      prevItemActive === 0 ? itemsData.length - 1 : prevItemActive - 1
    );
  };

  useEffect(() => {
        const interval = setInterval(nextSlide, 4000);
        return () => clearInterval(interval); // Cleanup on unmount
      }, []);

  // Function to handle thumbnail clicks
  const handleThumbnailClick = (index) => {
    setItemActive(index);
  };




  return (
    <>
      <CommercialHeader />

      <div
        id="carouselExampleIndicators"
        className="carousel slide res_third_section"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div
          className="carousel-inner"
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          <div
            className="carousel-item active"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/coomercailSmartmetr/smart energy meter hero banner 1.jpg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/coomercailSmartmetr/Smart Energy Meter 02.jpg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/coomercailSmartmetr/Smart Energy Meter 03.jpg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <section className="commercial_smart_meter_second_section">
        <div className="enrich_sec_sec_heading">
          <h1>Features</h1>
        </div>
        <section className="commercial_enrich_first_section">
          <div
            id="carouselExampleFade"
            className="carousel slide carousel-fade"
          >
            <div class="carousel-indicators control_buttons">
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              >
                {/* <GiKite className="icon" /> */}
                <Umbrella style={{ width: "48px", height: "48px" }} />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              >
                {/* <TbHeartRateMonitor className="icon" /> */}
                <IEC />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              >
                <Comment className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              >
                <Timer className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="4"
                aria-label="Slide 5"
              >
                <Chip className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="5"
                aria-label="Slide 6"
              >
                <Phone className="icon" />
              </button>
            </div>
            <div className="carousel-inner">
              <div
                className="carousel-item active"
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src="Images/coomercailSmartmetr/Smart Meter Features Commerical.jpeg"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>IP54 Rated</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>Ingress Protection</li>
                    <li> IP54 (Out Door) </li>
                  </ul>
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/coomercailSmartmetr/Compliant to.jpg"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Compliant to</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>IEC 62052-11</li>
                    <li> IEC 62053-23 </li>
                    <li> IEC 62056-21 </li>
                  </ul>
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/coomercailSmartmetr/Multi-communication channels.jpg"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Multi-communication channels</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>
                      Communication Support Optical port/ RS485 for Local &
                      2G/3G/4G/ for Remote
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/coomercailSmartmetr/LCD Display reflects.jpg"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>LCD Display reflects</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>Vrms </li>
                    <li>Irms </li>
                    <li>Power </li>
                    <li>Power factor </li>
                    <li>Frequency </li>
                  </ul>
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/coomercailSmartmetr/Tamper Detection.jpg"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Tamper Detection</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>Tamper Detection Meter Cover </li>
                    <li>Terminal Cove </li>
                    <li>Module Cover Status </li>
                    <li>Current Reverse </li>
                  </ul>
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/coomercailSmartmetr/Prepaid Recharging.jpg"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Prepaid Recharging</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>
                      Optional Consumer Interface Unit (CIV) Available for
                      Recharging (Prepaid)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <ApplicationArea itemsData={itemsData} PageTitle="Smart Meter" />

      {/* <section className="application_area_wrapper">
      <div className="heading_container">
        <h3>Smart Meter</h3>
        <h1>Application Areas</h1>
      </div>
      <div className="Application_Area">
        <div className="slider">
          <div className="list">
            {itemsData.map((item, index) => (
              <div
                key={index}
                className={`item ${index === itemActive ? "active" : ""}`}
              >
                <img src={item.src} alt={`Slider ${index + 1}`} />
                <div className="content">
                  <h2>
                    {item.title}
                    <br />
                    {item.title1}
                  </h2>
                </div>
              </div>
            ))}
          </div>
          <div className="thumbnail">
            {itemsData.map((item, index) => (
              <div
                key={index}
                className={`item ${index === itemActive ? "active" : ""}`}
                onClick={() => handleThumbnailClick(index)} // Add click event handler
              >
                <img src={item.src} alt={`Slider ${index + 1}`} />
                <div className="content">
                  <img src={item.icon} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section> */}

      <ProductCoomerCialMeter
        product={product}
        changeProduct1={ChangeProduct1}
        changeProduct2={ChangeProduct2}
        checked={checked}
        ProductButtons={ProductButtons}
        pdfurl={pdfFile}
      />

      <section className="commercial_smart_meter_fourth_section_wrapper">
        <div className="product_fourth_section">
          {/* <div className="featured_products">
            <h1>Product Range</h1>
            <div className="product_list">
              <div className="product">
                <div className="name">
                  <p>Single Phase</p>
                </div>
                <div className="info">
                  <img
                    src="Images/Residential/SetupSeries/smartpr.png"
                    alt=""
                  />
                  <button>
                    <a href="/">Know More</a>
                  </button>
                </div>
              </div>
              <div className="product">
                <div className="name">
                  <p>Three Phase</p>
                </div>
                <div className="info">
                  <img
                    src="Images/Residential/SetupSeries/smartpr.png"
                    alt=""
                  />
                  <button>
                    <a href="/">Know More</a>
                  </button>
                </div>
              </div>
              <div className="product">
                <div className="name">
                  <p>ct / md meter</p>
                </div>
                <div className="info">
                  <img
                    src="Images/Residential/SetupSeries/smartpr.png"
                    alt=""
                  />
                  <button>
                    <a href="/">Know More</a>
                  </button>
                </div>
              </div>
            </div>
            <div className="dwnld_btn">
              <button>
                <a href="/">Download pdf</a>
              </button>
            </div>
          </div>
          <div className="detailed_product_info_wrapper">
            <div className="detailed_product_info">
              <div className="product_image">
                <img src="Images/Residential/SetupSeries/smartpr.png" alt="" />
              </div>
              <div className="product_info">
                <div className="product_chng_button_wrapper">
                  <button>
                    <a href="/">FH 15</a>
                  </button>
                  <button>
                    <a href="/">FH 60</a>
                  </button>
                </div>
                <div className="all_information">
                  <h1>Product Info</h1>
                  <p>
                    Our aim is to empower residents with affordable solar
                    solutions tailored to meet their energy needs.
                    <br /> With our expertise and dedication, we're here to
                    transform the way you power your home
                  </p>
                  <div className="cta_btn_wrapper">
                    <button className="cta">
                      <a href="/">Buy Now</a>
                    </button>
                    <button className="cta">
                      <a href="/">Download</a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="Partner_with_us">
            <div className="partner_image" data-aos="zoom-in">
              <img
                src="Images/commercialHome/Partner with us.jpeg"
                alt="Area Map"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="partner_contents">
              <h1>Partner with us now! </h1>
              <p>Join Our Network Of Dealers And Distributors</p>
              <button>
                <Link to="/">Join Now</Link>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ComSmartMeter;
