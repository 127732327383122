import React from "react";

const BatterySecond = ({width,height}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.3363 14.0013C35.3342 14.0013 35.332 14.0013 35.3304 14.0013H33.1863V12.9262C33.1863 12.6414 33.0729 12.3678 32.8713 12.1662C32.6697 11.9641 32.3961 11.8512 32.1112 11.8512H25.6659C25.6643 11.8512 25.6627 11.8512 25.6611 11.8512C25.0677 11.8512 24.586 12.3328 24.586 12.9262V14.0013H22.436C21.8426 14.0013 21.361 14.4829 21.361 15.0763V38.7269C21.361 39.3203 21.8426 39.802 22.436 39.802H35.3363C35.9298 39.802 36.4114 39.3203 36.4114 38.7269V15.0763C36.4114 14.4829 35.9298 14.0013 35.3363 14.0013ZM34.2613 37.6519H23.511V16.1513H25.6611C26.2545 16.1513 26.7361 15.6697 26.7361 15.0763V14.0013H31.0362V15.0763C31.0362 15.6697 31.5178 16.1513 32.1112 16.1513H34.2613V37.6519Z"
        // fill="#19B01F"
      />
      <path
        d="M29.9613 24.7516H29.3028L29.9064 22.9412C30.0946 22.3779 29.7898 21.7694 29.2265 21.5813C28.6658 21.3942 28.0542 21.6979 27.8666 22.2613L26.7915 25.4863C26.6824 25.8142 26.7372 26.1744 26.9394 26.4544C27.1409 26.7355 27.4656 26.9016 27.8112 26.9016H28.4697L27.866 28.7114C27.6784 29.2747 27.9827 29.8837 28.546 30.0713C28.6583 30.109 28.7733 30.1272 28.8862 30.1272C29.3361 30.1272 29.7559 29.8424 29.9059 29.3919L30.9809 26.1668C31.09 25.839 31.0352 25.4788 30.8331 25.1982C30.631 24.9177 30.3069 24.7516 29.9613 24.7516Z"
        // fill="#19B01F"
      />
    </svg>
  );
};


export default BatterySecond