import React, { useEffect } from "react";
import "./faqs.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <section className="faqs">
        <div className="top_banner">
          <img src="Images/faqbanner.png" alt="" />
          <div className="overlay">
            <h1>FAQ</h1>
            <p>Frequently Asked Questions</p>
          </div>
        </div>
        <div className="main_image_wrapper">
          <img src="Images/faqmainimg.png" alt="" />
        </div>
        <div className="questions_wrapper">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  // style={{backgroundColor: 'green'}}
                >
                  What is your company all about?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  We are a renewable energy company that provides solar
                  solutions for residential and commercial clients. We offer a
                  wide range of products and services, including solar panel
                  installation, maintenance, and financing options.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  How do I know if solar panels are right for me?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Solar panels can be a great investment for anyone looking to
                  reduce their energy bills and their carbon footprint. Our team
                  can provide a consultation to help you determine if solar
                  panels are a good fit for your home or business.3. What can I
                  power with a Green generator?
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How long does it take to install solar panels?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The time it takes to install solar panels can vary depending
                  on the size of the installation and other factors. Our team
                  will provide a timeline estimate based on your specific needs.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  How much do solar panels cost?
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The cost of solar panels can vary depending on the size of the
                  installation and other factors. Our team can provide a quote
                  based on your specific needs and budget.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  What is a Green generator?
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A Green generator is a device that uses solar panels to
                  capture sunlight and convert it into electrical energy, which
                  is stored in a battery. It can be used to power various
                  devices and appliances, both indoors and outdoors.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How does a Green generator work?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A Green generator works by using solar panels to capture
                  sunlight, which is then converted into electrical energy. This
                  energy is stored in a battery, which can be used to power
                  various devices and appliances.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  What can I power with a Green generator?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The devices and appliances that can be powered by a Green
                  generator depend on the generator's wattage and capacity. Some
                  common examples include smartphones, laptops, lights, fans,
                  small appliances, and power tools.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How long can a Green generator last?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The lifespan of a Green generator depends on various factors,
                  such as the quality of the components, the frequency of use,
                  and the maintenance. Generally, a well-maintained Green
                  generator can last for several years.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Do I need to maintain my Green generator?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, regular maintenance is important to keep your Green
                  generator running smoothly and extend its lifespan. This may
                  include cleaning the solar panels, checking the battery
                  levels, and replacing any damaged components.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  Can I use a Green generator to power my home?
                </button>
              </h2>
              <div
                id="collapseTen"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  It depends on the size and capacity of the Green generator.
                  Small Green generators may be able to power some lights and
                  appliances, but larger generators are typically needed to
                  power an entire home.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                >
                  Can I charge my Green generator using a wall outlet?
                </button>
              </h2>
              <div
                id="collapseEleven"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Solar panels are used to collect sunlight, turn it into
                  electricity, and then store the electricity in a battery bank
                  for later use. The user can use the DC bulbs, mobile charging
                  cable, and fan (available with 50W SHS) as needed.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwelve"
                  aria-expanded="false"
                  aria-controls="collapseTwelve"
                >
                  Can I use my Green generator during a power outage?
                </button>
              </h2>
              <div
                id="collapseTwelve"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, a Green generator can be a useful backup power source
                  during a power outage
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Faqs;
