import React, { useState } from "react";
import "./../Header/header.scss";
import { FaPhoneAlt } from "react-icons/fa";
import { CgMenuGridO } from "react-icons/cg";
import { IoIosArrowDropright } from "react-icons/io";
import {
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaXTwitter,
} from "react-icons/fa6";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const ResidentialNavigation = [
    {
      id: "1",
      name: "Step-Up Series",
      link: "/residential_setup_series",
    },
    {
      id: "2",
      name: "Enrich Series",
      link: "/residential_enrich_series",
    },
    {
      id: "3",
      name: "Freedom Series",
      link: "/residential_freedom_series",
    },
  ];

  const [activeResidentialStyle, setActiveResidentialStyle] = useState({
    textDecoration: "none",
    color: "#000",
  });
  const [activeCommercialStyle, setActiveCommercialStyle] = useState({
    textDecoration: "underline",
    color: "#19B01F",
  });

  const [activeSideResidentialStyle, setActiveSideResidentialStyle] = useState({
    textDecoration: "none",
    color: "#fff",
  });
  const [activeSideCommercialStyle, setActiveSideCommercialStyle] = useState({
    textDecoration: "underline",
    color: "#fff",
  });

  const CommercialNavigation = [
    {
      id: "1",
      name: "Enrich Series",
      link: "/commercial_enrich_series",
    },
    {
      id: "2",
      name: "Freedom Series",
      link: "/commercial_freedom_series",
    },
    {
      id: "3",
      name: "Envault Series",
      link: "/commercial_envault_series",
    },
    {
      id: "4",
      name: "Smart Meter",
      link: "/commercial_smart_meter",
    },
  ];

  const [navigation, setNavigation] = useState(CommercialNavigation);
  const [navigationList, setNavigationList] = useState(CommercialNavigation);

  const [sidebarStyle, setSidebarStyle] = useState({
    right: "-100%",
  });

  const handleResidentialClick = () => {
    navigate("/");
    setNavigation(ResidentialNavigation);
    // props.setActiveTab("Residential");
    setActiveResidentialStyle({
      textDecoration: "underline",
      color: "#19B01F",
    });
    setActiveCommercialStyle({
      textDecoration: "none",
      color: "#000",
    });
  };

  const handleCommercialClick = () => {
    setNavigation(CommercialNavigation);
    // props.setActiveTab("Commercial");
    setActiveCommercialStyle({
      textDecoration: "underline",
      color: "#19B01F",
    });
    setActiveResidentialStyle({
      textDecoration: "none",
      color: "#000",
    });
    navigate("/commercial");
  };

  const handleResidentialListClick = () => {
    navigate("/");
    setNavigationList(ResidentialNavigation);
    setActiveSideResidentialStyle({
      textDecoration: "underline",
      color: "#fff",
    });
    setActiveSideCommercialStyle({
      textDecoration: "none",
      color: "#fff",
    });
  };

  const handleCommercialListClick = () => {
    navigate("/commercial");
    setNavigationList(CommercialNavigation);
    // props.setActiveTab("Commercial");
    setActiveSideCommercialStyle({
      textDecoration: "underline",
      color: "#fff",
    });
    setActiveSideResidentialStyle({
      textDecoration: "none",
      color: "#fff",
    });
  };

  const ShowSidebar = () => {
    if (sidebarStyle.right === "-100%") {
      setSidebarStyle({
        right: "0%",
      });
    } else {
      setSidebarStyle({
        right: "-100%",
      });
    }
  };

  return (
    <>
      <section className="Header">
        <div className="top_header_div">
          <p>
            <span>
              <FaPhoneAlt />
            </span>
            <span>Toll Free &nbsp; 08-0000-838383</span>
          </p>
        </div>
        <div className="bottom_header_div">
          <div className="left_part_navigation">
            <div className="logo_container">
              <Link to="/">
                <img
                  src="Images/header logo.png"
                  style={{ width: "88px", height: "62px" }}
                  alt=""
                />
              </Link>
            </div>
            <ul className="navigation_links">
              {navigation.map((nav) => (
                <li key={nav.id}>
                  <NavLink activeClassName="active" to={nav.link}>
                    {nav.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="right_part_flows">
            <ul className="flow_changer">
              <li
                style={activeResidentialStyle}
                onClick={handleResidentialClick}
              >
                Residential
              </li>
              <li>|</li>
              <li style={activeCommercialStyle} onClick={handleCommercialClick}>
                Commercial
              </li>
            </ul>
            <div className="menu_button">
              <CgMenuGridO className="menu_button_icon" onClick={ShowSidebar} />
            </div>
          </div>
        </div>
      </section>

      <section className="side_navbar" style={sidebarStyle}>
        <div className="sidebar_close_button">
          <IoIosArrowDropright
            onClick={ShowSidebar}
            className="sidebar_close_button_icon"
          />
        </div>
        <ul className="sidebar_nav_links">
          <li>
            <a href="/about_us">About Us</a>
          </li>
          <li>
            <a href="/contact_us">Contact Us</a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSe9lDPXE8vVwQ4E_Vyc3Yi6IvGTPaAX2pnDU0O4TBrLfoFHxg/viewform?pli=1"
            >
              Join Us
            </a>
          </li>
          <li>
            <a href="/blogs">Blog</a>
          </li>
          {/* <li>
            <a href="/case_studies">Case Studies</a>
          </li>
          <li>
            <a href="/news_&_blogs">News & Events</a>
          </li> */}
          {/* <li>
            <a href="/faqs">FAQs</a>
          </li> */}
        </ul>

        <ul className="social_media_handles">
          <li>
            <a
              target="_blank"
              href="https://www.facebook.com/people/Skipper-Green/61555956117815/"
            >
              <FaFacebook />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/skipper_green/?hl=en"
            >
              <FaInstagram />
            </a>
          </li>
          <li>
            <a target="_blank" href="https://twitter.com/SkipperGreen_ng">
              <FaXTwitter />
            </a>
          </li>
        </ul>
      </section>

      <section className="mobile_side_navbar" style={sidebarStyle}>
        <div className="sidebar_close_button">
          <IoIosArrowDropright
            onClick={ShowSidebar}
            className="sidebar_close_button_icon"
          />
        </div>

        <div className="right_part_flows">
          <ul className="flow_changer">
            <li
              style={activeSideResidentialStyle}
              onClick={handleResidentialListClick}
            >
              Residential
            </li>
            <li>|</li>
            <li
              style={activeSideCommercialStyle}
              onClick={handleCommercialListClick}
            >
              Commercial
            </li>
          </ul>
        </div>

        <ul className="sidebar_nav_links">
          {navigationList.map((nav) => (
            <li key={nav.id}>
              <NavLink activeClassName="active" to={nav.link}>
                {nav.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <ul className="sidebar_nav_links">
          <li>
            <a href="/about_us">About Us</a>
          </li>
          <li>
            <a href="/contact_us">Contact Us</a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSe9lDPXE8vVwQ4E_Vyc3Yi6IvGTPaAX2pnDU0O4TBrLfoFHxg/viewform?pli=1"
            >
              Join Us
            </a>
          </li>
          <li>
            <a href="/blog">Blog</a>
          </li>
          {/* <li>
            <a href="/case_studies">Case Studies</a>
          </li>
          <li>
            <a href="/news_&_blogs">News & Events</a>
          </li> */}
          {/* <li>
            <a href="/faqs">FAQs</a>
          </li> */}
        </ul>

        <ul className="social_media_handles">
          <li>
            <a
              target="_blank"
              href="https://www.facebook.com/people/Skipper-Green/61555956117815/"
            >
              <FaFacebook />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/skipper_green/?hl=en"
            >
              <FaInstagram />
            </a>
          </li>
          <li>
            <a target="_blank" href="https://twitter.com/SkipperGreen_ng">
              <FaXTwitter />
            </a>
          </li>
        </ul>
      </section>
    </>
  );
};

export default Header;
