import React, { useEffect, useState } from "react";
import "./applicationArea.css";

const ApplicationArea = ({ itemsData, PageTitle }) => {
  const [itemActive, setItemActive] = useState(0);

  const nextSlide = () => {
    setItemActive((prevItemActive) => (prevItemActive + 1) % itemsData.length);
  };

  const previousSlide = () => {
    setItemActive((prevItemActive) =>
      prevItemActive === 0 ? itemsData.length - 1 : prevItemActive - 1
    );
  };

  useEffect(() => {
        const interval = setInterval(nextSlide, 4000);
        return () => clearInterval(interval); // Cleanup on unmount
      }, []);

  // Function to handle thumbnail clicks
  const handleThumbnailClick = (index) => {
    setItemActive(index);
  };

  return (
    <section className="application_area_wrapper">
      <div className="heading_container">
        <h3>{PageTitle}</h3>
        <h1>Application Areas</h1>
      </div>
      <div className="Application_Area">
        <div className="slider">
          <div className="list">
            {itemsData.map((item, index) => (
              <div
                key={index}
                className={`item ${index === itemActive ? "active" : ""}`}
              >
                <img src={item.src} alt={`Slider ${index + 1}`} />
                <div className="content">
                  <h2>
                    {item.title}
                    <br />
                    {item.title1}
                  </h2>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="arrows">
            <button id="prev" onClick={previousSlide}>
              &lt;
            </button>
            <button id="next" onClick={nextSlide}>
              &gt;
            </button>
          </div> */}
          <div className="thumbnail">
            {itemsData.map((item, index) => (
              <div
                key={index}
                className={`item ${index === itemActive ? "active" : ""}`}
                onClick={() => handleThumbnailClick(index)} // Add click event handler
              >
                <img src={item.src} alt={`Slider ${index + 1}`} />
                <div className="content">
                  <img src={item.icon} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplicationArea;
