import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CommercialHome from "./Commercial/Pages/Home/CommercialHome";
import Home from "./FullLayOut/Home";
import ResSetUpSeries from "./Residential/Pages/ResSetUpSeries/ResSetUpSeries";
import ResEnrichSeries from "./Residential/Pages/ResEnrichSeries/ResEnrichSeries";
import ResFreedomSeries from "./Residential/Pages/ResFreedomSeries/ResFreedomSeries";
import ResidentialHome from "./Residential/Pages/Home/ResidentialHome";
import ComEnrichSeries from "./Commercial/Pages/ComEnrichSeries/ComEnrichSeries";
import ComFreedomSeries from "./Commercial/Pages/ComFreedomSeries/ComFreedomSeries";
import ComEnvaultSeries from "./Commercial/Pages/ComEnvaultSeries/ComEnvaultSeries";
import ComSmartMeter from "./Commercial/Pages/ComSmartMeter/ComSmartMeter";
import NearByDealers from "./Residential/Pages/NearByDealers/NearByDealers";
import ContactDealer from "./Residential/Pages/ContactDealer/ContactDealer";
import AboutUs from "./Common Components/About Us/AboutUs";
import ContactUs from "./Common Components/Contact Us/ContactUs";
import Blogs from "./Common Components/Blogs/Blogs";
import OneBlog from "./Common Components/Blogs/OneBlog";
import Faqs from "./Common Components/Faqs/Faqs";
import About2 from "./Common Components/About Us/About2";
import CaseStudies from "./Common Components/Case Studies/CaseStudies";
import { FaWhatsapp } from "react-icons/fa";
import NewsAndEvents from "./Common Components/Blogs/NewsAndEvents";
import SingleCaseStudies from "./Common Components/Case Studies/SingleCaseStudies";
import BlogsByTags from "./Common Components/Blogs/BlogsByTags";
import CaseStudiesByTag from "./Common Components/Case Studies/CaseStudiesByTag";
import BlogById from "./Common Components/Blogs/BlogById";

function App() {
  const handleClick = () => {
    const phoneNumber = "+2347031269442";
    const message = encodeURIComponent(
      `Hello, *Skipper Green*! I am interested and would like to know more about Skipper Green's products and services.`
    );
    const url = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open WhatsApp in a new window with the specified phone number and message
    window.open(url, "_blank");
  };
  return (
    <>
      <BrowserRouter>
        {/* <Suspense fallback={loading}> */}
        <Routes>
          <Route path="/" element={<ResidentialHome />} />
          <Route
            path="/residential_setup_series"
            element={<ResSetUpSeries />}
          />
          <Route
            path="/residential_enrich_series"
            element={<ResEnrichSeries />}
          />
          <Route
            path="/residential_freedom_series"
            element={<ResFreedomSeries />}
          />
          <Route path="/commercial" element={<CommercialHome />} />
          <Route
            path="/commercial_enrich_series"
            element={<ComEnrichSeries />}
          />
          <Route
            path="/commercial_freedom_series"
            element={<ComFreedomSeries />}
          />
          <Route
            path="/commercial_envault_series"
            element={<ComEnvaultSeries />}
          />
          <Route path="/commercial_smart_meter" element={<ComSmartMeter />} />
          <Route path="/near_by_dealers" element={<NearByDealers />} />
          <Route path="/contact_dealer" element={<ContactDealer />} />
          <Route path="/about_us_checking" element={<AboutUs />} />
          <Route path="/about_us" element={<About2 />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/news_&_blogs" element={<NewsAndEvents />} />
          <Route path="/news_&_blogs_by_tags" element={<BlogsByTags />} />
          {/* <Route path="/blog" element={<OneBlog />} /> */}
          <Route path="/faqs" element={<Faqs />} />
          {/* <Route path="/case_studies" element={<CaseStudies />} /> */}
          <Route path="/blogs" element={<NewsAndEvents />} />
          <Route path="/blogs-by-tag/:id" element={<BlogsByTags />} />
          <Route path="/blog" element={<OneBlog />} />
          <Route path="/blog/name/:id" element={<BlogById />} />
        </Routes>
        {/* </Suspense> */}
      </BrowserRouter>
      <div
        style={{
          position: "fixed",
          bottom: "3%",
          right: "2.5%",
          color: "#fff",
          height: "62px",
          width: "62px",
          borderRadius: "50%",
          backgroundColor: "#19B01F",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "999",
        }}
        onClick={handleClick}
      >
        <FaWhatsapp style={{ width: "60%", height: "60%", zIndex: "999" }} />
      </div>
    </>
  );
}

export default App;
