import React, { useEffect, useState } from "react";
import "./resEnrichSeries.scss";

import { GrLocation } from "react-icons/gr";
import Header from "../../../Common Components/Header/Header";
import Footer from "../../../Common Components/Footer/Footer";
import SecondSection from "../ProductPageComponents/SecondSection";
import Products from "../../../Common Components/Products/Products";
import Aos from "aos";
import "aos/dist/aos.css";
import Kite from "../../../Common Components/Icons/Kite";
import { Mobile } from "../../../Common Components/Icons/Mobile";
import { Hand } from "../../../Common Components/Icons/Hand";
import Round from "../../../Common Components/Icons/Round";
import { Punch } from "../../../Common Components/Icons/Punch";
import Bulb from "../../../Common Components/Icons/Bulb";
import FullBattery from "../../../Common Components/Icons/FullBattery";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProductResEnich from "../../../Common Components/Products/ProductResEnrich";
import Monitor from "../../../Common Components/Icons/Monitor";
import DoubleArrow from "../../../Common Components/Icons/DoubleArrow";
import Shield from "../../../Common Components/Icons/Shield";
import Group from "../../../Common Components/Icons/Group";
import Safe from "../../../Common Components/Icons/Safe";
const ResEnrichSeries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1000, offset: 200 });
  }, []);
  const [checked, setChecked] = useState({
    product1: "true",
    product2: "",
  });
  const pdfFile = "2 ENRICH Series.pdf";
  

  const Product1 = {
    id: 1,
    desc1: "Hybrid Solar Inverter : 3.6KW",
    desc2: "Smart Battery : up to 10.2KWh",
    desc3: "Solar Panel : up to 4.6KWp",
    images: {
      image: "Images/EnrichResidental/EN3.png",
      image2: "Images/EnrichResidental/ResEnPr01.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product2 = {
    id: 2,
    desc1: "Hybrid Solar Inverter : 8KW",
    desc2: "Smart Battery : up to 15.3KWh",
    desc3: "Solar Panel : up to to 10.4KWp",
    images: {
      image: "Images/EnrichResidental/ResEnPr02.png",
      image2: "Images/EnrichResidental/EN8.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };
  const [product, setProduct] = useState(Product1);

  const ChangeProduct1 = () => {
    setProduct(Product1);
    setChecked({ product1: "true" });
  };

  const ChangeProduct2 = () => {
    setProduct(Product2);
    setChecked({ product2: "true" });
  };
  const ProductButtons = [
    {
      id: 1,
      name: "SG-EN3",
      action: ChangeProduct1,
      checked: checked.product1,
    },
    {
      id: 2,
      name: "SG-EN8",
      action: ChangeProduct2,
      checked: checked.product2,
    },
  ];

  const [selectedIcon, setSelectedIcon] = useState({
    title: "Simple  & Scalable",
    content: [
      "Plug n Play quick installation & maintenance",
      "Flexibility to deliver unique combinations ",
      "Across various sizes & location",
      "Connect upto 6 units to grow the system with your needs",
    ],
    image: "Images/EnrichResidental/Simple & Scalable.jpg",
    icon: <Kite />,
  });
  const [selectedIconId, setSelectedIconId] = useState(1);
  const iconData = [
    {
      id: 1,
      label: "Icon 1",
      data: "Data 1",
      icon: <Kite />,
      title: "Simple  & Scalable",
      image: "Images/EnrichResidental/Simple & Scalable.jpg",
      content: [
        "Plug n Play quick installation & maintenance",
        "Flexibility to deliver unique combinations ",
        "Across various sizes & location",
        "Connect upto 6 units to grow the system with your needs",
      ],
    },
    {
      id: 2,
      label: "Icon 2",
      data: "Data 2",
      icon: <Monitor />,
      title: "Smart",
      image: "Images/EnrichResidental/Smart.png",
      content: [
        "24x7 Real-Time Monitoring via  Mobile App ",
        "Intelligent BMS optimises charging via solar & grid",
        "Doubles as a UPS for Power Backup",
      ],
    },
    {
      id: 3,
      label: "Icon 3",
      data: "Data 3",
      icon: <DoubleArrow />,
      title: "Seamless",
      image: "Images/EnrichResidental/Seamless.png",
      content: [
        "Instant switching between Grid power to Inverter",
        "Energy storage to power all your appliances seamlessly ",
        "Enjoy energy independence with non-stop reliable power",
      ],
    },
    {
      id: 4,
      label: "Icon 4",
      data: "Data 4",
      icon: <Safe />,
      title: "Safe & Sustainable",
      image: "Images/EnrichResidental/safe & sustainable.jpg",
      content: [
        "Reduce your family’s energy footprint by 90% ",
        "Clean hybrid energy that is sustainable & environment friendly  ",
        "Long cycle LFP batteries for sustained use over a long period of time",
      ],
    },
  ];
  const handleIconClick = (icon) => {
    setSelectedIconId(icon.id);

    setSelectedIcon({
      title: icon.title,
      title2: icon.title2,
      content: icon.content,
      content1: icon.content1,
      content2: icon.content2,
      image: icon.image,
      icon: icon.icon,
    });
  };
  const handleImageArrowClick = (direction) => {
    const currentIndex = iconData.findIndex(
      (icon) => icon.id === selectedIcon.id
    );
    let newIndex;

    if (direction === "left") {
      newIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : iconData.length - 1;
    } else {
      newIndex = currentIndex + 1 < iconData.length ? currentIndex + 1 : 0;
    }

    setSelectedIcon({
      title: iconData[newIndex].title,
      title2: iconData[newIndex].title2,
      content: iconData[newIndex].content,
      content1: iconData[newIndex].content1,
      content2: iconData[newIndex].content2,
      image: iconData[newIndex].image,
      icon: iconData[newIndex].icon,
      id: iconData[newIndex].id,
    });
    setSelectedIconId(iconData[newIndex].id);
  };
  return (
    <>
      <Header />
      <div
        id="carouselExampleIndicators"
        className="carousel slide res_third_section"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div
          className="carousel-inner"
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          <div
            className="carousel-item active"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/EnrichResidental/hero banner 1.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/EnrichResidental/EnirchMainBannerResidential02.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/EnrichResidental/EnirchMainBannerResidential03.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
      <div className="residental-features-heaidng">
        <div className="residental-features-heaidng-con">
          <h1>Features</h1>
        </div>
      </div>

      <section className="residental-features">
        <div className="resitental-features-container">
          <div className="residetal-sidebar-first">
            <div className="resitentl-colmn1">
              <div className="resitentl-colmn1-icon">{selectedIcon.icon}</div>
            </div>
            <div className="resitentl-colmn2">
              <h1>{selectedIcon.title}</h1>
            </div>
            <div className="resitentl-colmn3">
              <ul>
                {selectedIcon.content.map((contentItem, index) => (
                  <li key={index}>{contentItem}</li>
                ))}
              </ul>
              {/* <p>{selectedIcon.content}</p> */}
            </div>
            <div className="resitentl-colmn4">
              {iconData.map((icon) => (
                <div
                  key={icon.id}
                  // className="icon"
                  // className={`icon ${
                  //   icon.id === selectedIcon.id ? "selected" : ""
                  // }`}
                  className={`filter-delhi ${
                    icon.id === selectedIconId ? "selected" : ""
                  }`}
                  onClick={() => handleIconClick(icon)}
                  // style={{
                  //   width: "42px",
                  //   height: "42px",
                  //   backgroundColor: "#fff",
                  //   borderRadius: "50%",
                  //   cursor: "pointer",
                  // }}
                >
                  {icon.icon}
                </div>
              ))}
            </div>
          </div>
          <div
            className="residetal-sidebar-second"
          >
            <div className="arrow-left">
              {" "}
              {/* <ArrowBackIosIcon
                onClick={() => handleImageArrowClick("left")}
                className="large-icon"
              /> */}
            </div>
            <div className="small-arrow-left">
              <ArrowBackIosIcon
                onClick={() => handleImageArrowClick("left")}
                className="large-icon-small"
              />
            </div>

            <img src={selectedIcon.image} alt="" />
            <div className="arrow-right">
              {/* <ArrowForwardIosIcon
                onClick={() => handleImageArrowClick("right")}
                className="large-icon"
              /> */}
            </div>
            <div className="small-arrow-right">
              <ArrowForwardIosIcon
                onClick={() => handleImageArrowClick("right")}
                className="large-icon-small"
              />
            </div>
          </div>
        </div>
      </section>

      <ProductResEnich
        product={product}
        changeProduct1={ChangeProduct1}
        changeProduct2={ChangeProduct2}
        checked={checked}
        ProductButtons={ProductButtons}
        pdfurl={pdfFile}
      />

      {/* <section className="enrich_third_section_wrapper">
        <div className="product_fourth_section"></div>
      </section> */}

      <Footer />
    </>
  );
};

export default ResEnrichSeries;
