import React, { useState } from "react";
import "./contactDealer.scss";
import { GoPersonFill } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { RiSendPlaneFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const ContactDealer = () => {
    const navigate = useNavigate();
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [inputData, setInputData] = useState({
    name: '',
    email: '',
    number: '',
    message: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputData((prevInputData) => ({
      ...prevInputData,
      [name]: value,
    }));
  };

  const SendMessage = () => {
    const Data = {
      product_name: selectedSeries,
      customer_name: inputData.name,
      customer_email: inputData.email,
      customer_mobilenumber: inputData.number,
      message: inputData.message,
      dealer: "dealer_id",
    }
    console.log(Data);
    navigate(-1)
  }

  const handleChange = (event) => {
    setSelectedSeries(event.target.value);
    console.log(event.target.value)
  };
  const cover_pic ="Images/cover_pic.png";
  const profile_pic =
    "Images/profile.png";
  return (
    <section className="contact_dealer_wrapper">
      <div className="contact_dealer">
        <div className="profile_pic_wrapper">
          <div className="cover_pic">
            <img src={cover_pic} alt="" />
          </div>
          <div className="profile_pic">
            <img src={profile_pic} alt="" />
          </div>
        </div>

        <div className="profile_content_wrapper">
          <div className="content_container">
            <div className="about_info">
              <div className="name">
                <span className="icon">
                  <GoPersonFill />
                </span>
                <p>Ali Abdal</p>
              </div>
              <div className="email">
                <span className="icon">
                  <MdEmail />
                </span>
                <p>thelomashraj@gmail.com</p>
              </div>
              <div className="phone">
                <span className="icon">
                  <FaPhoneAlt />
                </span>
                <p>+91 7633828121</p>
              </div>
              <div className="address">
                <span className="icon">
                  <FaLocationDot />
                </span>
                <p>
                  Mr Daniel Izuchukwu Nwoye. 8, My Street, Ilassan Lekki, Lagos
                  105102.
                </p>
              </div>
            </div>
            <div className="bottom_header">
              <h1>
                Let's enjoy nature's <span>gifts</span> together !
              </h1>
            </div>
          </div>
          <div className="form_container_wrapper">
            <div className="form_container">
              <div className="top_heading">Choose Interested Products</div>
              <div className="buttons_container">
                <input
                  type="radio"
                  name="product"
                  id="step_series"
                  value="Step Series"
                  checked={selectedSeries === "Step Series"}
                  onChange={handleChange}
                />
                <label htmlFor="step_series">Step Series</label>

                <input
                  type="radio"
                  name="product"
                  id="enrich_series"
                  value="Enrich Series"
                  checked={selectedSeries === "Enrich Series"}
                  onChange={handleChange}
                />
                <label htmlFor="enrich_series">Enrich Series</label>

                <input
                  type="radio"
                  name="product"
                  id="freedom_series"
                  value="Freedom Series"
                  checked={selectedSeries === "Freedom Series"}
                  onChange={handleChange}
                />
                <label htmlFor="freedom_series">Freedom Series</label>

                <input
                  type="radio"
                  name="product"
                  id="smart_meters"
                  value="Smart Meters"
                  checked={selectedSeries === "Smart Meters"}
                  onChange={handleChange}
                />
                <label htmlFor="smart_meters">Smart Meters</label>

                <input
                  type="radio"
                  name="product"
                  id="others"
                  value="Others"
                  checked={selectedSeries === "Others"}
                  onChange={handleChange}
                />
                <label htmlFor="others">Others</label>
              </div>
              <div className="info_inputs">
                <input type="text" name="name" value={inputData.name} onChange={handleInputChange} placeholder="Your Name" />
                <input type="text" name="email" value={inputData.email} onChange={handleInputChange} placeholder="Your Email" />
                <input type="text" name="number" value={inputData.number} onChange={handleInputChange} placeholder="Your Number" />
                <input type="text" name="message" value={inputData.message} onChange={handleInputChange} placeholder="Your Message" />
              </div>
              <button onClick={SendMessage} className="send_msg_btn">
                <RiSendPlaneFill className="send_msg_icon" />
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactDealer;
