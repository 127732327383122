import React from 'react'
import './commercialFirstSection.scss'
import { GrLocation } from "react-icons/gr";

const CommercialFirstSection = () => {
  return (
    <>
    <section className="commercial_home_first_section">
      <div className="overlay"></div>
      <video autoPlay loop muted playsInline >
        <source
          src="Images/commercialHome/Commerical Home Page.mp4"
          type="video/mp4"
        />
      </video>
      <div className="first_sec_contents">
        <p data-aos="zoom-in">Sustainable Solutions for Commercial <br />  & Industrial Energy Independence</p>
        <h1 data-aos="fade-right">Hybrid Solar & <br/> Storage  Power Solutions</h1>
        {/* <div className="search_input_wrapper">
          <div className="search_input">
            <GrLocation className="loc_icon" />
            <input type="text" placeholder="Search service in your area" />
          </div>
          <button className="search_button">
              Order Now
          </button>
        </div> */}
      </div>
    </section>
  </>
  )
}

export default CommercialFirstSection