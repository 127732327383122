import React, { useEffect } from "react";
import "./fourthSection.scss";
import { GrLocation } from "react-icons/gr";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const FourthSection = () => {
  useEffect(() => {
    Aos.init({ duration: 600, offset: 200 });
  }, []);
  const FeaturedProducts = [
    {
      id: 1,
      title: "Green Generater",
      desc: "Plug & Play Energy Solution",
      bg_img: "Images/Residential/FP1.png",
      buy_link: "/contact_us",
    },
    {
      id: 2,
      title: "Solar Home System",
      desc: "Get Solar for your Home",
      bg_img: "Images/Residential/FP2.png",
      buy_link: "/contact_us",
    },
    {
      id: 3,
      title: "Smart MEter Solution",
      desc: "Get Solar for your Home",
      bg_img: "Images/Residential/FP3.png",
      buy_link: "/contact_us",
    },
  ];
  return (
    <>
      <section className="res_fourth_section_wrapper">
        <div className="res_fourth_section">
          <div className="featured_products">
            <h1>Featured Products</h1>
            <div className="product_list">
              <div className="product_wrapper" data-aos="fade-up">
                <div className="product">
                  <img
                    src="Images/newhomeassets/step up series.jpeg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="contents">
                    <h1>Step-Up Series</h1>
                    <p>Solar Home System</p>
                    <button>
                      <Link to="/residential_setup_series">Explore Now</Link>
                    </button>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
              <div className="product_wrapper" data-aos="fade-up">
                <div className="product">
                  <img
                    src="Images/newhomeassets/enrich series.jpeg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="contents">
                    <h1>Enrich Series</h1>
                    <p>Microgrid Hybrid Solution</p>
                    <button>
                      <Link to="/residential_enrich_series">Explore Now</Link>
                    </button>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
              <div className="product_wrapper" data-aos="fade-up">
                <div className="product">
                  <img
                    src="Images/newhomeassets/Feature_Freedom.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="contents">
                    <h1>Freedom Series</h1>
                    <p>Minigrid Hybrid Solution</p>
                    <button>
                      <Link to="/residential_freedom_series">Explore Now</Link>
                    </button>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
            </div>
          </div>
          {/* <div className="search_service">
            <div className="area_image" data-aos="zoom-in-up">
              <img src="Images/Residential/areamap.png" alt="Area Map" />
            </div>
            <div className="search_box_wrapper" data-aos="fade-left">
              <h1>Search Service in your Area</h1>
              <div className="search_input_wrapper">
                <div className="search_input">
                  <GrLocation className="loc_icon" />
                  <input
                    type="text"
                    placeholder="Search service in your area"
                  />
                </div>
                <button className="search_button">Search Now</button>
              </div>
            </div>
          </div> */}
          <div className="connect_and_partner">
            <div
              className="cpcard connect"
              data-aos="flip-right"
              data-aos-duration="1000"
            >
              <h1>
                <b>Connect with us!</b>{" "}
              </h1>
              <h5>To learn more about Solar<br/> Installation & Renewable Energy</h5>

              <button>
                <a href="/contact_us">Contact Now</a>
              </button>
            </div>
            <div
              className="cpcard partner"
              data-aos="flip-left"
              data-aos-duration="1000"
            >
              <h1>Partner with us!</h1>
              <h5>
                Join our Network of
                <br /> Dealers & Distributors
              </h5>
              <button>
                <a
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSe9lDPXE8vVwQ4E_Vyc3Yi6IvGTPaAX2pnDU0O4TBrLfoFHxg/viewform?pli=1"
                >
                  Join Now
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FourthSection;
