import React from "react";

export const Hand = ({width,height,selectedIcon}) => {
  // console.log('Selected Icon ID in Hand component:', selectedIconId);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
      //  fill={selectedIcon === 1 ? "#19B01F" : "white"}
        d="M42.9113 30.5541L42.8784 30.4779L42.868 30.4589C42.1318 29.5252 41.2813 28.8392 40.3406 28.42C38.4594 27.436 35.977 28.7508 35.9684 28.7508C32.0049 23.5401 26.718 23.3842 24.8125 23.2006L24.1802 23.1902C19.9136 23.1902 16.3313 24.7666 16.2984 24.7822C15.919 24.9485 15.6748 25.3209 15.6713 25.7263L15.6245 31.4428C15.6193 31.9937 16.0541 32.4562 16.6154 32.4943L22.4306 32.8996C23.0248 33.324 27.546 36.4248 32.5627 36.4681H32.5766C33.7822 36.4075 36.0255 35.867 38.3884 34.5436C38.8128 34.3028 39.9128 33.6722 40.966 32.962C41.9915 32.2708 42.6047 31.7459 42.7883 31.4081C42.8646 31.2609 42.9824 30.9543 42.9096 30.5507L42.9113 30.5541ZM32.5263 34.3755C28.1316 34.3409 23.5445 31.0998 23.5012 31.0704C23.3297 30.9335 23.1235 30.8538 22.9035 30.8382L17.7639 30.4779L17.7968 26.4486C18.8188 26.0779 21.3705 25.2707 24.1924 25.2707H24.3153C24.9979 25.2776 25.6579 25.3296 26.278 25.4231C26.6089 25.5201 27.0731 25.6171 27.2481 25.6553C28.6495 25.9515 30.3852 26.3205 33.8862 29.6967C33.5969 29.7729 33.2885 29.8093 32.9455 29.8093C30.82 29.7902 28.6582 28.1272 28.5664 28.0545C28.381 27.909 28.1489 27.8293 27.9012 27.8275C27.5755 27.8275 27.2723 27.9713 27.0697 28.2208C26.7042 28.6729 26.7786 29.3329 27.236 29.6915C28.5664 30.7378 30.7715 31.8603 32.9646 31.8776H33.0183C34.515 31.8776 35.7986 31.3614 36.8293 30.3411C37.2052 30.1367 37.6383 30.0396 38.151 30.0396C38.5789 30.0431 38.9635 30.1263 39.2095 30.1938C39.788 30.348 40.2055 30.5715 40.4723 30.7464C38.8301 31.9365 34.0057 34.2993 32.5246 34.3738L32.5263 34.3755Z"
        fill="#19B01F"
        // style={{color :"red"}}

      />
      <path
        // fill={selectedIcon === 1 ? "#19B01F" : "white"}
        d="M36.0449 14.3348C33.2525 14.3348 30.9884 16.5989 30.9884 19.3913C30.9884 22.1838 33.2525 24.4478 36.0449 24.4478C38.8373 24.4478 41.1014 22.1838 41.1014 19.3913C41.1014 16.5989 38.8373 14.3348 36.0449 14.3348ZM38.7854 18.9271H38.3107V19.8591H38.7854V20.5173H38.3107V22.2686H37.5295L36.3584 20.5173H34.5118V22.2686H33.7808V20.5173H33.3062V19.8591H33.7808V18.9271H33.3062V18.2688H33.7808V16.5175H34.5621L35.7331 18.2688H37.5814V16.5175H38.3125V18.2688H38.7871V18.9271H38.7854ZM37.5797 21.0318V20.5173H37.235L37.5797 21.0318ZM36.1731 18.9271L36.7967 19.8591H37.5814V18.9271H36.1731ZM34.5118 18.9271V19.8591H35.9184L35.2966 18.9271H34.5118ZM34.5118 17.7543V18.2688H34.8566L34.5118 17.7543ZM37.5797 21.0318V20.5173H37.235L37.5797 21.0318ZM36.1731 18.9271L36.7967 19.8591H37.5814V18.9271H36.1731ZM34.5118 18.9271V19.8591H35.9184L35.2966 18.9271H34.5118ZM34.5118 17.7543V18.2688H34.8566L34.5118 17.7543ZM36.1731 18.9271L36.7967 19.8591H37.5814V18.9271H36.1731ZM34.5118 17.7543V18.2688H34.8566L34.5118 17.7543ZM37.2367 20.5173L37.5814 21.0318V20.5173H37.2367ZM35.2966 18.9271H34.5118V19.8591H35.9184L35.2966 18.9271Z"
        fill="#19B01F"
        
      

      />
    </svg>
  );
};
export const HandWhite = ({width,height,selectedIcon}) => {
  // console.log('Selected Icon ID in Hand component:', selectedIconId);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
       
        d="M42.9113 30.5541L42.8784 30.4779L42.868 30.4589C42.1318 29.5252 41.2813 28.8392 40.3406 28.42C38.4594 27.436 35.977 28.7508 35.9684 28.7508C32.0049 23.5401 26.718 23.3842 24.8125 23.2006L24.1802 23.1902C19.9136 23.1902 16.3313 24.7666 16.2984 24.7822C15.919 24.9485 15.6748 25.3209 15.6713 25.7263L15.6245 31.4428C15.6193 31.9937 16.0541 32.4562 16.6154 32.4943L22.4306 32.8996C23.0248 33.324 27.546 36.4248 32.5627 36.4681H32.5766C33.7822 36.4075 36.0255 35.867 38.3884 34.5436C38.8128 34.3028 39.9128 33.6722 40.966 32.962C41.9915 32.2708 42.6047 31.7459 42.7883 31.4081C42.8646 31.2609 42.9824 30.9543 42.9096 30.5507L42.9113 30.5541ZM32.5263 34.3755C28.1316 34.3409 23.5445 31.0998 23.5012 31.0704C23.3297 30.9335 23.1235 30.8538 22.9035 30.8382L17.7639 30.4779L17.7968 26.4486C18.8188 26.0779 21.3705 25.2707 24.1924 25.2707H24.3153C24.9979 25.2776 25.6579 25.3296 26.278 25.4231C26.6089 25.5201 27.0731 25.6171 27.2481 25.6553C28.6495 25.9515 30.3852 26.3205 33.8862 29.6967C33.5969 29.7729 33.2885 29.8093 32.9455 29.8093C30.82 29.7902 28.6582 28.1272 28.5664 28.0545C28.381 27.909 28.1489 27.8293 27.9012 27.8275C27.5755 27.8275 27.2723 27.9713 27.0697 28.2208C26.7042 28.6729 26.7786 29.3329 27.236 29.6915C28.5664 30.7378 30.7715 31.8603 32.9646 31.8776H33.0183C34.515 31.8776 35.7986 31.3614 36.8293 30.3411C37.2052 30.1367 37.6383 30.0396 38.151 30.0396C38.5789 30.0431 38.9635 30.1263 39.2095 30.1938C39.788 30.348 40.2055 30.5715 40.4723 30.7464C38.8301 31.9365 34.0057 34.2993 32.5246 34.3738L32.5263 34.3755Z"
        fill="#19B01F"
        // style={{color :"red"}}

      />
      <path
        
        d="M36.0449 14.3348C33.2525 14.3348 30.9884 16.5989 30.9884 19.3913C30.9884 22.1838 33.2525 24.4478 36.0449 24.4478C38.8373 24.4478 41.1014 22.1838 41.1014 19.3913C41.1014 16.5989 38.8373 14.3348 36.0449 14.3348ZM38.7854 18.9271H38.3107V19.8591H38.7854V20.5173H38.3107V22.2686H37.5295L36.3584 20.5173H34.5118V22.2686H33.7808V20.5173H33.3062V19.8591H33.7808V18.9271H33.3062V18.2688H33.7808V16.5175H34.5621L35.7331 18.2688H37.5814V16.5175H38.3125V18.2688H38.7871V18.9271H38.7854ZM37.5797 21.0318V20.5173H37.235L37.5797 21.0318ZM36.1731 18.9271L36.7967 19.8591H37.5814V18.9271H36.1731ZM34.5118 18.9271V19.8591H35.9184L35.2966 18.9271H34.5118ZM34.5118 17.7543V18.2688H34.8566L34.5118 17.7543ZM37.5797 21.0318V20.5173H37.235L37.5797 21.0318ZM36.1731 18.9271L36.7967 19.8591H37.5814V18.9271H36.1731ZM34.5118 18.9271V19.8591H35.9184L35.2966 18.9271H34.5118ZM34.5118 17.7543V18.2688H34.8566L34.5118 17.7543ZM36.1731 18.9271L36.7967 19.8591H37.5814V18.9271H36.1731ZM34.5118 17.7543V18.2688H34.8566L34.5118 17.7543ZM37.2367 20.5173L37.5814 21.0318V20.5173H37.2367ZM35.2966 18.9271H34.5118V19.8591H35.9184L35.2966 18.9271Z"
        fill="#19B01F"
        
      

      />
    </svg>
  );
};

 
