import React, { useEffect, useState } from "react";
import "./resFreedomSeries.scss";
import { GrLocation } from "react-icons/gr";
import Header from "../../../Common Components/Header/Header";
import Footer from "../../../Common Components/Footer/Footer";
import SecondSection from "../ProductPageComponents/SecondSection";
import Products from "../../../Common Components/Products/Products";
import Kite from "../../../Common Components/Icons/Kite";
import { Mobile, MobileWhite } from "../../../Common Components/Icons/Mobile";
import { Hand } from "../../../Common Components/Icons/Hand";
import Round from "../../../Common Components/Icons/Round";
import { Punch, PunchWhite } from "../../../Common Components/Icons/Punch";
import Bulb from "../../../Common Components/Icons/Bulb";
import FullBattery from "../../../Common Components/Icons/FullBattery";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProductResFreedom from "../../../Common Components/Products/ProductResFreedom";
import Shield from "../../../Common Components/Icons/Shield";
import Wifi from "../../../Common Components/Icons/Wifi";
import Battery from "../../../Common Components/Icons/Battery";

const ResFreedomSeries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [checked, setChecked] = useState({
    product1: "true",
    product2: "",
  });
  const pdfFile = "2.2 FREEDOM Series.pdf";

  const Product1 = {
    id: 1,
    desc1: "Hybrid Solar Inverter :15KW",
    desc2: "Smart Battery : up to 48KWh",
    desc3: "Solar Panel : up to 22KWp",
    images: {
      image: "Images/FreedeomResidental/FreedomPr.png",
      image2: "Images/FreedeomResidental/Freedom 01.jpg",
      image3: "Images/FreedeomResidental/Freedom 02.jpg",
      image4: "Images/FreedeomResidental/Freedom 03.jpg",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product2 = {
    id: 2,
    desc1: "Hybrid Solar Inverter :60KW",
    desc2: "Smart Battery : up to 192KWh",
    desc3: "Solar Panel : up to 88KWp",
    images: {
      image: "Images/FreedeomResidental/FreedomPr02.png",
      image2: "Images/FreedeomResidental/Freedom 01.jpg",
      image3: "Images/FreedeomResidental/Freedom 02.jpg",
      image4: "Images/FreedeomResidental/Freedom 03.jpg",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };
  const [product, setProduct] = useState(Product1);

  const ChangeProduct1 = () => {
    setProduct(Product1);
    setChecked({ product1: "true" });
  };

  const ChangeProduct2 = () => {
    setProduct(Product2);
    setChecked({ product2: "true" });
  };
  const ProductButtons = [
    {
      id: 1,
      name: "SG-FH15",
      action: ChangeProduct1,
      checked: checked.product1,
    },
    {
      id: 2,
      name: "SG-FH60",
      action: ChangeProduct2,
      checked: checked.product2,
    },
  ];

  const [selectedIcon, setSelectedIcon] = useState({
    title: "Smart & Safe",
    content: [
      "Web Based Monitoring for Remote Support ",
      "Intelligent Energy Management System (EMS) ensures battery optimization",
      "Inbuilt protection devices for safe operation Maintenance free VRLA Battery. Safe for environment ",
    ],
    image: "Images/FreedeomResidental/SMART & SAFE.jpg",
    icon: <Shield />,

    id: 1,
  });
  const [selectedIconId, setSelectedIconId] = useState(1);
  console.log("selectedIconId", selectedIconId);
  const iconData = [
    {
      id: 1,
      label: "Icon 1",
      data: "Data 1",
      icon: <Shield />,
      title: "Smart & Safe",
      image: "Images/FreedeomResidental/SMART & SAFE.jpg",
      content: [
        "Web Based Monitoring for Remote Support ",
        "Intelligent Energy Management System (EMS) ensures battery optimization",
        "Inbuilt protection devices for safe operation Maintenance free VRLA Battery. Safe for environment ",
      ],
    },
    {
      id: 2,
      label: "Icon 2",
      data: "Data 2",
      icon: <Wifi />,
      // iconCheck: <MobileWhite selectedIcon={setSelectedIcon} />,
      title: "Compact & Modular",
      image: "Images/FreedeomResidental/COMPACT & MODULAR.jpg",
      content: [
        "Connect up to 90kW in parallel for easy scalability",
        "Small Foot print. Plug n play operation ",
        "Pre assembled, self contained System ",
      ],
    },
    {
      id: 3,
      label: "Icon 3",
      data: "Data 3",
      icon: <Battery />,
      // iconCheck: <PunchWhite selectedIcon={selectedIcon} />,

      title: "Absolute Powerpack",
      image: "Images/FreedeomResidental/ABSOLUTE POWERPACK.jpg",
      content: [
        "Seamless switching between Grid power to Inverter",
        "Support charging via Solar, Grid & other sources like diesel generator.",
        "  Uninterrupted 24X7 reliable power ",
      ],
    },
  ];
  const handleIconClick = (icon) => {
    setSelectedIconId(icon.id);
    setSelectedIcon({
      title: icon.title,
      content: icon.content,
      image: icon.image,
      icon: icon.icon,
      iconCheck: icon.iconCheck,
      id: icon.id,
    });
  };
  const handleImageArrowClick = (direction) => {
    const currentIndex = iconData.findIndex(
      (icon) => icon.id === selectedIcon.id
    );
    let newIndex;

    if (direction === "left") {
      newIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : iconData.length - 1;
    } else {
      newIndex = currentIndex + 1 < iconData.length ? currentIndex + 1 : 0;
    }

    setSelectedIcon({
      title: iconData[newIndex].title,
      content: iconData[newIndex].content,
      image: iconData[newIndex].image,
      icon: iconData[newIndex].icon,
      iconCheck: iconData[newIndex].iconCheck,
      id: iconData[newIndex].id,
    });
    setSelectedIconId(iconData[newIndex].id);
  };
  return (
    <>
      <Header />
      <div
        id="carouselExampleIndicators"
        className="carousel slide res_third_section"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div
          className="carousel-inner"
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          <div
            className="carousel-item active"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/FreedeomResidental/homefreedombanner1.png"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/FreedeomResidental/hero banner 2.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/FreedeomResidental/hero banner 3.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <div className="residental-features-heaidng">
        <div className="residental-features-heaidng-con">
          <h1>Features</h1>
        </div>
      </div>

      <section className="residental-features">
        <div className="resitental-features-container">
          <div className="residetal-sidebar-first">
            <div className="resitentl-colmn1">
              <div className="resitentl-colmn1-icon">{selectedIcon.icon}</div>
            </div>
            <div className="resitentl-colmn2">
              <h1>{selectedIcon.title}</h1>
            </div>
            <div className="resitentl-colmn3">
              <ul>
                {selectedIcon.content.map((contentItem, index) => (
                  <li key={index}>{contentItem}</li>
                ))}
              </ul>
              {/* <p>{selectedIcon.content}</p> */}
            </div>
            <div className="resitentl-colmn4">
              {iconData.map((icon) => (
                <div
                  key={icon.id}
                  className={`filter-delhi ${
                    icon.id === selectedIconId ? "selected" : ""
                  }`}
                  // className={icon.id === selectedIconId ? "selected" : "ddddd"}
                  onClick={() => handleIconClick(icon)}
                >
                  {icon.icon}
                </div>
              ))}
            </div>
          </div>
          <div
            className="residetal-sidebar-second"
            style={{ boxShadow: "15px 15px 0px #18AF1E", borderRadius: "28px" }}
          >
            <div className="arrow-left"> </div>
            <div className="small-arrow-left">
              <ArrowBackIosIcon
                onClick={() => handleImageArrowClick("left")}
                className="large-icon-small"
              />
            </div>

            <img src={selectedIcon.image} alt="" />
            <div className="arrow-right"></div>
            <div className="small-arrow-right">
              <ArrowForwardIosIcon
                onClick={() => handleImageArrowClick("right")}
                className="large-icon-small"
              />
            </div>
          </div>
        </div>
      </section>

      <ProductResFreedom
        product={product}
        changeProduct1={ChangeProduct1}
        changeProduct2={ChangeProduct2}
        checked={checked}
        ProductButtons={ProductButtons}
        pdfurl={pdfFile}
      />

      <Footer />
    </>
  );
};

export default ResFreedomSeries;
