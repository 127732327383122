import React from "react";
import "./aboutUs.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { LuPhone, LuFactory } from "react-icons/lu";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";

const AboutUs = () => {
  return (
    <>
      <Header />
      <section className="about_us">
        <div className="about_banner">
          <div className="about_text_container">
            <h1>Who We Are</h1>
            <h3>Know About Skipper Green</h3>
          </div>
        </div>
        <div className="main_text_container">
          <div className="img_text_container">
            <div className="img_container">
              <img
                src="https://skippergreen.com/wp-content/uploads/2023/04/2-2-2048x1370.jpg"
                alt=""
              />
            </div>
            <div className="text_container">
              <h3>About Us</h3>
              <h1>
                Skipper Green, as a brand is dedicated to providing reliable &
                affordable solar energy solutions.
              </h1>
              <p>
                Our mission is to provide clean and renewable energy while
                reducing your carbon footprint and energy cost. We specialize in
                creating and setting up solar energy systems for residential and
                commercial buildings that are tailored to each one’s particular
                requirements. In close collaboration with you, our team of
                qualified experts will evaluate your energy requirements and
                suggest the best solar energy system for you.
              </p>
            </div>
          </div>
          <div className="only_text_container">
            <p>
              To ensure that your system runs effectively and dependably for
              many years to come, we only use the best solar panels, inverters,
              and batteries. We also handle every aspect of the installation
              process, from design to installation and maintenance, ensuring
              that you are able to relish the benefits of clean, renewable
              energy. Throughout the entire process, we are dedicated to
              offering outstanding customer service and support, and in
              everything we do, we work hard to go above and beyond for our
              clients. To find out more about how solar energy can help you save
              money and lessen your carbon footprint, contact us right away! To
              ensure that your system runs effectively and dependably for many
              years to come, we only use the best solar panels, inverters, and
              batteries. We also handle every aspect of the installation
              process, from design to installation and maintenance, ensuring
              that you are able to relish the benefits of clean, renewable
              energy. Throughout the entire process, we are dedicated to
              offering outstanding customer service and support, and in
              everything we do, we work hard to go above and beyond for our
              clients. To find out more about how solar energy can help you save
              money and lessen your carbon footprint, contact us right away!
            </p>
          </div>
        </div>
        {/* <div className="overview_wrapper">
            <h3>Overview</h3>
            <h1>We Serves Across Africa</h1>
            <img src="https://skippergreen.com/wp-content/uploads/2023/04/Msp-01-01.svg" alt="map" />
        </div> */}
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;
