import React from "react";

const SheildSecond = ({width,height}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.6642 11.2315C35.5151 11.2315 35.3721 11.2907 35.2667 11.3961C35.1613 11.5015 35.1021 11.6445 35.1021 11.7935C35.1021 11.9426 35.1613 12.0856 35.2667 12.191C35.3721 12.2964 35.5151 12.3556 35.6642 12.3556C37.0819 12.3566 38.4413 12.9202 39.4438 13.9227C40.4463 14.9252 41.0099 16.2846 41.0109 17.7024C41.0109 17.8514 41.0702 17.9944 41.1756 18.0998C41.281 18.2052 41.4239 18.2644 41.573 18.2644C41.722 18.2644 41.865 18.2052 41.9704 18.0998C42.0758 17.9944 42.135 17.8514 42.135 17.7024C42.133 15.9868 41.4506 14.3421 40.2375 13.129C39.0245 11.9159 37.3797 11.2335 35.6642 11.2315Z"
        // fill="#19B01F"
      />
      <path
        d="M35.6642 13.1312C35.5151 13.1312 35.3721 13.1904 35.2667 13.2958C35.1613 13.4012 35.1021 13.5441 35.1021 13.6932C35.1021 13.8423 35.1613 13.9852 35.2667 14.0906C35.3721 14.196 35.5151 14.2552 35.6642 14.2552C36.5781 14.2562 37.4543 14.6197 38.1005 15.266C38.7468 15.9122 39.1103 16.7884 39.1113 17.7024C39.1113 17.8514 39.1705 17.9944 39.2759 18.0998C39.3813 18.2052 39.5243 18.2644 39.6733 18.2644C39.8224 18.2644 39.9653 18.2052 40.0707 18.0998C40.1761 17.9944 40.2353 17.8514 40.2353 17.7024C40.2343 16.4903 39.7524 15.3282 38.8954 14.4711C38.0383 13.6141 36.8762 13.1322 35.6642 13.1312Z"
        // fill="#19B01F"
      />
      <path
        d="M35.6642 15.0308C35.5151 15.0308 35.3721 15.0901 35.2667 15.1955C35.1613 15.3009 35.1021 15.4438 35.1021 15.5929C35.1021 15.7419 35.1613 15.8849 35.2667 15.9903C35.3721 16.0957 35.5151 16.1549 35.6642 16.1549C36.0749 16.1549 36.4689 16.3178 36.7597 16.6079C37.0505 16.898 37.2144 17.2916 37.2154 17.7024C37.2154 17.8514 37.2746 17.9944 37.38 18.0998C37.4854 18.2052 37.6283 18.2644 37.7774 18.2644C37.9265 18.2644 38.0694 18.2052 38.1748 18.0998C38.2802 17.9944 38.3394 17.8514 38.3394 17.7024C38.3384 16.9935 38.0561 16.314 37.5545 15.8131C37.0529 15.3122 36.373 15.0308 35.6642 15.0308Z"
        // fill="#19B01F"
      />
      <path
        d="M35.5556 17.1291C35.039 17.1987 34.5183 17.2338 33.9969 17.234C31.1599 17.2334 28.4201 16.1999 26.2896 14.3264C26.187 14.2335 26.0534 14.182 25.9149 14.182C25.7764 14.182 25.6429 14.2335 25.5402 14.3264C23.4097 16.1999 20.67 17.2334 17.8329 17.234C17.3116 17.2336 16.7909 17.1986 16.2742 17.1291C16.1948 17.1174 16.1139 17.1231 16.0369 17.1457C15.9599 17.1683 15.8888 17.2074 15.8283 17.2602C15.7687 17.3136 15.7209 17.3788 15.688 17.4517C15.6551 17.5246 15.6378 17.6036 15.6373 17.6836V25.301C15.6242 28.4915 16.5797 31.6108 18.3773 34.2467C20.1749 36.8826 22.7301 38.911 25.7051 40.0637C25.7707 40.0899 25.8405 40.1039 25.9112 40.1049C25.9819 40.1043 26.0518 40.0903 26.1173 40.0637C29.0937 38.9122 31.6504 36.8843 33.4495 34.2484C35.2485 31.6124 36.205 28.4924 36.1926 25.301V17.6836C36.193 17.6042 36.1769 17.5255 36.1453 17.4526C36.1137 17.3798 36.0673 17.3142 36.009 17.2602C35.9477 17.2064 35.8753 17.1667 35.797 17.144C35.7186 17.1214 35.6362 17.1163 35.5556 17.1291ZM35.0685 25.301C35.0833 28.2303 34.2193 31.0968 32.5883 33.53C30.9572 35.9633 28.6339 37.8516 25.9187 38.9509C23.204 37.8507 20.8813 35.9622 19.2504 33.5292C17.6194 31.0961 16.7551 28.2301 16.7688 25.301V18.3131C17.1435 18.3431 17.492 18.3581 17.8404 18.3581C20.7839 18.3595 23.6375 17.3443 25.9187 15.4842C28.2002 17.3438 31.0536 18.3589 33.9969 18.3581C34.3491 18.3581 34.7051 18.3581 35.0685 18.3131V25.301Z"
        // fill="#19B01F"
      />
      <path
        d="M26.2409 16.7581C26.147 16.6924 26.0351 16.6571 25.9205 16.6571C25.8059 16.6571 25.694 16.6924 25.6001 16.7581C23.4066 18.2806 20.8134 19.1237 18.1439 19.1824C17.9968 19.1853 17.8567 19.2458 17.7537 19.3509C17.6507 19.456 17.593 19.5973 17.5931 19.7444V25.301C17.5912 27.9622 18.3523 30.5681 19.786 32.81C21.2197 35.0519 23.2661 36.8359 25.6826 37.9505C25.757 37.983 25.8374 37.9996 25.9186 37.9992C25.9999 38.0001 26.0804 37.9835 26.1547 37.9505C28.5712 36.8359 30.6175 35.0519 32.0512 32.81C33.485 30.5681 34.246 27.9622 34.2442 25.301V19.7444C34.2452 19.597 34.1878 19.4551 34.0846 19.3498C33.9815 19.2446 33.8408 19.1843 33.6934 19.1824C31.0249 19.1243 28.4328 18.2811 26.2409 16.7581ZM33.1201 25.301C33.123 27.703 32.449 30.0573 31.1753 32.0938C29.9015 34.1303 28.0797 35.7666 25.9186 36.8152C23.7581 35.7659 21.9367 34.1294 20.6631 32.093C19.3895 30.0567 18.7151 27.7028 18.7171 25.301V20.2802C21.285 20.1307 23.7684 19.3089 25.9186 17.8972C28.0688 19.3089 30.5523 20.1307 33.1201 20.2802V25.301Z"
        // fill="#19B01F"
      />
      <path
        d="M23.0673 23.48V24.6041H22.5802C22.4311 24.6041 22.2882 24.6633 22.1828 24.7687C22.0774 24.8741 22.0182 25.0171 22.0182 25.1661V31.4609C22.0182 31.6099 22.0774 31.7529 22.1828 31.8583C22.2882 31.9637 22.4311 32.0229 22.5802 32.0229H29.2646C29.4137 32.0229 29.5566 31.9637 29.662 31.8583C29.7674 31.7529 29.8266 31.6099 29.8266 31.4609V25.1811C29.8266 25.0321 29.7674 24.8891 29.662 24.7837C29.5566 24.6783 29.4137 24.6191 29.2646 24.6191H28.77V23.495C28.77 22.7388 28.4696 22.0135 27.9349 21.4788C27.4001 20.9441 26.6749 20.6437 25.9187 20.6437C25.1624 20.6437 24.4372 20.9441 23.9024 21.4788C23.3677 22.0135 23.0673 22.7388 23.0673 23.495V23.48ZM28.6876 30.8988H23.1422V25.7431H28.6988L28.6876 30.8988ZM27.6347 23.48V24.6041H24.1913V23.48C24.1913 23.0219 24.3733 22.5826 24.6973 22.2586C25.0212 21.9347 25.4605 21.7527 25.9187 21.7527C26.3768 21.7527 26.8161 21.9347 27.14 22.2586C27.464 22.5826 27.646 23.0219 27.646 23.48H27.6347Z"
        // fill="#19B01F"
      />
      <path
        d="M25.9186 25.9417C25.6452 25.9431 25.3774 26.0192 25.1442 26.1618C24.9109 26.3044 24.7211 26.5081 24.5953 26.7508C24.4695 26.9935 24.4125 27.2661 24.4305 27.5389C24.4484 27.8117 24.5406 28.0744 24.6972 28.2985L24.6297 29.9396C24.6259 30.0924 24.6824 30.2405 24.7871 30.3518C24.8396 30.4061 24.9025 30.4493 24.972 30.4789C25.0415 30.5085 25.1162 30.5239 25.1917 30.5242H26.6418C26.7173 30.5239 26.792 30.5085 26.8615 30.4789C26.931 30.4493 26.9939 30.4061 27.0464 30.3518C27.0997 30.2977 27.1411 30.2331 27.1682 30.1622C27.1953 30.0912 27.2074 30.0155 27.2038 29.9396L27.1401 28.2985C27.3165 28.0501 27.4108 27.7527 27.4099 27.448C27.4109 27.2512 27.3731 27.0561 27.2987 26.8739C27.2243 26.6916 27.1147 26.5259 26.9762 26.386C26.8377 26.2461 26.6731 26.1349 26.4916 26.0587C26.3102 25.9824 26.1155 25.9427 25.9186 25.9417ZM26.1772 27.6878C26.122 27.7433 26.0786 27.8095 26.0496 27.8822C26.0206 27.955 26.0067 28.0329 26.0086 28.1112L26.061 29.3814H25.7763L25.8287 28.1112C25.8322 28.033 25.8194 27.955 25.791 27.8821C25.7626 27.8092 25.7193 27.743 25.6638 27.6878C25.629 27.6544 25.6014 27.6143 25.5827 27.5698C25.564 27.5253 25.5546 27.4775 25.5552 27.4292C25.5741 27.3465 25.6205 27.2725 25.6868 27.2196C25.7532 27.1666 25.8356 27.1378 25.9205 27.1378C26.0054 27.1378 26.0878 27.1666 26.1542 27.2196C26.2205 27.2725 26.267 27.3465 26.2858 27.4292C26.2844 27.5262 26.2455 27.6189 26.1772 27.6878Z"
        // fill="#19B01F"
      />
    </svg>
  );
};

export default SheildSecond