import React, { useCallback, useEffect, useState } from "react";
import "./oneBlog.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const BlogById = () => {
    const {id}=useParams()
  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [blogs, setBlogs] = useState([]);
  const [blog, setBlog] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState([]);

  const ReadMore = (blogId) => {
    localStorage.setItem("BlogId", blogId);
    history("/blog");
  };

  const handleNavigateButton =(idBlog)=>{
    history(`/blog/name/${idBlog}`)
  }


  useEffect(() => {
   
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://dashboard.skippergreen.com/blogs/${id}`
        ); // Replace with your API endpoint
        setBlog(response.data);
        console.log(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.get("https://dashboard.skippergreen.com/blogs/"); // Replace with your API endpoint
        setBlogs(response.data);
        console.log(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchBlog();
  }, [id]);


 

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Pad single digit day and month with leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };
  return (
    <>
      <Header />
      <section className="One_Blog">
        <div className="hero_section">
          <div className="header_text">
            <h1 style={{ textTransform: "capitalize", margin: "0 2.5rem", textAlign: 'center' }}>{blog.title}</h1>
          </div>
          <img src="/Images/BannerCS.png" alt="Banner Case Studies" />
        </div>
        <div className="content_section_wrapper">
          <div className="content_section">
            <div className="info_wrapper">
              <p>
                Home &gt; Product Range &gt; <span>You are here</span>
              </p>
            </div>
            <div className="years_wrapper">
              <p>Get year wise updates</p>
              <div className="year">
                <div className="radio_btn">
                  <input type="radio" name="year" id="2024" />
                  <label htmlFor="2024">2024</label>
                </div>
                <div className="radio_btn">
                  <input type="radio" name="year" id="2023" />
                  <label htmlFor="2023">2023</label>
                </div>
                <div className="radio_btn">
                  <input type="radio" name="year" id="2022" />
                  <label htmlFor="2022">2022</label>
                </div>
              </div>
            </div>
            <div className="articles_wrapper">
              <div className="article">
                <div className="image_container">
                  <div id="carouselExampleIndicators" className="carousel slide">
                    <div className="carousel-indicators">
                      {blog.blog_img && (
                        <button
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to="0"
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"
                        ></button>
                      )}
                      {blog.blog_img1 && (
                        <button
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to="1"
                          aria-label="Slide 2"
                        ></button>
                      )}
                      {blog.blog_img2 && (
                        <button
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to="2"
                          aria-label="Slide 3"
                        ></button>
                      )}
                      {blog.blog_img3 && (
                        <button
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to="3"
                          aria-label="Slide 4"
                        ></button>
                      )}
                    </div>
                    <div className="carousel-inner">
                      {blog.blog_img && (
                        <div className="carousel-item active">
                          <img
                            src={`https://dashboard.skippergreen.com${blog.blog_img}`}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      )}
                      {blog.blog_img1 && (
                        <div className="carousel-item">
                          <img
                            src={`https://dashboard.skippergreen.com${blog.blog_img1}`}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      )}
                      {blog.blog_img2 && (
                        <div className="carousel-item">
                          <img
                            src={`https://dashboard.skippergreen.com${blog.blog_img2}`}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      )}
                      {blog.blog_img3 && (
                        <div className="carousel-item">
                          <img
                            src={`https://dashboard.skippergreen.com${blog.blog_img3}`}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <img src={`https://dashboard.skippergreen.com${item?.CaseStudy_img}`} alt="" /> */}
                </div>
                <h1>{blog.title}</h1>
                <div className="tags_wrapper">
                  <p>Published {formatDate(blog.created_at)}</p>
                  <span>Tags</span>
                  <p>{blog.category}</p>
                </div>
                <p className="desc">{blog.content}</p>
              </div>
            </div>
          </div>
          <div className="latest_posts_wrapper">
            <h1>Latest Posts</h1>
            {blogs.map((item) => (
              <>
                <ul className="single_post_link">
                  <li
                    // onClick={() => {
                    //   ReadMore(item.id);
                    // }}
                    onClick={()=>handleNavigateButton(item?.id)}

                  >
                    {item?.title}
                  </li>
                  <li>
                    {item?.content.split(" ").slice(0, 20).join(" ") + "..."}
                  </li>
                </ul>
              </>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogById