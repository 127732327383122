import React from "react";
import "./commercialFourthSection.scss";
import { GrLocation } from "react-icons/gr";
import { Link } from "react-router-dom";

const CommercialFourthSection = () => {
  return (
    <>
      <section className="commercial_fourth_section_wrapper">
        <div className="res_fourth_section">
          <div className="featured_products">
            <h1>Featured Products</h1>
            <div className="product_list">
              <div className="product_wrapper" data-aos="fade-right">
                <div className="product">
                  <img
                    src="Images/commercialHome/envault series.jpeg"
                    alt=""
                  />
                  <div className="contents">
                    <h1>Envault Series</h1>
                    <p>C&I Solar Bess Solution </p>
                    <button><Link to="/commercial_envault_series">Explore Now</Link></button>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
              <div className="product_wrapper" data-aos="zoom-in-up">
                <div className="product">
                  <img
                    src="Images/newhomeassets/Feature_Freedom.jpg"
                    alt=""
                  />
                  <div className="contents">
                    <h1>Freedom Series</h1>
                    <p>Minigrid Hybrid Solution</p>
                    <button><Link to="/commercial_freedom_series">Explore Now</Link></button>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
              <div className="product_wrapper" data-aos="fade-left">
                <div className="product">
                  <img
                    src="Images/commercialHome/enrich series.jpeg"
                    alt=""
                  />
                  <div className="contents">
                    <h1>Enrich Series</h1>
                    <p>Microgrid Hybrid Solution</p>
                    <button><Link to="/commercial_enrich_series">Explore Now</Link></button>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
            </div>
          </div>


          <div className="Partner_with_us">
            <div className="partner_image" data-aos="zoom-in">
              <img src="Images/commercialHome/Partner with us.jpeg" alt="Area Map" style={{objectFit:"cover"}} />
            </div>
            <div className="partner_contents">
              <h1>Partner with us Now! </h1>
              <p style={{fontWeight:"500"}}> Join Our Network of Dealers and Distributors</p>
              <button><Link target="_blank" to='https://docs.google.com/forms/d/e/1FAIpQLSe9lDPXE8vVwQ4E_Vyc3Yi6IvGTPaAX2pnDU0O4TBrLfoFHxg/viewform?pli=1'>Join Now</Link></button>
            </div>
          </div>
        </div>
      </section>

      <section className="commercial_fourth_section_wrapper_mobile">
        <div className="res_fourth_section">
          <div className="featured_products">
            <h1>Featured Products</h1>
            <div className="product_list">
              <div className="product_wrapper">
                <div className="product">
                  <img
                    src="Images/commercialHome/envault series.jpeg"
                    alt=""
                  />
                  <div className="contents">
                    <h1>Envault Series</h1>
                    <p>C&I Solar Bess Solution </p>
                    <button><Link to="/commercial_envault_series">Explore Now</Link></button>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
              <div className="product_wrapper">
                <div className="product">
                  <img
                    src="Images/newhomeassets/Feature_Freedom.jpg"
                    alt=""
                  />
                  <div className="contents">
                    <h1>Freedom Series</h1>
                    <p>Minigrid Hybrid Solution</p>
                    <button><Link to="/commercial_freedom_series">Explore Now</Link></button>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
              <div className="product_wrapper">
                <div className="product">
                  <img
                    src="Images/commercialHome/enrich series.jpeg"
                    alt=""
                  />
                  <div className="contents">
                    <h1>Enrich Series</h1>
                    <p>Microgrid Hybrid Solution</p>
                    <button><Link to="/commercial_enrich_series">Explore Now</Link></button>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
            </div>
          </div>


          <div className="Partner_with_us">
          <div className="partner_contents">
              <h1>Partner with us Now! </h1>
              <p style={{fontWeight:"500"}}> Join Our Network of Dealers and Distributors</p>
            </div>
            <div className="partner_image" data-aos="zoom-in">
              <img src="Images/commercialHome/Partner with us.jpeg" alt="Area Map" style={{objectFit:"cover"}} />
            </div>
            <div className="partner_contents">
              <button><Link target="_blank" to='https://docs.google.com/forms/d/e/1FAIpQLSe9lDPXE8vVwQ4E_Vyc3Yi6IvGTPaAX2pnDU0O4TBrLfoFHxg/viewform?pli=1'>Join Now</Link></button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommercialFourthSection;
