import React from "react";

const Comment = ({ width, height }) => {
  return (
    <svg
      width="38"
      height="35"
      viewBox="0 0 38 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.7519 23.2616L28.7527 23.2592C28.9048 22.8325 29.0306 22.3953 29.1271 21.9499H32.0982L28.7519 23.2616ZM32.3117 19.5427C32.3117 11.4218 25.109 4.81649 16.2573 4.81649V7.49933C22.5999 7.49933 27.9058 11.6455 29.1242 17.1369L32.3116 19.5426L32.3117 19.5427ZM29.1374 17.1388H26.7377C25.5631 12.8327 21.3102 9.63375 16.2576 9.63375V12.3166C20.6018 12.3166 24.1359 15.5587 24.1359 19.5443H27.06V19.5441H32.3117L29.1374 17.1388ZM32.0982 21.9501C31.5689 24.9307 30.0512 27.6806 27.703 29.8751C24.6618 32.7108 20.5971 34.275 16.2574 34.275C15.146 34.275 14.0553 34.173 12.9938 33.9717C10.9666 33.5913 9.05145 32.8547 7.33459 31.7962L4.68231 32.448L0.878964 33.3818L1.8996 29.8926L2.63927 27.3525C1.05242 25.0321 0.202637 22.3456 0.202637 19.5433C0.202637 15.5648 1.93085 11.9482 4.73836 9.29751C7.00465 7.15114 9.97864 5.63549 13.3065 5.06404C13.4177 5.04788 13.5258 5.02925 13.637 5.01309V7.73884C11.7858 8.08225 10.0781 8.788 8.59838 9.76431C5.288 11.9562 3.12713 15.5243 3.12713 19.5433C3.12713 20.6969 3.30236 21.8264 3.64459 22.9076C4.24111 24.8045 5.34936 26.5483 6.89933 27.9944C9.38492 30.3125 12.7099 31.5921 16.2571 31.5921C19.807 31.5921 23.1291 30.3125 25.6176 27.9944C27.0703 26.6374 28.1315 25.0206 28.7517 23.2615L28.7525 23.2593C28.9046 22.8326 29.0304 22.3954 29.1268 21.95H32.098L32.0982 21.9501ZM16.2574 14.5474C13.2511 14.5474 10.8122 16.7849 10.8122 19.5429C10.8122 22.3036 16.2574 26.5303 16.2574 26.5303C16.2574 26.5303 21.7053 22.3035 21.7053 19.5429C21.7053 16.7849 19.2664 14.5474 16.2574 14.5474ZM16.2574 21.8571C14.865 21.8571 13.7349 20.8226 13.7349 19.5429C13.7349 18.2656 14.8649 17.2312 16.2574 17.2312C17.6497 17.2312 18.7797 18.2657 18.7797 19.5429C18.7797 20.8227 17.6495 21.8571 16.2574 21.8571ZM37.5614 19.5445C37.5614 8.76782 28.0045 0 16.2577 0V2.68274C26.3924 2.68274 34.6372 10.2468 34.6372 19.5442L37.5614 19.5444V19.5445Z"
        // fill="#18AF1E"
      />
    </svg>
  );
};

export default Comment;
