import React from "react";

export const Mobile = ({width,height,selectedIcon}) => {
  console.log('Selected Icon ID in Mobile component:', selectedIcon);
  return (
    <svg
    width={width}
    height={height}
      viewBox="0 0 57 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.8863 12.7876C36.2337 12.7876 42.1877 18.7436 42.1877 26.091C42.1877 33.4384 36.2317 39.3924 28.8863 39.3924C21.5408 39.3924 15.5848 33.4364 15.5848 26.091C15.5848 18.7456 21.5408 12.7876 28.8863 12.7876ZM28.8863 10.7977C37.3326 10.7977 44.1776 17.6446 44.1776 26.089C44.1776 34.5334 37.3307 41.3804 28.8863 41.3804C20.4419 41.3804 13.5949 34.5334 13.5949 26.089C13.5949 17.6446 20.4399 10.7977 28.8863 10.7977Z"
        fill= "#19B01F"  
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.8869 19.2107C32.6862 19.2107 35.7672 22.2898 35.7672 26.091C35.7672 29.8923 32.6881 32.9713 28.8869 32.9713C25.0856 32.9713 22.0066 29.8923 22.0066 26.091C22.0066 22.2898 25.0856 19.2107 28.8869 19.2107ZM28.8869 18.1019C33.2985 18.1019 36.874 21.6775 36.874 26.089C36.874 30.5006 33.2985 34.0762 28.8869 34.0762C24.4753 34.0762 20.8997 30.5006 20.8997 26.089C20.8997 21.6775 24.4753 18.1019 28.8869 18.1019Z"
        fill= "#19B01F"  
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.3536 29.7863H31.4226V22.2544H26.3555L26.3536 29.7863ZM27.9392 21.5872L29.8369 21.5911V21.4322L27.9392 21.4302V21.5872ZM28.7792 30.2435C28.1943 30.3927 28.4024 31.2719 28.995 31.1325C29.2089 31.0835 29.4032 30.8676 29.3345 30.5811C29.2835 30.3672 29.0559 30.1729 28.7811 30.2435H28.7792ZM26.3948 20.5863C26.122 20.6393 25.9238 20.8532 25.9199 21.1653C25.9061 22.1956 25.9199 23.3102 25.9199 24.3464V30.7067C25.9199 31.109 25.9081 31.3229 26.175 31.5015C26.4105 31.6585 26.8305 31.6074 27.1464 31.6074H30.3275C30.5807 31.6074 31.1773 31.6369 31.3912 31.5937C31.6561 31.5407 31.8602 31.3229 31.8622 31.0148V21.4734C31.8622 21.179 31.9073 20.867 31.609 20.6766C31.3617 20.5216 30.9575 20.5726 30.6376 20.5726H27.4565C27.2014 20.5726 26.6126 20.5432 26.3987 20.5844L26.3948 20.5863Z"
        // fill="white"
        fill= "#19B01F"  
      />
    </svg>
  );
};
// export const MobileWhite = ({width,height,selectedIcon}) => {
//   console.log('Selected Icon ID in Mobile component:', selectedIcon);
//   return (
//     <svg
//     width={width}
//     height={height}
//       viewBox="0 0 57 52"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fill-rule="evenodd"
//         clip-rule="evenodd"
//         d="M28.8863 12.7876C36.2337 12.7876 42.1877 18.7436 42.1877 26.091C42.1877 33.4384 36.2317 39.3924 28.8863 39.3924C21.5408 39.3924 15.5848 33.4364 15.5848 26.091C15.5848 18.7456 21.5408 12.7876 28.8863 12.7876ZM28.8863 10.7977C37.3326 10.7977 44.1776 17.6446 44.1776 26.089C44.1776 34.5334 37.3307 41.3804 28.8863 41.3804C20.4419 41.3804 13.5949 34.5334 13.5949 26.089C13.5949 17.6446 20.4399 10.7977 28.8863 10.7977Z"
//         // fill="white"
        
//         fill={selectedIcon === 2 ? "#19B01F" : "white"}
        
//       />
//       <path
//         fill-rule="evenodd"
//         clip-rule="evenodd"
//         d="M28.8869 19.2107C32.6862 19.2107 35.7672 22.2898 35.7672 26.091C35.7672 29.8923 32.6881 32.9713 28.8869 32.9713C25.0856 32.9713 22.0066 29.8923 22.0066 26.091C22.0066 22.2898 25.0856 19.2107 28.8869 19.2107ZM28.8869 18.1019C33.2985 18.1019 36.874 21.6775 36.874 26.089C36.874 30.5006 33.2985 34.0762 28.8869 34.0762C24.4753 34.0762 20.8997 30.5006 20.8997 26.089C20.8997 21.6775 24.4753 18.1019 28.8869 18.1019Z"
        
//         fill={selectedIcon === 2 ? "#19B01F" : "white"}
        
//       />
//       <path
//         fill-rule="evenodd"
//         clip-rule="evenodd"
//         d="M26.3536 29.7863H31.4226V22.2544H26.3555L26.3536 29.7863ZM27.9392 21.5872L29.8369 21.5911V21.4322L27.9392 21.4302V21.5872ZM28.7792 30.2435C28.1943 30.3927 28.4024 31.2719 28.995 31.1325C29.2089 31.0835 29.4032 30.8676 29.3345 30.5811C29.2835 30.3672 29.0559 30.1729 28.7811 30.2435H28.7792ZM26.3948 20.5863C26.122 20.6393 25.9238 20.8532 25.9199 21.1653C25.9061 22.1956 25.9199 23.3102 25.9199 24.3464V30.7067C25.9199 31.109 25.9081 31.3229 26.175 31.5015C26.4105 31.6585 26.8305 31.6074 27.1464 31.6074H30.3275C30.5807 31.6074 31.1773 31.6369 31.3912 31.5937C31.6561 31.5407 31.8602 31.3229 31.8622 31.0148V21.4734C31.8622 21.179 31.9073 20.867 31.609 20.6766C31.3617 20.5216 30.9575 20.5726 30.6376 20.5726H27.4565C27.2014 20.5726 26.6126 20.5432 26.3987 20.5844L26.3948 20.5863Z"
//         fill={selectedIcon === 2 ? "#19B01F" : "white"}

       
//       />
//     </svg>
//   );
// };


