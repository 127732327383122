import React from "react";

const IEC = ({ width, height }) => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.395996 38.9281H38.8797V0.444336H0.395996V38.9281ZM4.66007 24.3222L7.76756 24.2993L7.77351 8.8806L4.6496 8.80777L4.66019 24.3222H4.66007ZM35.1143 20.1074C33.6586 20.6532 32.3964 24.3538 28.7982 23.3526C23.0556 21.7553 23.8697 6.80034 31.4787 9.64349C32.685 10.0942 33.1305 10.5954 33.8809 11.3308C34.2745 11.7158 33.9524 11.4554 34.2614 11.681C34.8427 11.002 34.8171 11.5025 34.6694 10.7882C34.4791 9.86843 30.9367 6.95125 26.8721 8.70994C20.8335 11.3228 20.0753 21.7021 27.3851 24.2513C32.2919 25.9627 35.799 21.7053 35.114 20.1073L35.1143 20.1074ZM11.9187 11.2198V21.2252C11.9187 25.0339 15.1351 24.2593 18.8696 24.3084C19.2141 24.3131 20.1869 24.3667 20.4493 24.3432C22.008 24.2048 21.3663 24.677 21.5619 23.5343C21.3231 22.0807 16.3735 23.9148 15.2374 22.5228C14.6963 21.5631 14.8418 17.9761 15.0551 16.645C16.539 16.4678 18.8113 16.9101 19.9259 16.3512C19.8433 14.4763 19.0993 15.8336 15.0112 15.3403C14.5224 8.02977 15.1161 10.1526 21.4032 9.9158L21.3875 8.4963L14.2502 8.48059C12.3774 8.48059 11.9188 9.40225 11.9188 11.2201L11.9187 11.2198ZM4.54129 31.6064H29.7875C29.924 31.9613 30.1201 32.286 30.3648 32.5688H4.54129V31.6064ZM32.8079 28.104C34.1048 28.104 35.1557 29.155 35.1557 30.4519C35.1557 31.7482 34.1048 32.7991 32.8079 32.7991C31.5116 32.7991 30.4607 31.7482 30.4607 30.4519C30.4607 29.155 31.5116 28.104 32.8079 28.104ZM4.54129 28.104H30.4987V28.19C30.2495 28.4446 30.0415 28.7403 29.8866 29.0664H4.54129V28.104ZM4.54129 29.8556H29.6302C29.5947 30.0491 29.5751 30.2479 29.5751 30.4519C29.5751 30.5753 29.5823 30.6973 29.596 30.8172H4.54129V29.8556Z"
        // fill="#18AF1E"
      />
    </svg>
  );
};

export default IEC;
