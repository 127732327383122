import React from "react";

const Monitor = ({width,height}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.5814 30.6957L26.4132 25.8682L27.7681 27.2219L28.4121 26.578L27.0576 25.2244L27.0577 25.2243L26.6977 24.8648L26.4132 24.5805L21.2035 29.7852H18.3065V16.0406H39.4664V29.7852H29.3415V29.7849H28.4304V34.5012H22.1767C21.912 34.5012 21.6973 34.7157 21.6973 34.9802V34.9917C21.6973 35.2564 21.9118 35.4707 22.1767 35.4707H35.5949C35.8601 35.4707 36.0743 35.2566 36.0743 34.9917V34.9802C36.0743 34.7155 35.8599 34.5012 35.5949 34.5012H29.3415V30.6957H39.9241C40.1738 30.6957 40.3777 30.4937 40.3777 30.2438V15.5824C40.3777 15.3322 40.1744 15.1297 39.9241 15.1297H17.8487C17.598 15.1297 17.3947 15.3316 17.3947 15.5824V30.2438C17.3947 30.4942 17.5986 30.6957 17.8487 30.6957H21.5814V30.6957ZM28.3087 25.3931L28.9529 26.0364L31.8364 23.1553L32.0707 23.2491C32.277 23.3317 32.4962 23.3734 32.7184 23.3734C33.6818 23.3734 34.4636 22.5926 34.4636 21.6299C34.4636 20.6668 33.6822 19.8865 32.7184 19.8865C31.7545 19.8865 30.9744 20.667 30.9744 21.6299C30.9744 21.8518 31.016 22.0709 31.0985 22.277L31.1922 22.5109L28.3087 25.3931V25.3931ZM32.7184 22.7318C32.1096 22.7318 31.616 22.2381 31.616 21.6299C31.616 21.0217 32.1097 20.5281 32.7184 20.5281C33.3276 20.5281 33.822 21.021 33.822 21.6299C33.822 22.2389 33.3277 22.7318 32.7184 22.7318Z"
        fill="#19B01F"
      />
    </svg>
  );
};

export default Monitor;
