import React, { useState } from "react";

import "./products.scss";
import { Link } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import MyModel from "./MyModel";

const ProductResEnich = (props) => {
  const [mainImage, setMainImage] = useState(props.product.images.image);
  const ProductButtons = props.ProductButtons;
  const PDF_FILE = props?.pdfurl;
  // const [selectedButton, setSelectedButton] = useState(null);
  const [selectedButton, setSelectedButton] = useState(ProductButtons[0].id);
  console.log("PDF_FILE", PDF_FILE);

  const handlePDF = (url) => {
    if (url) {
      console.log("PDF File URL:", url);

      const fileName = url.split("/").pop();
      console.log("File Name:", fileName);

      const aTag = document.createElement("a");
      aTag.href = url;
      aTag.setAttribute("download", fileName);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    } else {
      console.error("PDF file URL is undefined");
    }
  };
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <section className="products_range">
        <div className="products_range_heading">
          <h1>Explore Our Products Range</h1>
        </div>
        <div className="products_range_wrapper">
          <button className="get_latest_updates_on_products">
            Get Latest Updates
          </button>
          <div className="display_image_container">
            <div className="main_image">
              <img src={mainImage} alt="Main Image" />
            </div>
            <div className="change_main_image_buttons">
              <button
                className="img_chng_btn"
                onClick={() => {
                  setMainImage(props.product.images.image);
                }}
                style={{ backgroundColor: "white" }}
              >
                <img
                  src={props.product.images.image}
                  style={{ objectFit: "cover" }}
                />
              </button>
              <button
                className="img_chng_btn"
                onClick={() => {
                  setMainImage(props.product.images.image2);
                }}
                style={{ backgroundColor: "white" }}
              >
                <img
                  src={props.product.images.image2}
                  style={{ objectFit: "cover" }}
                />
              </button>
            </div>
          </div>
          <div className="product_details_wrapper">
            <div className="change_product_buttons">
              {ProductButtons.map((item, index) => (
                <div key={item.id}>
                  <input
                    type="radio"
                    name="change_product"
                    id={`product_${index}`}
                    // onClick={item.action}a
                    onClick={() => {
                      item.action();
                      setSelectedButton(item.id);
                    }}
                    // checked={item.checked}
                    checked={item.id === selectedButton}
                  />
                  <label
                    className={item.id === selectedButton ? "selected" : ""}
                    //  style={{
                    //   backgroundColor:
                    //     item.id === selectedButton
                    //       ? "yellow"
                    //       : "white",
                    // }}
                    style={{
                      backgroundColor:
                        item.id === selectedButton ? "#E31E24" : " ",
                        boxShadow:
                        item.id == selectedButton
                          ? "0px 2.653px 8.529px 0px #E31E24 "
                          : "",
                    }}
                    htmlFor={`product_${index}`}
                  >
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
            <div className="description">
              {/* <h3>Description</h3> */}
              <ul className="ms-4" style={{ margin: "0px", padding: "0px" }}>
                <li>{props.product.desc1}</li>
                <li>{props.product.desc2}</li>
                <li>{props.product.desc3}</li>
              </ul>
              {/* <p> {formatDescription(props.product.desc)}</p> */}
              {/* <p>{props.product.desc}</p> */}
            </div>
            <div className="cta_buttons">
              <button
                type="button"
                className="cta"
                // data-bs-toggle="modal"
                // data-bs-target="#exampleModal"
                onClick={handleOpenModal}
              >
                Download Brochure
              </button>
              <button className="cta">
                <Link
                  style={{ color: "black" }}
                  to={props.product.cta_links.buy_now}
                >
                  Buy Now
                </Link>
              </button>
            </div>
            <MyModel
              pdf={() => handlePDF(PDF_FILE)}
              show={showModal}
              handleClose={handleCloseModal}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductResEnich;
