import React, { useEffect, useState } from "react";
import "./firstSection.scss";
import { GrLocation } from "react-icons/gr";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Searchable from "react-searchable-dropdown";

const FirstSection = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  // New Address select code::
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    // You can perform additional actions based on the selected value here
    console.log("Selected Option:", event.target.value);
  };

  // New Address select code ends here::

  const HandleSelect = async (value) => {
    const results = await geocodeByAddress(value);

    const addressComponents = results[0].address_components;

    let country = "";
    let state = "";
    let city = "";

    // Extract country, state, and city from address components
    addressComponents.forEach((component) => {
      if (component.types.includes("country")) {
        country = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      }
    });

    const ll = await getLatLng(results[0]);
    // console.log(ll);
    setAddress(value);
    setCoordinates(ll);
    localStorage.setItem("lat", ll.lat);
    localStorage.setItem("lng", ll.lng);
    localStorage.setItem("country", country);
    localStorage.setItem("state", state);
    localStorage.setItem("city", city);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <section className="res_home_first_section">
        <div className="overlay"></div>
        <video autoPlay loop muted playsInline>
          <source
            src="Images/newhomeassets/updated_home_vid.mp4"
            // src="Images/New2/HOMEPAGE/bgvid.mp4"
            type="video/mp4"
          />
        </video>
        <div className="first_sec_contents">
          <p data-aos="fade-right">Affordable Solutions Empowering Households <br /> & Residential Living</p>
          <h1 data-aos="fade-right">
            Hybrid Solar &
            <br />
            Storage Power Solutions
          </h1>
          {/* <div className="search_input_wrapper" data-aos="fade-right">
            <div className="search_input">
              <GrLocation className="loc_icon" />
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={HandleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Start typing to search your location",
                        className: "location-search-input",
                      })}
                      style={{
                        width: "172%",
                        height: "100%",
                        color: "#fff",
                        // padding: "0.6rem 0.5rem",
                        // borderRadius: "10px",
                        // border: "1px solid #fff",
                        outline: "none",
                        // marginBottom: "1rem",
                        backgroundColor: "rgba(0, 0, 0, 0.082)",
                      }}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#000",
                              color: "#fff",
                              cursor: "pointer",
                              padding: "0.1rem 0.3rem",
                              borderRadius: "5px",
                              marginBottom: "0.1rem",
                            }
                          : {
                              backgroundColor: "#000",
                              cursor: "pointer",
                              color: "#fff",
                              padding: "0.1rem 0.3rem",
                              borderRadius: "5px",
                              marginBottom: "0.1rem",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <button
              onClick={() => {
                if (localStorage.getItem("lat")) {
                  navigate("/near_by_dealers");
                } else {
                  alert("Select a location first.");
                }
              }}
              className="search_button"
            >
              Order Now
            </button>
          </div> */}
          {/* <div className="search_input_wrapper" data-aos="fade-right">
            <div className="search_input">
              <GrLocation className="loc_icon" />
              <Searchable
              style={{width: '100%'}}
              value=""
              placeholder="Search" // by default "Search"
              notFoundText="No result found" // by default "No result found"
              noInput
              options={[
                {
                  value: "",
                  label: "All",
                },
                {
                  value: "popular",
                  label: "Popular", // Closing quote added here
                },
              ]}
              onSelect={(value) => {
                console.log(value);
              }}
              listMaxHeight={200} // by default 140
            />
              <input
                className="form-control"
                list="datalistOptions"
                id="exampleDataList"
                placeholder="Type to search..."
                onChange={handleOptionChange}
                value={selectedOption}
              />
              <datalist id="datalistOptions">
                <option value="San Francisco" />
                <option value="New York" />
                <option value="Seattle" />
                <option value="Los Angeles" />
                <option value="Chicago" />
              </datalist>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={HandleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Start typing to search your location",
                        className: "location-search-input",
                      })}
                      style={{
                        width: "172%",
                        height: "100%",
                        // padding: "0.6rem 0.5rem",
                        // borderRadius: "10px",
                        // border: "1px solid #fff",
                        outline: "none",
                        // marginBottom: "1rem",
                        backgroundColor: "rgba(0, 0, 0, 0.082)",
                      }}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#000",
                              color: "#fff",
                              cursor: "pointer",
                              padding: "0.1rem 0.3rem",
                              borderRadius: "5px",
                              marginBottom: "0.1rem",
                            }
                          : {
                              backgroundColor: "#000",
                              cursor: "pointer",
                              color: "#fff",
                              padding: "0.1rem 0.3rem",
                              borderRadius: "5px",
                              marginBottom: "0.1rem",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            <button
              onClick={() => {
                if (localStorage.getItem("lat")) {
                  navigate("/near_by_dealers");
                } else {
                  alert("Select a location first.");
                }
              }}
              className="search_button"
            >
              Order Now
            </button>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default FirstSection;
