import React from "react";

const Safe = ({width,height}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.8862 37.7154C19.3491 34.0943 20.2522 25.8448 20.2522 25.8448V16.9948L28.8862 13.8265L37.5205 16.9948V25.8448C37.5205 25.8448 38.4223 34.0943 28.8862 37.7154ZM38.9487 25.7835V15.9984L28.8862 12.3052L18.8236 15.9984V25.7835C18.7865 26.2577 18.6882 28.4067 19.6588 30.9341C20.6452 33.5008 22.9583 36.9918 28.3788 39.0502L28.8862 39.2424L29.3941 39.0502C34.814 36.9918 37.1272 33.5008 38.1135 30.9341C39.0841 28.4067 38.9858 26.2577 38.9487 25.7835H38.9487Z"
        fill="#19B01F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.3096 22.4764L34.2882 22.4546C33.958 22.1247 33.4235 22.1247 33.0929 22.4546L28.2911 27.2534L25.975 24.9387C25.6448 24.6089 25.1103 24.6089 24.7796 24.9387L24.7582 24.9601C24.4276 25.2895 24.4276 25.825 24.7582 26.155L27.6658 29.0587C27.6711 29.0649 27.6786 29.0711 27.6854 29.0768L27.6948 29.0863C28.0246 29.4161 28.5595 29.4161 28.8897 29.0863L34.3096 23.6708C34.6393 23.3403 34.6397 22.8064 34.3096 22.4764H34.3096Z"
        fill="#19B01F"
      />
    </svg>
  );
};

export default Safe;
