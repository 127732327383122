import React, { useEffect, useState } from "react";
import "../../../Common Components/Application Area/applicationArea.css";
import "./comEnvaultSeries.scss";
import CommercialHeader from "../../../Common Components/CommercialHeader/CommercialHeader";
import Footer from "../../../Common Components/Footer/Footer";
import { Link } from "react-router-dom";
// import { GiKite } from "react-icons/gi";
// import { TbHeartRateMonitor } from "react-icons/tb";
// import { TbArrowsLeftRight } from "react-icons/tb";
// import { LuShieldCheck } from "react-icons/lu";
// import Products from "../../../Common Components/Products/Products";
import Aos from "aos";
import "aos/dist/aos.css";
import Group from "../../../Common Components/Icons/Group";
import Money from "../../../Common Components/Icons/Money";
import TwoHands from "../../../Common Components/Icons/TwoHands";
import CloudNetwork from "../../../Common Components/Icons/CloudNetwork";
import Things from "../../../Common Components/Icons/Things";
import ProductCoomerCialEnvalt from "../../../Common Components/Products/ProductCommerEnvalt";
import ApplicationArea from "../../../Common Components/Application Area/ApplicationArea";

const ComEnvaultSeries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const pdfFile = "4 ENVAULT Series.pdf";

  useEffect(() => {
    Aos.init({ duration: 1000, offset: 200 });
  }, []);
  const [checked, setChecked] = useState({
    product1: "true",
    product2: "",
    product3: "",
    product4: "",
    product5: "",
    product6: "",
  });

  const Product1 = {
    id: 1,
    desc1: "Hybrid Solar Inverter: 100KW",
    desc2: "Smart Battery : up to 215KWh",
    desc3: "Solar Panel :up to 110KWp",
    desc: `Our aim is to empower residents with affordable solar solutions tailored to meet their energy needs. With our expertise and dedication, we're here to transform the way you power your home`,
    images: {
      image1: "Images/coomercailEnvalt/Commercial Envault Series.png",
      image2: "Images/coomercailEnvalt/EnvaultPr01.png",
      image3: "Images/coomercailEnvalt/EnvaultPr02.png",
      image4: "Images/coomercailEnvalt/EnvaultPr03.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product2 = {
    id: 2,
    desc1: "Hybrid Solar Inverter: 150KW",
    desc2: "Smart Battery : up to 400KWh",
    desc3: "Solar Panel :up to 180KWp",
    desc: `Our aim is to empower residents with affordable solar solutions tailored to meet their energy needs. With our expertise and dedication, we're here to transform the way you power your home`,
    images: {
      image1: "Images/coomercailEnvalt/Commercial Envault Series.png",
      image2: "Images/coomercailEnvalt/EnvaultPr01.png",
      image3: "Images/coomercailEnvalt/EnvaultPr02.png",
      image4: "Images/coomercailEnvalt/EnvaultPr03.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product3 = {
    id: 3,
    desc1: "Hybrid Solar Inverter: 250KW",
    desc2: "Smart Battery : up to 800KWh",
    desc3: "Solar Panel :up to 300KWp",
    desc: `Our aim is to empower residents with affordable solar solutions tailored to meet their energy needs. With our expertise and dedication, we're here to transform the way you power your home`,
    images: {
      image1: "Images/coomercailEnvalt/Commercial Envault Series.png",
      image2: "Images/coomercailEnvalt/EnvaultPr01.png",
      image3: "Images/coomercailEnvalt/EnvaultPr02.png",
      image4: "Images/coomercailEnvalt/EnvaultPr03.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product4 = {
    id: 4,
    desc1: "Hybrid Solar Inverter: 500KW",
    desc2: "Smart Battery : up to 1075KWh",
    desc3: "Solar Panel :up to 600KWp",
    desc: `Our aim is to empower residents with affordable solar solutions tailored to meet their energy needs. With our expertise and dedication, we're here to transform the way you power your home`,
    images: {
      image1: "Images/coomercailEnvalt/Commercial Envault Series.png",
      image2: "Images/coomercailEnvalt/EnvaultPr01.png",
      image3: "Images/coomercailEnvalt/EnvaultPr02.png",
      image4: "Images/coomercailEnvalt/EnvaultPr03.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product5 = {
    id: 5,
    desc: `Our aim is to empower residents with affordable solar solutions tailored to meet their energy needs. With our expertise and dedication, we're here to transform the way you power your home`,
    images: {
      image1: "Images/coomercailEnvalt/Commercial Envault Series.png",
      image2: "Images/coomercailEnvalt/EnvaultPr01.png",
      image3: "Images/coomercailEnvalt/EnvaultPr02.png",
      image4: "Images/coomercailEnvalt/EnvaultPr03.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };

  const Product6 = {
    id: 6,
    desc: `Our aim is to empower residents with affordable solar solutions tailored to meet their energy needs. With our expertise and dedication, we're here to transform the way you power your home`,
    images: {
      image1: "Images/coomercailEnvalt/Commercial Envault Series.png",
      image2: "Images/coomercailEnvalt/EnvaultPr01.png",
      image3: "Images/coomercailEnvalt/EnvaultPr02.png",
      image4: "Images/coomercailEnvalt/EnvaultPr03.png",
    },
    cta_links: {
      brochure: "/",
      buy_now: "/contact_us",
    },
  };
  const [product, setProduct] = useState(Product1);

  const ChangeProduct1 = () => {
    setProduct(Product1);
    setChecked({ product1: "true" });
  };

  const ChangeProduct2 = () => {
    setProduct(Product2);
    setChecked({ product2: "true" });
  };

  const ChangeProduct3 = () => {
    setProduct(Product3);
    setChecked({ product3: "true" });
  };

  const ChangeProduct4 = () => {
    setProduct(Product4);
    setChecked({ product4: "true" });
  };

  const ChangeProduct5 = () => {
    setProduct(Product5);
    setChecked({ product5: "true" });
  };

  const ChangeProduct6 = () => {
    setProduct(Product6);
    setChecked({ product6: "true" });
  };
  const ProductButtons = [
    {
      id: 1,
      name: "SG-ENV100",
      action: ChangeProduct1,
      checked: checked.product1,
    },
    {
      id: 2,
      name: "SG-ENV150",
      action: ChangeProduct2,
      checked: checked.product2,
    },
    {
      id: 3,
      name: "SG-ENV250",
      action: ChangeProduct3,
      checked: checked.product3,
    },
    {
      id: 4,
      name: "ENV500",
      action: ChangeProduct4,
      checked: checked.product4,
    },
  ];

  const itemsData = [
    {
      src: "/applicationenvalt/Envault01.png",
      title: "Airports/ Seaports",
      title1: "Railway/ Bus Terminals",
      icon: "/applicationenvalt/icons/Envault01.png",
    },
    {
      src: "/applicationenvalt/Envault02.png",
      title: "Community & Large",
      title1: "Residential Complexes",
      icon: "/applicationenvalt/icons/Envault02.png",
    },
    {
      src: "/applicationenvalt/Envault03.png",
      title: "Government Buildings",
      title1: "& Universities",
      icon: "/applicationenvalt/icons/Envault03.png",
    },
    {
      src: "/applicationenvalt/Envault04.png",
      title: "Malls & Multiplexes ",
      title1: "Stadiums and Sports Complexes",
      icon: "/applicationenvalt/icons/Envault04.png",
    },
    {
      src: "/applicationenvalt/Envault05.png",
      title: "Process Industries",
      title1: "Warehouses",
      icon: "/applicationenvalt/icons/Envault05.png",
    },
  ];

  const [itemActive, setItemActive] = useState(0);

  const nextSlide = () => {
    setItemActive((prevItemActive) => (prevItemActive + 1) % itemsData.length);
  };

  const previousSlide = () => {
    setItemActive((prevItemActive) =>
      prevItemActive === 0 ? itemsData.length - 1 : prevItemActive - 1
    );
  };

  useEffect(() => {
        const interval = setInterval(nextSlide, 4000);
        return () => clearInterval(interval); // Cleanup on unmount
      }, []);

  // Function to handle thumbnail clicks
  const handleThumbnailClick = (index) => {
    setItemActive(index);
  };

  return (
    <>
      <CommercialHeader />

      <div
        id="carouselExampleIndicators"
        className="carousel slide res_third_section"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div
          className="carousel-inner"
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          <div
            className="carousel-item active"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/coomercailEnvalt/Enavault 1ST.jpg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/coomercailEnvalt/hero section banner 2.jpeg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="carousel-item"
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <img
              src="Images/coomercailEnvalt/Enavault 3RD BANNER.jpg"
              className="d-block w-100"
              alt="..."
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <section className="commercial_envault_second_section">
        <div className="enrich_sec_sec_heading">
          <h1>Features</h1>
        </div>
        <section className="commercial_enrich_first_section">
          <div
            id="carouselExampleFade"
            className="carousel slide carousel-fade"
          >
            <div class="carousel-indicators control_buttons">
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              >
                <Group width="45" height="45" className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              >
                <Money width="45" height="45" className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              >
                <TwoHands width="45" height="45" className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              >
                <CloudNetwork width="45" height="45" className="icon" />
              </button>
              <div className="line"></div>
              <button
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide-to="4"
                aria-label="Slide 5"
              >
                <Things width="45" height="45" className="icon" />
              </button>
            </div>
            <div className="carousel-inner">
              <div
                className="carousel-item active"
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src="Images/coomercailEnvalt/Fenvault1.png"
                  className="d-block w-100"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Flexible & Scalable</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>
                      Advanced flexibility to deliver unique customized
                      solutions
                    </li>
                    <li>
                      Infinitely scalable, suitable for projects of various size
                      & locations
                    </li>
                    <li>
                      {" "}
                      Complete solution for Commercial, Industrial, IPP &
                      utility scale use case
                    </li>
                  </ul>
                  {/* <p>
                    Advanced flexibility to deliver unique customised solutions{" "}
                    <br />
                    Infinitely scalable, suitable for projects of various size &
                    locations <br />
                    Complete solution for Commercial, Industrial, IPP & utility
                    scale use case
                  </p> */}
                </div>
              </div>
              <div
                className="carousel-item"
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  objectFit: "cover",
                }}
              >
                <img
                  src="Images/coomercailEnvalt/Fenvault2.png"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Bankable & Resilient</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>Long-lasting, high-performance operation </li>
                    <li> Produce & store energy on-site, safely & reliably </li>
                    <li>
                      Increased uptime of production & critical infrastructure
                    </li>
                    <li>
                      {" "}
                      Weatherproof outdoor ready solutions Freedom from gas &
                      diesel generators
                    </li>
                  </ul>
                  {/* <p>
                    Long-lasting, high-performance operation <br />
                    Produce & store energy on-site, safely & reliably <br />
                    Increased uptime of production & critical infrastructure{" "}
                    <br />
                    Weatherproof outdoor ready solutions <br />
                    Freedom from gas & diesel generators
                  </p> */}
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/coomercailEnvalt/Fenvault3.png"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Hybrid & Sustainable</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>
                      {" "}
                      Seamless transfer between solar, grid & BESS for
                      On-grid/Off-grid Operations{" "}
                    </li>
                    <li>
                      {" "}
                      Intelligent solution for high energy industries &
                      communities{" "}
                    </li>
                    <li>
                      {" "}
                      Minimal maintenance, maximised power & value over its
                      lifetime
                    </li>
                    <li> Reduced energy costs. </li>
                    <li>Reduced carbon footprint. </li>
                  </ul>
                  {/* <p>
                    Seamless transfer between solar, grid & BESS for
                    On-grid/Off-grid Operations <br />
                    Intelligent solution for high energy industries &
                    communities <br />
                    Minimal maintenance, maximised power & value over its
                    lifetime <br />
                    Reduced energy costs. Reduced carbon footprint
                  </p> */}
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/coomercailEnvalt/Fenvault4.png"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Intelligence Integrated</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li> Pre assembled, self contained & ready to operate </li>
                    <li> Quick installation timelines & reduced complexity </li>
                    <li>Greater control through advanced EMS</li>
                    <li>
                      {" "}
                      Optimum maintenance through remote diagnostics via IoT
                      based system{" "}
                    </li>
                    {/* <li>Reduced carbon footprint. </li> */}
                  </ul>
                  {/* <p>
                    Pre assembled, self contained & ready to operate <br />
                    Quick installation timelines & reduced complexity <br />
                    Greater control through advanced EMS <br />
                    Optimum maintenance through remote diagnostics via IoT based
                    system
                  </p> */}
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/coomercailEnvalt/Fenvault5.png"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Energy Audit & Consulting</h1>
                  <ul
                    className="ms-3"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <li>
                      {" "}
                      Inspection, survey & analysis for complete energy
                      assessment{" "}
                    </li>
                    <li> Recommendations of energy efficiency improvements</li>
                    <li>System modelling for maximisation of ROI</li>
                    <li>
                      {" "}
                      Turn key delivery model, long term maintenance & support
                    </li>
                    {/* <li>Reduced carbon footprint. </li> */}
                  </ul>
                  {/* <p>
                    Inspection, survey & analysis for complete energy assessment
                    <br />
                    Recommendations of energy efficiency improvements
                    <br />
                    System modelling for maximisation of ROI
                    <br />
                    Turn key delivery model, long term maintenance & support
                  </p> */}
                </div>
              </div>
              {/* <div
                className="carousel-item"
                style={{ width: "100%", height: "100%", overflow: "hidden" }}
              >
                <img
                  src="Images/Commercial/cImg4.png"
                  className="d-block w-100"
                  alt=""
                />
                <div className="first_sec_contents" data-aos="fade-right">
                  <h1>Simple & Scalable</h1>
                  <p>Plug & Play quick installation & maintenance</p>
                  <p>
                    Flexibility to deliver unique combinations across various
                    sizes & location
                  </p>
                  <p>Connect upto 6 units to grow the system with your needs</p>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </section>

      <ApplicationArea itemsData={itemsData} PageTitle="Envault Series" />

      {/* <section className="application_area_wrapper">
      <div className="heading_container">
        <h3>Envault Series</h3>
        <h1>Application Areas</h1>
      </div>
      <div className="Application_Area">
        <div className="slider">
          <div className="list">
            {itemsData.map((item, index) => (
              <div
                key={index}
                className={`item ${index === itemActive ? "active" : ""}`}
              >
                <img src={item.src} alt={`Slider ${index + 1}`} />
                <div className="content">
                  <h2>
                    {item.title}
                    <br />
                    {item.title1}
                  </h2>
                </div>
              </div>
            ))}
          </div>
          <div className="thumbnail">
            {itemsData.map((item, index) => (
              <div
                key={index}
                className={`item ${index === itemActive ? "active" : ""}`}
                onClick={() => handleThumbnailClick(index)} // Add click event handler
              >
                <img src={item.src} alt={`Slider ${index + 1}`} />
                <div className="content">
                  <img src={item.icon} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section> */}

      <ProductCoomerCialEnvalt
        product={product}
        changeProduct1={ChangeProduct1}
        changeProduct2={ChangeProduct2}
        checked={checked}
        ProductButtons={ProductButtons}
        pdfurl={pdfFile}
      />

      <section className="commercial_envault_fourth_section_wrapper">
        <div className="product_fourth_section">
          <div className="Partner_with_us">
            <div className="partner_image" data-aos="zoom-in">
              <img
                src="Images/commercialHome/Partner with us.jpeg"
                alt="Area Map"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="partner_contents">
              <h1>Partner with us now! </h1>
              <p>Join Our Network Of Dealers And Distributors</p>
              <button>
                <Link to="/">Join Now</Link>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ComEnvaultSeries;
