import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import "./mymodel.scss"
import { IoArrowBackCircleOutline } from "react-icons/io5";
import axios from 'axios';
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
 

const MyModel = ({pdf, show, handleClose }) => {
    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        phone_number: "",
      });
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };
    const habkk=()=>{
        handleClose();
    }
    
      const DownloadBrochureSubmit = (e) => {
        e.preventDefault();
        const payload = {
          full_name: formData?.full_name,
          phone_number: formData?.phone_number,
          email: formData?.email,
    
        }
        axios.post("https://dashboard.skippergreen.com/brochure-contact/", payload).then((response) => {
          Swal.fire({
            title: "success!",
            // html: `Thank you for your interest in Skipper Green! <br> A member of our team will get back to you shortly`,
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: '#18AF1E',
          });
        //   handlePDF(PDF_FILE);
          setFormData({
            full_name: '',
            phone_number: "",
            email: ""
          })
          pdf()
          handleClose();
          // $('#exampleModal').modal('hide');
        })
      };
    return (
        <Modal show={show} onHide={handleClose}  
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='modelproduct'
        >
            <div 
              id="exampleModal"
            
            >
            <div className="modal-body">
                <div className="close_btn_container">
                    <button type="button">
                        <IoArrowBackCircleOutline
                            onClick={habkk}
                        />
                    </button>
                </div>
        
                <div className="heading_container">
                    <p>Enter your Details for Brochure</p>
                </div>
                <form
                    className="detail_form"
                    onSubmit={DownloadBrochureSubmit}
                >
                    <input
                        type="text"
                        placeholder="Name"
                        name="full_name"
                        value={formData.full_name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="number"
                        placeholder="Number"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        required
                        minLength={10}
                    />
                    <input type="submit" value="Get Brochure" />
                </form>
            </div>
            </div>
         
        </Modal>
    )
}

export default MyModel